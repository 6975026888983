import React from "react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as Yup from "yup";
function AddSliderBanner() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const today = new Date();
  const minStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const FILE_SIZE = 11200 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];
  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
    // return undefined;
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(20, "Title must not exceed 20 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
    image: Yup.mixed()
      .required("Image is required")
      .test(
        "fileSize",
        "File too large",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    startDate: Yup.date()
      .typeError("Start date must be a valid date")
      .required("Start date is required")
      .min(minStartDate, "Start date must not be in the past"),
    endDate: Yup.date()
      .typeError("End date must be a valid date")
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date must be after the start date"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      image: "",
      link: "",
      startDate: moment(startDate).format("yyyy-MM-DD"),
      endDate: moment(endDate).format("yyyy-MM-DD"),
    },

    validationSchema,
    validateOnChange: true,
    // validateOnBlur: false,
    onSubmit: async (data) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", formik.values.image);
      formData.append("title", formik.values.title);
      formData.append("link", formik.values.link);
      formData.append("startDate", formik.values.startDate);
      formData.append("endDate", formik.values.endDate);

      console.log("formData", formData);
      await axios
        .post(process.env.REACT_APP_API_KEY + "slider-banner", formData, {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Created Successfully!",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/banner-list")
                : navigate("/banner-list/Modify");
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            swal("Oops", error.response.data.message, "error");
          }
          setIsLoading(false);
        });
    },
  });

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Add Slider Banner</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Title
                  </label>
                  <input
                    name="title"
                    type="text"
                    className="form-control"
                    id="inputTitle"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    autoComplete="off"
                    placeholder="Enter Title"
                  />
                  <div className="text-danger">
                    {formik.errors.title && formik.touched.title
                      ? formik.errors.title
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    External Url
                  </label>
                  <input
                    name="link"
                    type="text"
                    className="form-control"
                    id="inputTitle"
                    onChange={formik.handleChange}
                    value={formik.values.link}
                    autoComplete="off"
                    placeholder="Enter External Url"
                  />
                </div>
                <div className="col-md-6 my_calendar">
                  <label htmlFor="inputStartDate" className="form-label">
                    Start Date
                  </label>
                  <br></br>
                  <div className="datepicker-icon">
                    <DatePicker
                      name="startDate"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        formik.setFieldValue(
                          "startDate",
                          moment(date).format("yyyy-MM-DD")
                        );
                      }}
                      className="form-control"
                      id="inputStartDate"
                      autoComplete="off"
                      placeholderText="Start Date"
                      minDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                      required
                    />

                    <span className="new-date-icon">
                      <i class="material-icons-two-tone">calendar_today</i>
                    </span>
                  </div>
                  <div className="text-danger">
                    {formik.errors.startDate && formik.touched.startDate
                      ? formik.errors.startDate
                      : null}
                  </div>
                </div>
                <div className="col-md-6 my_calendar">
                  <label htmlFor="inputStartDate" className="form-label">
                    End Date
                  </label>
                  <br></br>
                  <div className="datepicker-icon">
                    <DatePicker
                      className="form-control"
                      name="endDate"
                      dateFormat="yyyy-MM-dd"
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        formik.setFieldValue(
                          "endDate",
                          moment(date).format("yyyy-MM-DD")
                        );
                      }}
                      dropdownMode="select"
                      minDate={startDate}
                      adjustDateOnChange
                    />

                    <span className="new-date-icon">
                      <i class="material-icons-two-tone">calendar_today</i>
                    </span>
                  </div>
                  <div className="text-danger">
                    {formik.errors.endDate && formik.touched.endDate
                      ? formik.errors.endDate
                      : null}
                  </div>
                </div>
                <div className="item-wrapper">
                  <div className="row-item">
                    <input
                      type="file"
                      name="image"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "image",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <div className="text-danger">
                    
                      {formik.errors.image && formik.touched.image
                        ? formik.errors.image
                        : null}
                        
                    </div>
                    <label style={{ fontSize: 10, color: "blue" }}>
                    Best resolution for the banner image 2659 X 1406.
                    </label>
                  </div>
                </div>

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Saving...
                      </>
                    ) : (
                      "Save"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/banner-list/Modify"
                        : "/banner-list"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddSliderBanner;
