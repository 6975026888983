import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
export const priceChartAdapter = createEntityAdapter();

export const fetchSettingsData = createAsyncThunk(
  "settings/fetchData",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "general-settings",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchAdminProfile = createAsyncThunk(
  "admin/profile",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "user-profile",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const initialState = priceChartAdapter.getInitialState({
  settingList: [],
  isLoading: false,
  error: null,
  adminData: {},
  adminDataLoad: false,
});

const settingSlice = createSlice({
  name: "admin-profile-data",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettingsData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSettingsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settingList = action.payload;
      })
      .addCase(fetchSettingsData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAdminProfile.pending, (state) => {
        state.adminDataLoad = true;
        state.error = null;
      })
      .addCase(fetchAdminProfile.fulfilled, (state, action) => {
        state.adminDataLoad = false;
        state.adminData = action.payload;
      })
      .addCase(fetchAdminProfile.rejected, (state, action) => {
        state.adminDataLoad = false;
        state.error = action.error.message;
      });
  },
});

export const { settingsData } = settingSlice.actions;
export default settingSlice.reducer;
