import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
export const sideBarAdapter = createEntityAdapter();
const sidebarPersistConfig = {
  key: "sidebar",
  storage,
};

export const fetchSidebarData = createAsyncThunk(
  "sidebar/fetchData",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "sidebar-item-list",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchDashboardCount = createAsyncThunk(
  "dashboard/fetchData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "dashboard-item-counts",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchDashboardPermission = createAsyncThunk(
  "dashboard/fetchDashboardPermission",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "fetch-dashboard-permission" ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      console.log('data in fetchdashboard premission',data)
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const initialState = sideBarAdapter.getInitialState({
  data: [],
  isLoading: false,
  error: null,
  dashboardData: [],
  dashboardPermission: [],
});

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    data: [],
    dashboardPermission: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSidebarData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSidebarData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchSidebarData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDashboardCount.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDashboardCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dashboardData = action.payload;
      })
      .addCase(fetchDashboardCount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDashboardPermission.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDashboardPermission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dashboardPermission = action.payload;
      })
      .addCase(fetchDashboardPermission.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const persistedSidebarReducer = persistReducer(
  sidebarPersistConfig,
  sidebarSlice.reducer
);
export const sidebarActions = sidebarSlice.actions;
