import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrandList } from "../BrandAndModel/brandSlice";
import {
  fetchProductAccessoriesType,
  fetchSingleAccessories,
} from "./filterSlice";

import Select from "react-select";
import LoadingSpinner from "../Loader/LoadingSpinner";
function EditFilter() {
  const location = useLocation();
  const { id, action } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = [];
  const brandOptions = [];
  const brandsAccessory = useSelector((state) => state.filter.productAccessoriesType);

  const modelData = useSelector((state) => state.filter?.filterData);

  const modelLoading = useSelector((state) => state.brand?.modelLoading);
  if (brandsAccessory) {
    brandsAccessory.data?.forEach((element) => {
      options.push({ value: element.type, label: element.type });
    });
  }

  const brands = useSelector((state) => state.brand.brandsList);
  if (brands) {
    
    brands.data?.forEach((element) => {
      brandOptions.push({ value: element.id, label: element.name });
    });
  }
  
  useEffect(() => {
    dispatch(fetchProductAccessoriesType());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleAccessories(id));
      dispatch(fetchBrandList());
    }
  }, [id]);
  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
    // return undefined;
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(30, "Name must not exceed 30 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
    type: Yup.string().required("Type is required"),
    //brand_id: Yup.string().required("Brand is required")
    brand_id: Yup.mixed().when("type", {
      is: (val) => 
      {
        var finCon =  val?.toUpperCase().includes("ACCESSORIES") ||  val?.toUpperCase().includes("FACTTORYGEM") ||  val?.toUpperCase().includes("CUSTOMFACTTORYGEM") ||  val?.toUpperCase().includes("MOVEMENT") ? false : true;
        
       return finCon; 
       },
      then: () =>
        Yup.string().required("Brand is required")
        
    }),

  });

  const formik = useFormik({
    initialValues: {
      name: modelData?.data?.name,
      type: modelData?.data?.type,
      brand_id: modelData?.data?.brand_id ?? '',
    },
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async (data) => {
      setIsLoading(true);
      await axios
        .post(
          process.env.REACT_APP_API_KEY + "update-product-accessories/" + id,
          data,
          {
            headers: {
              "Content-type": "Application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              type: "model",
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            swal({
              title: "Success!",
              text: "Data Updated Successfully !",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/filter-management")
                : navigate("/model-management/Modify");
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            swal("Oops", error.response.data.message, "error");
          }
          setIsLoading(false);
        });
    },
  });

  if (modelLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Edit Filter</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                
              <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Select Brand
                  </label>
                  <Select
                    name="brand_id"
                    onChange={(selected) => {
                      formik.setFieldValue("brand_id", selected.value);
                    }}
                    defaultValue={{
                      label: modelData?.data?.m_name,
                      value: modelData?.data?.m_id,
                    }}
                    options={brandOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors.brand_id && formik.touched.brand_id
                      ? formik.errors.brand_id
                      : null}
                  </div>
                </div>
              <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Select Type
                  </label>
                  <Select
                    name="type"
                    onChange={(selected) => {
                      formik.setFieldValue("type", selected.value);
                    }}
                    defaultValue={{
                      label: modelData?.data?.type,
                      value: modelData?.data?.type,
                    }}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors?.type && formik.touched?.type
                      ? formik.errors?.type
                      : null}
                  </div>
                </div>

                <div className="col-md-12">
                  <label htmlFor="inputTitle" className="form-label">
                    Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      formik.setFieldValue("name", e.target.value);
                    }}
                    value={formik.values.name}
                    autoComplete="off"
                    placeholder="Name"
                  />
                  <div className="text-danger">
                    {formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : null}
                  </div>
                </div>

                

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Updating...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={"/filter-management"}
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditFilter;
