import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import * as Yup from "yup";

function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordNew, setPasswordTypeNew] = useState("password");
  const [passwordConfirm, setPasswordTypeConfirm] = useState("password");
  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required("Required*").trim(),
    new_password: Yup.string()
      .required("Required*")
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "new_password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirm_password: Yup.string()
      .required("Required*")
      .trim()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      new_password: "",
      current_password: "",
      confirm_password: "",
    },
    validationSchema,
    validateOnChange: true,
    // validateOnBlur: false,
    onSubmit: async (data) => {
      setIsLoading(true);
      await axios
        .post(process.env.REACT_APP_API_KEY + "change-password", data, {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            userID: localStorage.getItem("userID"),
          },
        })
        .then((res) => {
          if (res.data.success) {
            swal({
              title: "Success!",
              text: res.data.message,
              icon: "success",
              button: "Okay",
            });
            localStorage.clear();
            navigate("/sign-in");
            setIsLoading(false);
            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/dashboard")
                : navigate("/dashboard");
            }
          }
        })
        .catch((error) => {
          swal("Oops", error.response.data.message, "error");
          setIsLoading(false);
        });
    },
  });

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  const togglePasswordNew = () => {
    if (passwordNew === "password") {
      setPasswordTypeNew("text");
    } else {
      setPasswordTypeNew("password");
    }
  };
  const togglePasswordConfirm = () => {
    if (passwordConfirm === "password") {
      setPasswordTypeConfirm("text");
    } else {
      setPasswordTypeConfirm("password");
    }
  };
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Change Password</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-3">
                  <label htmlFor="inputTitle" className="form-label">
                    Current Password
                  </label>
                  <div className="icon-input">
                    <input
                      name="current_password"
                      type={passwordType}
                      className="form-control eye-icon"
                      onChange={formik.handleChange}
                      defaultValue={formik.values.current_password}
                      autoComplete="off"
                      placeholder="Enter Current Password"
                    />
                    {passwordType === "password" ? (
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={togglePassword}
                        style={
                          formik.values.current_password.length > 0
                            ? {}
                            : { display: "none" }
                        }
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash"
                        onClick={togglePassword}
                      ></i>
                    )}
                  </div>

                  <div className="text-danger">
                    {formik.errors.current_password &&
                    formik.touched.current_password
                      ? formik.errors.current_password
                      : null}
                  </div>
                </div>

                <div className="col-md-3">
                  <label htmlFor="inputTitle" className="form-label">
                    New Password
                  </label>
                  <div className="icon-input">
                    <input
                      name="new_password"
                      type={passwordNew}
                      className="form-control"
                      onChange={formik.handleChange}
                      defaultValue={formik.values.new_password}
                      autoComplete="off"
                      placeholder="New Password"
                    />
                    {passwordNew === "password" ? (
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={togglePasswordNew}
                        style={
                          formik.values.new_password.length > 0
                            ? {}
                            : { display: "none" }
                        }
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash"
                        onClick={togglePasswordNew}
                      ></i>
                    )}
                  </div>
                  <div className="text-danger">
                    {formik.errors.new_password && formik.touched.new_password
                      ? formik.errors.new_password
                      : null}
                  </div>
                </div>

                <div className="col-md-3">
                  <label htmlFor="inputTitle" className="form-label">
                    Confirm Password
                  </label>
                  <div className="icon-input">
                    <input
                      name="confirm_password"
                      type={passwordConfirm}
                      className="form-control"
                      onChange={formik.handleChange}
                      defaultValue={formik.values.confirm_password}
                      autoComplete="off"
                      placeholder="Confirm Password"
                    />
                    {passwordConfirm === "password" ? (
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={togglePasswordConfirm}
                        style={
                          formik.values.confirm_password.length > 0
                            ? {}
                            : { display: "none" }
                        }
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash"
                        onClick={togglePasswordConfirm}
                      ></i>
                    )}
                  </div>
                  <div className="text-danger">
                    {formik.errors.confirm_password &&
                    formik.touched.confirm_password
                      ? formik.errors.confirm_password
                      : null}
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Updating...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/dashboard"
                        : "/dashboard"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ChangePassword;
