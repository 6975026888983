import React from "react";
import { useRef, useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { fetchBrandList, fetchModelList } from "../BrandAndModel/brandSlice";
import { fetchSingleProductForAutoFill } from "./autoFilsSlice";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
function AddBrand() {
  const location = useLocation();
  const state = location.state;
  const [allDropDown, setAllDropDown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modelId, setModelId] = useState();
  const params = useLocation();
  const [inputs, setInputs] = useState({});
  const [brandName, setBrandName] = useState();
  const [newCreatedModel, setNewCreatedModel] = useState(false);
  const dispatch = useDispatch();
  const accessories = []; //accessories
  const dialOption = []; //DIAL
  const dialMarOption = []; //DIALMARKERS
  const caseSizeOption = []; //CASESIZE
  const movementOption = []; //MOVEMENT
  const caseMaterialOption = []; //CASEMATERIAL
  const strapOption = []; //STRAPBRACELET
  const claspOption = []; //CLASP
  const [defaultModel, setDefaultModel] = useState({ value: "", label: "" });
  const [defaultBrand, setDefaultBrand] = useState({ value: "", label: "" });
  const [defaultDial, setDefaultDial] = useState({ value: "", label: "" });
  const [defaultMovement, setDefaultMovement] = useState({
    value: "",
    label: "",
  });

  const [defaultCaseMaterial, setDefaultCaseMaterial] = useState({
    value: "",
    label: "",
  });
  const [defaultBracelet, setDefaultBracelet] = useState({
    value: "",
    label: "",
  });
  const [defaultClasp, setDefaultClasp] = useState({ value: "", label: "" });
  const [defaultDialMarker, setDefaultDialMarker] = useState({
    value: "",
    label: "",
  });
  const [defaultReferenceNumber, setDefaultReferenceNumber] = useState();
  const [defaultImageUrl, setDefaultImageUrl] = useState("");

  const [defaultCaseSize, setDefaultCaseSize] = useState({
    value: "",
    label: "",
  });
  const [newBrand, setNewBrand] = useState();
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const options = [];
  const modelOptions = [];

  const models = useSelector((state) => state.brand.modelList);
  if (models) {
    models.data?.forEach((element) => {
      modelOptions.push({ value: element.id, label: element.name });
    });
  }
  const navigate = useNavigate();
  const productAutFill = useSelector(
    (state) => state.autoFills.productForAutoFillList
  );
  const isLoadingAutoFill = useSelector((state) => state.autoFills.isLoading);
  const error = useSelector((state) => state.autoFills.error);
  useEffect(() => {
    dispatch(fetchBrandList());
    if(modelId){
      dispatch(fetchModelList(modelId));
      getAllDropDownList(modelId);
    } else {
      getAutoFillDataById();
    }
    

  }, [dispatch, modelId]);

  const brands = useSelector((state) => state.brand.brandsList);
  const dataLoading = useSelector((state) => state.brand.isLoading);
  if (brands) {
    brands.data?.forEach((element) => {
      options.push({ value: element.id, label: element.name });
    });
  }
  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
  };
  const validationSchema = Yup.object().shape({
    reference_number: Yup.string()
      .required("Reference Number")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(30, "Reference Name must not exceed 30 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
  });

  const getAllDropDownList = async () => {
    await axios({
      url: process.env.REACT_APP_API_KEY + "get-product-dropdown/"+modelId,
      method: "get",
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        setAllDropDown(response.data.data);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    setDefaultImageUrl(URL.createObjectURL(file));
    // Append the selected image to the FormData
    formik.setFieldValue("thumb_image", file);
  };
  if (allDropDown) {
    allDropDown.ACCESSORIES?.forEach((element) => {
      accessories.push({ value: element.id, label: element.name });
    });
    allDropDown.DIAL?.forEach((element) => {
      dialOption.push({ value: element.id, label: element.name });
    });
    allDropDown.DIALMARKERS?.forEach((element) => {
      dialMarOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CASESIZE?.forEach((element) => {
      caseSizeOption.push({ value: element.id, label: element.name });
    });
    allDropDown.MOVEMENT?.forEach((element) => {
      movementOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CASEMATERIAL?.forEach((element) => {
      caseMaterialOption.push({ value: element.id, label: element.name });
    });
    allDropDown.STRAPBRACELET?.forEach((element) => {
      strapOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CLASP?.forEach((element) => {
      claspOption.push({ value: element.id, label: element.name });
    });
  }

  const getAutoFillDataById = () => {
    setIsLoading(true);
    axios
      .get(
        process.env.REACT_APP_API_KEY +
          "get-auto-fill-by-id/" +
          params.state.id,
        {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )

      .then((res) => {
        setIsLoading(false);

        formik.setFieldValue("brand", res.data.data.brand);
        setModelId(res.data.data.brand)
        setDefaultBrand({
          value: res.data.data.brand,
          label: res.data.data.brand_name,
        });
        formik.setFieldValue("tumb_image_url", res.data.data.thumb_image_url);
        setDefaultImageUrl(res.data.data.thumb_image_url);

        formik.setFieldValue("model", res.data.data.model);
        console.log("========>>>>>>",res.data.data.brand)
        dispatch(fetchModelList(res.data.data.brand));
        setDefaultModel({
          value: res.data.data.model,
          label: res.data.data.model_name,
        });

        console.log(res.data.data.model,"",res.data.data.model_name)
        formik.setFieldValue("dial", res.data.data.dial);
        setDefaultDial({
          value: res.data.data.dial,
          label: res.data.data.dial_name,
        });
        formik.setFieldValue("dial_markers", res.data.data.dial_markers);
        setDefaultDialMarker({
          value: res.data.data.dial_markers,
          label: res.data.data.dial_mar_name,
        });
        formik.setFieldValue("bracelet", res.data.data.bracelet);
        setDefaultBracelet({
          value: res.data.data.bracelet,
          label: res.data.data.bracelet_acc_name,

        });
        formik.setFieldValue("case_size", res.data.data.case_size);
        setDefaultCaseSize({
          value: res.data.data.case_size,
          label: res.data.data.casesize_name,
        });
        formik.setFieldValue("movement", res.data.data.movement);
        setDefaultMovement({
          value: res.data.data.movement,
          label: res.data.data.movem_name,
        });
        formik.setFieldValue("case_material", res.data.data.case_material);
        setDefaultCaseMaterial({
          value: res.data.data.case_material,
          label: res.data.data.case_ma_name,
        });
        formik.setFieldValue("clasp", res.data.data.clasp);
        setDefaultClasp({
          value: res.data.data.clasp,
          label: res.data.data.clasp_acc_name,
        });

        formik.setFieldValue(
          "reference_number",
          res.data.data.reference_number
        );
        setDefaultReferenceNumber(res.data.data.reference_number);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error>>", error.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      reference_number: defaultReferenceNumber,
      //thumb_image_url:defaultImage,
      newBrand: false,
      newModel: false,
      brand: defaultBrand?.value,
      model: defaultModel?.value,
      dial: defaultDial.value,
      dial_markers: defaultDialMarker.value,
      case_size: defaultCaseSize.value,
      movement: defaultMovement.value,
      case_materials: defaultCaseMaterial.value,
      bracelet: defaultBracelet.value,
      clasp: defaultClasp.value,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (data) => {
      setIsLoading(true);
      axios
        .post(
          process.env.REACT_APP_API_KEY + `update-auto-fill/${state.id}`,
          data,
          {
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res?.status === 200) {
            swal({
              title: "Success!",
              text: "Updated Successfully!",
              icon: "success",
              button: "Okay",
            });

            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/auto-fills-management")
                : navigate("/auto-fills-management");
            }
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            swal("Oops", error.response.data.message, "error");
          }
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {}, [formik]);

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Edit Auto Fill</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="title" className="form-label">
                    Reference Number{" "}
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <input
                    name="reference_number"
                    id="reference_number"
                    type="text"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.reference_number}
                    autoComplete="off"
                    placeholder="Enter reference number."
                  />
                  <div className="text-danger">
                    {formik.errors.reference_number &&
                    formik.touched.reference_number
                      ? formik.errors.reference_number
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Choose Brand{" "}
                  </label>
                  
                  {/* {defaultBrand.value && (
                    <Select
                      name="brand_id"
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                      isClearable
                      isDisabled={isSelectLoading}
                      isLoading={isSelectLoading}
                      defaultValue={defaultBrand}
                      onChange={(selected) => {
                        formik.setFieldValue("brand_id", selected?.value);
                      }}
                      onCreateOption={(inputValue) => {
                        setIsSelectLoading(true);
                        setTimeout(() => {
                          const newOption = {
                            value: inputValue,
                            label: inputValue,
                          };
                          setIsSelectLoading(false);
                          options.push(newOption);
                          setNewBrand(newOption);
                          setNewCreatedModel(null);
                          formik.setFieldValue("brand_id", newOption.value);
                        }, 1000);
                      }}
                      value={newBrand}
                    />
                  )} */}
                  {/* {!defaultBrand.value && (
                    <Select
                      name="brand_id"
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                      isClearable
                      isDisabled={isSelectLoading}
                      isLoading={isSelectLoading}
                      defaultValue={defaultBrand}
                      onChange={(selected) => {
                        formik.setFieldValue("brand_id", selected?.value);
                        setNewBrand(selected);
                        setModelId(selected?.value);
                        setBrandName(selected?.label);

                        setNewCreatedModel(null);
                      }}
                      onCreateOption={(inputValue) => {
                        setIsSelectLoading(true);
                        setTimeout(() => {
                          const newOption = {
                            value: inputValue,
                            label: inputValue,
                          };
                          setIsSelectLoading(false);
                          options.push(newOption);
                          setNewBrand(newOption);
                          setNewCreatedModel(null);
                          formik.setFieldValue("brand_id", newOption.value);
                        }, 1000);
                      }}
                      value={newBrand}
                    />
                  )} */}

                  {defaultBrand.value && (
                    <Select
                      name="brand"
                      id="brand"
                      onChange={(selected) => {
                        formik.setFieldValue("brand", selected.value);
                        setModelId(selected?.value);
                        
                        setDefaultModel({value: '',label: ''});
                        setDefaultDial({value: '',label: ''});
                        setDefaultDialMarker({value: '',label: ''});
                        setDefaultBracelet({value: '',label: ''});
                        setDefaultCaseSize({value: '',label: ''});
                        setDefaultMovement({value: '',label: ''});
                        setDefaultCaseMaterial({value: '',label: ''});
                        setDefaultClasp({value: '',label: ''});

                        console.log('DefaultClasp >> ',defaultClasp);
                      }}
                      defaultValue={defaultBrand}
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultBrand.value && (
                    <Select
                      name="brand"
                      id="brand"
                      onChange={(selected) => {
                        formik.setFieldValue("brand", selected.value);
                      }}
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger">
                    {formik.errors.brand && formik.touched.brand
                      ? formik.errors.brand
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="model" className="form-label">
                    Model
                  </label>

                  {defaultModel.value && (
                    <Select
                      name="model"
                      id="model"
                      onChange={(selected) => {
                        formik.setFieldValue("model", selected.value);
                      }}
                      defaultValue={defaultModel}
                      options={modelOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultModel.value && (
                    <Select
                      name="model"
                      id="model"
                      onChange={(selected) => {
                        formik.setFieldValue("model", selected.value);
                      }}
                      options={modelOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}

                  <div className="text-danger">
                    {formik.errors.model && formik.touched.model
                      ? formik.errors.model
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="dial" className="form-label">
                    Dial
                   
                  </label>
                  {defaultDial.value && (
                    <Select
                      name="dial"
                      id="dial"
                      onChange={(selected) => {
                        formik.setFieldValue("dial", selected.value);
                      }}
                      defaultValue={defaultDial}
                      options={dialOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultDial.value && (
                    <Select
                      name="dial"
                      id="dial"
                      onChange={(selected) => {
                        formik.setFieldValue("dial", selected.value);
                      }}
                      options={dialOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger">
                    {formik.errors.dial && formik.touched.dial
                      ? formik.errors.dial
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="dial_markers" className="form-label">
                    Dial Markers
                  </label>
                  {defaultDialMarker.value && (
                    <Select
                      name="dial_markers"
                      id="dialMarkers"
                      onChange={(selected) => {
                        formik.setFieldValue("dial_markers", selected.value);
                      }}
                      defaultValue={defaultDialMarker}
                      options={dialMarOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultDialMarker.value && (
                    <Select
                      name="dialMarkers"
                      id="dialMarkers"
                      onChange={(selected) => {
                        formik.setFieldValue("dial_markers", selected.value);
                      }}
                      // defaultValue={defaultDialMarker}
                      options={dialMarOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger"></div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="case_size" className="form-label">
                    Case Size
                  </label>
                  {defaultCaseSize.value && (
                    <Select
                      name="case_size"
                      id="caseSize"
                      onChange={(selected) => {
                        formik.setFieldValue("case_size", selected.value);
                      }}
                      defaultValue={defaultCaseSize}
                      options={caseSizeOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultCaseSize.value && (
                    <Select
                      name="caseSize"
                      id="caseSize"
                      onChange={(selected) => {
                        formik.setFieldValue("case_size", selected.value);
                      }}
                      // defaultValue={defaultCaseSize}
                      options={caseSizeOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger"></div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="movement" className="form-label">
                    Movement
                  </label>
                  {defaultMovement.value && (
                    <Select
                      name="movement"
                      id="movement"
                      onChange={(selected) => {
                        formik.setFieldValue("movement", selected.value);
                      }}
                      defaultValue={defaultMovement}
                      options={movementOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultMovement.value && (
                    <Select
                      name="movement"
                      id="movement"
                      onChange={(selected) => {
                        formik.setFieldValue("movement", selected.value);
                      }}
                      // defaultValue={defaultMovement}
                      options={movementOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger"></div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="case_material" className="form-label">
                    Case Material
                  </label>
                  {defaultCaseMaterial.value && (
                    <Select
                      name="case_materials"
                      id="case_materials"
                      onChange={(selected) => {
                        formik.setFieldValue("case_materials", selected.value);
                      }}
                      defaultValue={defaultCaseMaterial}
                      options={caseMaterialOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultCaseMaterial.value && (
                    <Select
                      name="caseMaterial"
                      id="case_materials"
                      onChange={(selected) => {
                        formik.setFieldValue("case_materials", selected.value);
                      }}
                      options={caseMaterialOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger"></div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="bracelet" className="form-label">
                    Strap/Bracelet
                   
                  </label>
                  {defaultBracelet.value && (
                    <Select
                      name="bracelet"
                      id="strapBracelet"
                      onChange={(selected) => {
                        formik.setFieldValue("bracelet", selected.value);
                      }}
                      defaultValue={defaultBracelet}
                      options={strapOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultBracelet.value && (
                    <Select
                      name="strapBracelet"
                      id="strapBracelet"
                      onChange={(selected) => {
                        formik.setFieldValue("bracelet", selected.value);
                      }}
                      // defaultValue={defaultBracelet}
                      options={strapOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger">
                    {formik.errors.bracelet && formik.touched.bracelet
                      ? formik.errors.bracelet
                      : null}
                  </div>
                  
                </div>
                <div className="col-md-12">
                  <label htmlFor="clasp" className="form-label">
                    Clasp
                  </label>
                  {defaultClasp.value && (
                    <div>
                    <Select
                      name="clasp"
                      id="clasp"
                      onChange={(selected) => {
                        formik.setFieldValue("clasp", selected.value);
                      }}
                      defaultValue={defaultClasp}
                      options={claspOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                    </div>
                  )}
                  {!defaultClasp.value && (
                    <div>
                    <Select
                      name="clasp"
                      id="clasp"
                      onChange={(selected) => {
                        formik.setFieldValue("clasp", selected.value);
                      }}
                      // value={defaultClasp}
                      options={claspOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                    </div>
                  )}
                  <div className="text-danger"></div>
                </div>
                <div>

                  <input
                    type="file"
                    accept="image/*"
                    name="thumb_image_url"
                    onChange={handleImageChange}
                  />
                  {defaultImageUrl && (
                    <img height={"250"} src={defaultImageUrl} alt="Selected" />
                  )}
                </div>

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Updating...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={"/auto-fills-management"}
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddBrand;
