import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrandList, fetchSingleModel } from "./brandSlice";
import Select from "react-select";
import LoadingSpinner from "../Loader/LoadingSpinner";
function AddModel() {
  const location = useLocation();
  const { id, action } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = [];
  const brands = useSelector((state) => state.brand.brandsList);

  const modelData = useSelector((state) => state.brand?.modelData);
  const modelLoading = useSelector((state) => state.brand?.modelLoading);
  if (brands) {
    brands.data?.forEach((element) => {
      options.push({ value: element.id, label: element.name });
    });
  }

  useEffect(() => {
    dispatch(fetchBrandList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSingleModel(id));
  }, [id]);
  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
    // return undefined;
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Model name is required")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(30, "Model name must not exceed 30 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
    brandId: Yup.string().required("Brand is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: modelData?.data?.name,
      brandId: modelData?.data.parent.id,
    },
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async (data) => {
      setIsLoading(true);
      await axios
        .post(process.env.REACT_APP_API_KEY + "update-brand/" + id, data, {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            type: "model",
          },
        })
        .then((res) => {
          if (res.data.success) {
            swal({
              title: "Success!",
              text: "Data Updated Successfully !",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/model-management")
                : navigate("/model-management/Modify");
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            swal("Oops", error.response.data.message, "error");
          }
          setIsLoading(false);
        });
    },
  });

  if (modelLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Edit Model</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Select Brand
                  </label>
                  <Select
                    name="brandId"
                    onChange={(selected) => {
                      formik.setFieldValue("brandId", selected.value);
                    }}
                    defaultValue={{
                      label: modelData?.data.parent.name,
                      value: modelData?.data.parent.id,
                    }}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors.brandId && formik.touched.brandId
                      ? formik.errors.brandId
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Model Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      formik.setFieldValue("name", e.target.value);
                    }}
                    defaultValue={formik.values.name || modelData?.data.name}
                    autoComplete="off"
                    placeholder="Model Name"
                  />
                  <div className="text-danger">
                    {formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : null}
                  </div>
                </div>

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Updating...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/model-management/Modify"
                        : "/model-management"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddModel;
