import React from "react";
import $ from "jquery";
import { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { fetchBulkEmail } from "./bulkEmailSlice";
import { AccessControlComponent } from "../../../routes/AccessControlOnRoutes";
import { fetchUserManagementPermission } from "../UserManagement/userSlice";
import moment from "moment";
import swal from "sweetalert";
import axios from "axios";

export default function BulkEmailList() {
  let { action } = useParams();
  const dispatch = useDispatch();
  const emails = useSelector((state) => state?.bulk_email?.bulkEmails);
  console.log('emails ', emails);
  const isLoading = useSelector((state) => state.bulk_email.isLoading);
  const error = useSelector((state) => state.bulk_email.error);
  const [permissionToModify, setPermissionToModify] = useState(true);
  const dashboardPermission = useSelector((state) => state.user.dashboardPermission.data);
  const isdashboardPermissionLoading = useSelector((state) => state.sidebar.data.isLoading);
  const dashboardPermissionError = useSelector((state) => state.sidebar.data.error);
  useEffect(() => {
    if (dashboardPermission && localStorage.getItem("user_role") !== "super_admin") {
      let permission = AccessControlComponent({ dashboardKey: process.env.REACT_APP_BULKNOTIFICATION, data: dashboardPermission[0] });
      setPermissionToModify(permission);
    }
  }, [dashboardPermission]);
 
  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();
    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [
          {
            extend: "excelHtml5",
            title: "",
            text: "Export",
            filename: "Bulk_emails",
            className: "btn btn-info mt-2",
          },
        ],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
      });
    }, 1000);
  };
  

  useEffect(() => {
    dispatch(fetchBulkEmail());
    initializeDataTable();
  }, [dispatch, dashboardPermission]);
  useEffect(() => {
    dispatch(fetchUserManagementPermission());
  }, []);


  const handleDeleteChange = async (id) => {
    await axios
      .get(process.env.REACT_APP_API_KEY + "bulk-email-delete/" + id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        swal({
          title: "Updated!",
          text: "Delete Successfully",
          icon: "success",
          button: "Okay",
        });
        initializeDataTable();
        dispatch(fetchBulkEmail());
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteChange(id);

      setTimeout(function () {
        dispatch(fetchBulkEmail());

        if(isLoading === false){
          initializeDataTable();  
        }
      }, 1200);
        
      }
    });
  };

  if (isLoading || isdashboardPermissionLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error || dashboardPermissionError) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }

  const renderUser = (
    <table id="myTable" className="table table-striped my-3">
      <thead className="table-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Subject</th>
          <th scope="col">Date</th>
          <th scope="col">Send To</th>
          {permissionToModify &&( <th data-orderable="false">Action(s)</th>)}
        </tr>
      </thead>
      <tbody>
        {emails?.data?.map((item, index) => (
          <React.Fragment key={item.id}>
            <tr>
              <td>{++index}</td>
              <td>{item.subject}</td>
              <td data-sort={moment(item.send_date).format('YYYY.MM.DD HH.mm.ss')}>{moment(item.send_date).format(process.env.REACT_APP_DATE_FORMAT)}</td>
              <td>{(item.send_to == 'user' ? 'Buyers' : (item.send_to  == 'seller' ? 'Seller' : 'Both') )} </td>
              {permissionToModify &&(<td>
                <Link
                  to={{ pathname: "/bulk-email/edit-email" }}
                  state={{ id: item.id, action: action }}
                >
                  <span className="material-icons" title="Edit">
                    edit
                  </span>
                </Link>
                &nbsp;&nbsp;
                
                <span
                  style={{ color: "red", cursor: "pointer" }}
                  className="material-icons-outlined cursor-default"
                  onClick={() => {
                    handleDelete(item.id);
                  }}
                  title="Delete"
                >
                  delete
                </span>

                &nbsp;&nbsp;
                
              </td>)}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Bulk Email List</h1>
          </div>
        </div>
      </div>
      {permissionToModify && (<>
        <NavLink className="btn btn-info" to="/bulk-email/new-email">
          New Email Broadcast
        </NavLink>
      </>)}
      {isLoading ? <LoadingSpinner /> : renderUser}
    </div>
  );
}
