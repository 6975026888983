import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchHistoryLists = createAsyncThunk(
  "history/fetchData",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "coins-purchase-history",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const brandModelSlice = createSlice({
  name: "history-data",
  initialState: {
    historyList: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistoryLists.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchHistoryLists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.historyList = action.payload;
      })
      .addCase(fetchHistoryLists.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { bannersData } = brandModelSlice.actions;
export default brandModelSlice.reducer;
