import React from "react";
import $ from "jquery";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect, useState } from "react";
import { Link, NavLink, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { fetchProductList } from "./productSlice";
import { fetchUserManagementPermission } from "../UserManagement/userSlice";
import { AccessControlComponent } from "../../../routes/AccessControlOnRoutes";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import DatePicker from "react-datepicker";
export default function ProductList() {
  let { action } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [permissionToModify, setPermissionToModify] = useState(true);
  const products = useSelector((state) => state.product.productsList);
  const isLoading = useSelector((state) => state.product.isLoading);
  const error = useSelector((state) => state.product.error);
  const dashboardPermission = useSelector((state) => state.user.dashboardPermission.data);
  const isdashboardPermissionLoading = useSelector((state) => state.sidebar.data.isLoading);
  const dashboardPermissionError = useSelector((state) => state.sidebar.data.error);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();
    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
      });
    }, 1200);
  };
  useEffect(() => {
    dispatch(fetchProductList());
    initializeDataTable();
    document.body.className = "pace-done no-loader users";
    if ($("body").hasClass("users")) {
      $(".dt-buttons").addClass("csv-button");
    }
    return () => {
      document.body.className = "pace-done no-loader";
    };
  }, [dispatch, location.key, dashboardPermission]);
  
  useEffect(() => {
    dispatch(fetchUserManagementPermission());
  }, []);
  useEffect(() => {
    if (dashboardPermission && localStorage.getItem("user_role") !== "super_admin") {
      let permission = AccessControlComponent({ dashboardKey: process.env.REACT_APP_PRODUCTMANAGEMENT, data: dashboardPermission[0] });
      setPermissionToModify(permission);
    }
  }, [dashboardPermission]);

  if (isLoading || isdashboardPermissionLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } 

  function capitalizeFirstWord(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
 

  if (error || dashboardPermissionError) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }
  const handleChange = (id, status) => {
    if (status === "active") {
      swal({
        title: "Are you sure?",
        text: "This Item will become Inactive!",
        icon: "warning",
        buttons: ["Cancel", "Inactive"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .get(process.env.REACT_APP_API_KEY + "product-status/" + id, {
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then(function (response) {
              swal({
                title: "Updated!",
                text: "Status Updated Successfully",
                icon: "success",
                button: "Okay",
              });
              initializeDataTable();
              dispatch(fetchProductList());
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    }
    if (status === "inactive") {
      swal({
        title: "Are you sure?",
        text: "This Item will become Active!",
        icon: "warning",
        buttons: ["Cancel", "Activate"],
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .get(process.env.REACT_APP_API_KEY + "product-status/" + id, {
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then(function (response) {
              swal({
                title: "Updated!",
                text: "Status Updated Successfully",
                icon: "success",
                button: "Okay",
              });
              initializeDataTable();
              dispatch(fetchProductList());
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    }
  };
  const exportExcel = async () => {
    

    await axios
		  .get(process.env.REACT_APP_API_KEY + "product-download" ,
		  {
        params:{
          sdate: fromDate,
          edate: toDate
        },
		    headers: {
		    "Content-type": "Application/json",
		    Authorization: `Bearer ${localStorage.getItem("token")}`,
		    }   
		  }).then(function (response) {  
        if(response.data.data.erro == false){
          window.location.href = response.data.data.url;
        }
		    
		    
		  }).catch(function (error) {        
		    console.log(error);
		  });
	    
  }
  const renderUser = (
    <div className="data-list-responsive">
      <table id="myTable" className="table-scroll table table-striped my-3">
        <thead className="table-dark">
          <tr>
            <th>Sr.No.</th>
            <th>Title</th>
            <th>Price (S$)</th>
            <th>Brand</th>
            <th>Model</th>
            <th>Dated</th>
            <th>Owner</th>
            <th>User ID</th>
            <th>Email</th>
            <th>Dial</th>
            <th>Bracelet</th>
            <th>Condition</th>
            <th>Accessories</th>
            <th>Custom</th>
            <th>SoldOut / Available</th>
            <th>Created On</th>
            <th>Status</th>
            {permissionToModify && (<>
              <th scope="col" className="no-export" data-orderable="false">
                Action(s)
              </th>
            </>)}
            {!permissionToModify && (<>
              <th scope="col" className="no-export" data-orderable="false">
                Action(s)
              </th>
            </>)}
          </tr>
        </thead>
        <tbody>
          {products?.data?.map((item, index) => (
            <React.Fragment key={item.id}>
              <tr>
                <td>{++index}</td>
                <td>{item.title}</td>
                <td>{item.price}</td>
                <td>{ item.brand_custom  ? item.brand_custom : item.brand?.name}  </td>
                <td>{ item.model_custom ? item.model_custom : item.model?.name}  </td>
                <td>{(item.dated) ? moment(item.dated).format(process.env.REACT_APP_DATE_FORMAT): ''}</td>
                <td>{item.user?.name}</td>
                <td>{item.user?.user_id_tag}</td>
                <td>{item.user?.email}</td>
                <td>{(item.dial_custom) ? item.dial_custom : item.dial_name}</td>
                <td>{(item.bracelet_custom) ? item.bracelet_custom : item.bracelet_acc_name}</td>
                <td>{item?.watch_condition_text}</td>
                <td>{item?.accessory_name}</td>
                <td>{item?.custom_gem_set}</td>
                <td>{capitalizeFirstWord((item.product_status == 'sold_out' ? 'Sold Out' : item.product_status))}</td>
                <td data-sort={moment(item.created_at).format('YYYY.MM.DD HH.mm.ss')}>{moment(item.created_at).format(process.env.REACT_APP_DATE_FORMAT)}</td>
                <td>{capitalizeFirstWord(item.status)}</td>
                  {permissionToModify && (
                    <td>
                      <Link
                        to={{ pathname: "/product-management/edit-product" }}
                        state={{ id: item.id, action: action }}
                      >
                        <span className="material-icons" title="Edit">
                          edit
                        </span>
                      </Link>
                      &nbsp;&nbsp;
                      <Link
                        to={{ pathname: "/product-management/view-product" }}
                        state={{ id: item.id, action: action }}
                      >
                        <span className="material-icons" title="View">
                          visibility
                        </span>
                      </Link>
                      &nbsp;&nbsp;
                      <span
                        style={
                          item.status === "active"
                            ? { color: "green", cursor: "pointer" }
                            : { color: "red", cursor: "pointer" }
                        }
                        className="material-icons-outlined"
                        onClick={() => {
                          handleChange(item.id, item.status);
                        }}
                        title={item.status === "active" ? "Active" : "Inactive"}
                      >
                        {item.status === "active" ? "toggle_on" : "toggle_off"}
                      </span>
                    </td>
                )}
                {!permissionToModify && (
                  <td>
                    <Link
                      to={{ pathname: "/product-management/view-product" }}
                      state={{ id: item.id, action: action }}
                    >
                      <span className="material-icons" title="View">
                        visibility
                      </span>
                    </Link>
                 </td>
                )}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Product Management</h1>
          </div>
        </div>
      </div>
      {permissionToModify  &&(<>
        <NavLink className="btn btn-info" to="/product-management/add-product">
          Add Product
        </NavLink>
      </>)} &nbsp;<br/>
      <div class="row mt-3">
      <div className="col-md-3">
      <DatePicker
                      showIcon
                      name="dated"
                      selected={fromDate}
                      onChange={(date) => setFromDate(date)}
                      selectsStart
                      startDate={fromDate}
                      endDate={toDate}
                      maxDate={new Date()}
                      className="form-control"
                      autoComplete="off"
                      placeholderText="From Date"
                      dateFormat="yyyy-MM-dd"
                      
                    />
          </div>
          <div className="col-md-3">
                     <DatePicker
                      showIcon
                      name="dated"
                      selected={toDate}
                      onChange={(date) => setToDate(date)}
                      selectsEnd
                      startDate={fromDate}
                      endDate={toDate}
                      minDate={fromDate}
                      maxDate={new Date()}
                      className="form-control"
                      autoComplete="off"
                      placeholderText="To Date"
                      dateFormat="yyyy-MM-dd"
                      
                    />
       </div>

      </div>
      <div className="row">
      <div className="col-md-12">
          <button type="submit" onClick={exportExcel} className="btn btn-primary btn-info mt-2">Export Data</button>
      </div>
      </div>
      {isLoading ? <LoadingSpinner /> : renderUser}
    </div>
  );
}