import React from "react";
import { useRef, useMemo, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { fetchBrandList, fetchModelList } from "../BrandAndModel/brandSlice";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
function AddBrand() {
  const [allDropDown, setAllDropDown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modelId, setModelId] = useState();
  const [brandName, setBrandName] = useState();
  const [newCreatedModel, setNewCreatedModel] = useState(false);
  const dispatch = useDispatch();
  const accessories = []; //accessories
  const dialOption = []; //DIAL
  const dialMarOption = []; //DIALMARKERS
  const caseSizeOption = []; //CASESIZE
  const movementOption = []; //MOVEMENT
  const caseMaterialOption = []; //CASEMATERIAL
  const strapOption = []; //STRAPBRACELET
  const claspOption = []; //CLASP

  const [newBrand, setNewBrand] = useState();
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const options = [];
  const modelOptions = [];

  const models = useSelector((state) => state.brand.modelList);
  if (models) {
    models.data?.forEach((element) => {
      modelOptions.push({ value: element.id, label: element.name });
    });
  }

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchBrandList());
    if (modelId) {
      dispatch(fetchModelList(modelId));
      getAllDropDownList(modelId);
    }
    
  }, [dispatch, modelId]);
  const brands = useSelector((state) => state.brand.brandsList);
  const dataLoading = useSelector((state) => state.brand.isLoading);
  if (brands) {
    brands.data?.forEach((element) => {
      options.push({ value: element.id, label: element.name });
    });
  }
  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
  };
  const validationSchema = Yup.object().shape({
    reference_number: Yup.string()
      .required("Reference Number")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(30, "Reference Name must not exceed 30 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
    thumb_image:Yup.string()
    .required("Please select image")
  });

  const getAllDropDownList = async (modelId) => {
    await axios({
      url: process.env.REACT_APP_API_KEY + "get-product-dropdown/"+modelId,
      method: "get",
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        console.log("response==>", response.data.data);
        setAllDropDown(response.data.data);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    // Append the selected image to the FormData
    formik.setFieldValue("thumb_image", file);
  };
  if (allDropDown) {
    allDropDown.ACCESSORIES?.forEach((element) => {
      accessories.push({ value: element.id, label: element.name });
    });
    allDropDown.DIAL?.forEach((element) => {
      dialOption.push({ value: element.id, label: element.name });
    });
    allDropDown.DIALMARKERS?.forEach((element) => {
      dialMarOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CASESIZE?.forEach((element) => {
      caseSizeOption.push({ value: element.id, label: element.name });
    });
    allDropDown.MOVEMENT?.forEach((element) => {
      movementOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CASEMATERIAL?.forEach((element) => {
      caseMaterialOption.push({ value: element.id, label: element.name });
    });
    allDropDown.STRAPBRACELET?.forEach((element) => {
      strapOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CLASP?.forEach((element) => {
      claspOption.push({ value: element.id, label: element.name });
    });
  }

  const formik = useFormik({
    initialValues: {
      reference_number: "",
      brand: "",
      model: "",
      dial_markers: "",
      case_material: "",
      movement: "",
      case_size: "",
      clasp: "",
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (data) => {
      // alert('aaaaaaaaaaaa');
      const formData = new FormData();
      formData.append("thumb_image", selectedImage);
      formData.append("reference_number", data.reference_number);
      formData.append("brand", data.brand);
      formData.append("model", data.model);
      formData.append("dial", data.dial);
      formData.append("dial_markers", data.dial_markers);
      formData.append("bracelet", data.bracelet);
      formData.append("case_size", data.case_size);
      formData.append("movement", data.movement);
      formData.append("case_material", data.case_material);
      formData.append("clasp", data.clasp);

      setIsLoading(true);
      console.log("DATA============>", data);
      await axios
        .post(process.env.REACT_APP_API_KEY + "add-auto-fill", formData, {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            type: "brand",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Created Successfully!",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/auto-fills-management")
                : navigate("/auto-fills-management/Modify");
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            swal("Oops", error.response.data.message, "error");
          }
          setIsLoading(false);
        });
    },
  });


  useEffect(() => {
    console.log('formik001: ', formik.errors)
  }, [formik]);


  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Add Auto Fill</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="title" className="form-label">
                    Reference Number{" "}
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <input
                    name="reference_number"
                    id="reference_number"
                    type="text"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.reference_number}
                    autoComplete="off"
                    placeholder="Enter reference number."
                  />
                  <div className="text-danger">
                    {formik.errors.reference_number &&
                    formik.touched.reference_number
                      ? formik.errors.reference_number
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Choose Brand
                  </label>
                  <CreatableSelect
                    name="brand"
                    isClearable
                    isDisabled={isSelectLoading}
                    isLoading={isSelectLoading}
                    onChange={(selected) => {
                      console.log(selected, "==================>>>");
                      formik.setFieldValue("brand", selected?.value);
                      setNewBrand(selected);
                      setModelId(selected?.value);
                      setBrandName(selected?.label);
                      setNewCreatedModel(null);
                    }}
                    onCreateOption={(inputValue) => {
                      setIsSelectLoading(true);
                      setTimeout(() => {
                        const newOption = {
                          value: inputValue,
                          label: inputValue,
                        };
                        setIsSelectLoading(false);
                        // setOptions((prev) => [...prev, newOption]);
                        options.push(newOption);
                        console.log("optio----------ns", options);
                        setNewBrand(newOption);
                        setNewCreatedModel(null);

                        formik.setFieldValue("brand", newOption.value);
                      }, 1000);
                    }}
                    options={options}
                    value={newBrand}
                  />
                  <div className="text-danger">
                    {formik.errors.brand_id && formik.touched.brand_id
                      ? formik.errors.brand_id
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="model" className="form-label">
                    Model
                  </label>
                  <CreatableSelect
                    name="model"
                    isClearable
                    isDisabled={isSelectLoading}
                    isLoading={isSelectLoading}
                    onChange={(selected) => {
                      formik.setFieldValue("model", selected?.value);
                      setNewCreatedModel(selected);
                    }}
                    onCreateOption={(inputValue) => {
                      setIsSelectLoading(true);
                      setTimeout(() => {
                        const newOption = {
                          value: inputValue,
                          label: inputValue,
                        };
                        setIsSelectLoading(false);
                        options.push(newOption);
                        console.log("options", options);
                        setNewCreatedModel(newOption);
                        formik.setFieldValue("model", newOption.value);
                      }, 1000);
                    }}
                    options={modelOptions}
                    value={newCreatedModel}
                  />
                  <div className="text-danger">
                    {formik.errors.model && formik.touched.model
                      ? formik.errors.model
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="dial" className="form-label">
                    Dial
                    
                  </label>
                  <Select
                    name="dial"
                    onChange={(selected) => {
                      formik.setFieldValue("dial", selected.value);
                    }}
                    options={dialOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors.dial && formik.touched.dial
                      ? formik.errors.dial
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="dial_markers" className="form-label">
                    Dial Markers
                  </label>
                  <Select
                    name="dial_markers"
                    id="dial_markers"
                    onChange={(selected) => {
                      formik.setFieldValue("dial_markers", selected.value);
                    }}
                    options={dialMarOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger"></div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="case_size" className="form-label">
                    Case Size
                  </label>
                  <Select
                    name="case_size"
                    id="case_size"
                    onChange={(selected) => {
                      formik.setFieldValue("case_size", selected.value);
                    }}
                    options={caseSizeOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger"></div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="movement" className="form-label">
                    Movement
                  </label>
                  <Select
                    name="movement"
                    id="movement"
                    onChange={(selected) => {
                      formik.setFieldValue("movement", selected.value);
                    }}
                    options={movementOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger"></div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="case_material" className="form-label">
                    Case Material
                  </label>
                  <Select
                    name="case_material"
                    onChange={(selected) => {
                      formik.setFieldValue("case_material", selected.value);
                    }}
                    options={caseMaterialOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger"></div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="bracelet" className="form-label">
                    Strap/Bracelet
                    
                  </label>
                  <Select
                    name="bracelet"
                    id="bracelet"
                    onChange={(selected) => {
                      formik.setFieldValue("bracelet", selected.value);
                    }}
                    options={strapOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors.bracelet && formik.touched.bracelet
                      ? formik.errors.bracelet
                      : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <label htmlFor="clasp" className="form-label">
                    Clasp
                  </label>
                  <Select
                    name="clasp"
                    id="clasp"
                    onChange={(selected) => {
                      formik.setFieldValue("clasp", selected.value);
                    }}
                    options={claspOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger"></div>
                </div>
                <div className="col-md-12">
                <label htmlFor="clasp" className="form-label">
                    Image 
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label><br/>
                  <input
                    type="file"
                    accept="image/*"
                    name="thumb_image"
                    onChange={handleImageChange}
                  />
                  {selectedImage && (
                    <img
                    height={"250"}
                      src={URL.createObjectURL(selectedImage)}
                      alt="Selected"
                    />
                  )}

                  <div className="text-danger">
                    {formik.errors.thumb_image 
                      ? formik.errors.thumb_image
                      : null}
                  </div>

                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Saving...
                      </>
                    ) : (
                      "Save"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/auto-fills-management/Modify"
                        : "/auto-fills-management"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddBrand;
