import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
export const priceChartAdapter = createEntityAdapter();

export const fetchDataList = createAsyncThunk(
  "price-chart/fetchData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + `pctalert-data`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const DeleteRowtData = createAsyncThunk(
  "price-chart/delete-price",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "delete-pctalert-data/" + id,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const responseData = response.data;
      return responseData;
    } catch (error) {
      console.log("Error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const initialState = priceChartAdapter.getInitialState({
  chartList: [],
  modelList: [],
  isLoading: false,
  error: null,
  addLoading: false,
  addedResponseData: [],
  deleteResponse: null,
});

const priceChartSlice = createSlice({
  name: "brand-model",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDataList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chartList = action.payload;
      })
      .addCase(fetchDataList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(DeleteRowtData.rejected, (state, action) => {
        state.addLoading = false;
        state.error = action.error.message;
      })
      .addCase(DeleteRowtData.pending, (state) => {
        state.addLoading = true;
        state.error = null;
      })
      .addCase(DeleteRowtData.fulfilled, (state, action) => {
        swal({
          title: "Success!",
          text: action.payload?.message,
          icon: "success",
          button: "Okay",
        });
        state.addLoading = false;
        state.deleteResponse = action.payload;
      });
  },
});

export const { chartsData } = priceChartSlice.actions;
export default priceChartSlice.reducer;
