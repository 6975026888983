import React from "react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
function AddBrand() {
  const [isLoading, setIsLoading] = useState(false);
  const [reportType, setReportType] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const navigate = useNavigate();
 
  

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    
    validateOnChange: true,
    // validateOnBlur: false,
    onSubmit: async (data) => {
      setIsLoading(true);

      await axios
        .post(process.env.REACT_APP_API_KEY + "reports", data, {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            type: reportType,
            sDate: startDate,
            eDate: endDate,
          },
        })
        .then((response) => {
          
          if (response.status === 200) {
            if(response.data.data.erro == false){
              window.location.href = response.data.data.url;
            }
          } else {
            swal("Oops", response.data.message, "error");
          }
        })
        .catch((error) => {
          swal("Oops", error.response.data.message, "error");
        });
        setIsLoading(false);
    },
  });

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Analyze Reports</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
              <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">From Date</label>
                  <DatePicker className="form-control" name="startDate" maxDate={new Date()} selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>

                <div className="col-md-6">
                <label htmlFor="inputTitle" className="form-label">To Date</label>
                <DatePicker className="form-control" name="endDate" maxDate={new Date()} selected={endDate} onChange={(date) => setEndDate(date)} /> 
                </div>

                <div className="col-12">
                <label htmlFor="inputTitle" className="form-label">Analyze the query of what users searched </label>
                <br/><button
                    type="submit"
                    onClick={() => setReportType('searched')}
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {(isLoading && reportType == 'searched') ?  'Download...' : "Download"}
                  </button>
                </div>

                <div className="col-12">
                <label htmlFor="inputTitle" className="form-label">Analyze the item clicked</label>
                <br/><button
                    type="submit"
                    onClick={() => setReportType('clicked')}
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {(isLoading && reportType == 'clicked') ?  'Download...' : "Download"}
                  </button>
                </div>

                <div className="col-12">
                <label htmlFor="inputTitle" className="form-label">Chat Interaction with Product </label>
                <br/><button
                    type="submit"
                    onClick={() => setReportType('productchat')}
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {(isLoading && reportType == 'productchat') ?  'Download...' : "Download"}
                  </button>
                </div>
                


              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddBrand;
