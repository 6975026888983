import React from "react";
import { useRef, useMemo, useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import { Field } from "formik";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as Yup from "yup";
import img from "../../../logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrandList, fetchModelList } from "../BrandAndModel/brandSlice";
import { fetchSingleProduct } from "../Product/productSlice";
import CreatableSelect from "react-select/creatable";
import PriceLineChart from "../Chart/PriceLineChart";
import ProductBoostDetail from "./BoostDetail"
import LoadingSpinner from "../Loader/LoadingSpinner";
// import { set } from "immer/dist/internal";

function ProductView() {
  const [allDropDown, setAllDropDown] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const state = location.state;
  const [defaultDial, setDefaultDial] = useState("");
  const [defaultDialMarker, setDefaultDialMarker] = useState("");
  const [defaultCaseSize, setDefaultCaseSize] = useState("");
  const [defaultMovement, setDefaultMovement] = useState("");
  const [defaultCaseMaterial, setDefaultCaseMaterial] = useState("");
  const [defaultBracelet, setDefaultBracelet] = useState("");
  const [defaultClasp, setDefaultClasp] = useState("");
  const [defaultFactoryDial, setDefaultFactoryDial] = useState("");
  const [defaultFactoryBracelet, setDefaultFactoryBracelet] = useState("");
  const [defaultFactoryBezel, setDefaultFactoryBezel] = useState("");
  const [defaultFactoryClasp, setDefaultFactoryClasp] = useState("");
  const [defaultFactoryLugs, setDefaultFactoryLugs] = useState("");

  const [defaultCustomGemCase, setDefaultCustomGemCase] = useState("");
  const [defaultCustomGemDial, setDefaultCustomGemDial] = useState("");
  const [defaultCustomGemBracelet, setDefaultCustomGemBracelet] = useState("");
  const [defaultCustomGemBezel, setDefaultCustomGemBezel] = useState("");
  const [defaultCustomGemLugs, setDefaultCustomGemLugs] = useState("");
  const [defaultCustomGemClasp, setDefaultCustomGemClasp] = useState("");
  const dispatch = useDispatch();

  const accessories = []; //accessories
  const dialOption = []; //DIAL
  const dialMarOption = []; //DIALMARKERS
  const caseSizeOption = []; //CASESIZE
  const movementOption = []; //MOVEMENT
  const caseMaterialOption = []; //CASEMATERIAL
  const strapOption = []; //STRAPBRACELET
  const claspOption = []; //CLASP

  const product = useSelector((state) => state.product.product);

  useEffect(() => {
    dispatch(fetchSingleProduct(state));
    getAllDropDownList();
  }, [dispatch, state]);

  const getAllDropDownList = async () => {
    await axios({
      url: process.env.REACT_APP_API_KEY + "get-product-dropdown",
      method: "get",
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        console.log("response==>", response.data.data);
        setAllDropDown(response.data.data);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
  };

  useEffect(() => {
    let dial = allDropDown.DIAL?.filter((el, i) => {
      return el.id === parseInt(product?.productData?.data?.dial);
    });
    let dialMarker = allDropDown.DIALMARKERS?.filter((el, i) => {
      return el.id === parseInt(product?.productData?.data?.dial_markers);
    });
    let caseSize = allDropDown.CASESIZE?.filter((el, i) => {
      return el.id === parseInt(product?.productData?.data?.case_size);
    });
    let movement = allDropDown.MOVEMENT?.filter((el, i) => {
      return el.id === parseInt(product?.productData?.data?.movement);
    });
    let CASEMATERIAL = allDropDown.CASEMATERIAL?.filter((el, i) => {
      return el.id === parseInt(product?.productData?.data?.case_materials);
    });
    let STRAPBRACELET = allDropDown.STRAPBRACELET?.filter((el, i) => {
      return el.id === parseInt(product?.productData?.data?.bracelet);
    });
    let clasp = allDropDown.CLASP?.filter((el, i) => {
      return el.id === parseInt(product?.productData?.data?.clasp);
    });

    if (dial?.length > 0) {
      setDefaultDial(dial?.[0]?.name);
    }
    if (dialMarker?.length > 0) {
      setDefaultDialMarker(dialMarker?.[0]?.name);
    }
    if (caseSize?.length > 0) {
      setDefaultCaseSize(caseSize?.[0]?.name);
    }
    if (movement?.length > 0) {
      setDefaultMovement(movement?.[0]?.name);
    }
    if (CASEMATERIAL?.length > 0) {
      setDefaultCaseMaterial(CASEMATERIAL?.[0]?.name);
    }
    if (STRAPBRACELET?.length > 0) {
      setDefaultBracelet(STRAPBRACELET?.[0]?.name);
    }
    if (clasp?.length > 0) {
      setDefaultClasp(clasp?.[0]?.name);
    }

    if (product?.productData?.data?.gem_set?.length > 0) {
      let arr = [...product?.productData?.data?.gem_set];
      let arrLength = arr.length;
      for (let i = 0; i < arrLength; i++) {
        if (arr[i].type.toLowerCase() === "factory") {
          switch (arr[i].gem_position.toLowerCase()) {
            case "dial":
              setDefaultFactoryDial(arr[i].value);
              break;
            case "bracelet":
              setDefaultFactoryBracelet(arr[i].value);
              break;
            case "bezel":
              setDefaultFactoryBezel(arr[i].value);
              break;
            case "clasp":
              setDefaultFactoryClasp(arr[i].value);
              break;
            case "lugs":
              setDefaultFactoryLugs(arr[i].value);
              break;
            default:
              break;
          }
        }
        if (arr[i].type.toLowerCase() === "custom") {
          switch (arr[i].gem_position.toLowerCase()) {
            case "case":
              setDefaultCustomGemCase(arr[i].value);
              break;
            case "dial":
              setDefaultCustomGemDial(arr[i].value);
              break;
            case "bracelet":
              setDefaultCustomGemBracelet(arr[i].value);
              break;
            case "bezel":
              setDefaultCustomGemBezel(arr[i].value);
              break;
            case "lugs":
              setDefaultCustomGemLugs(arr[i].value);
              break;
            case "clasp":
              setDefaultCustomGemClasp(arr[i].value);
              break;
            default:
              break;
          }
        }
      }
    }
  }, [product, allDropDown]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>View Product: {product?.productData?.data?.title}</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              {/* <form className="row g-3" onSubmit={formik.handleSubmit}> */}
              <div className="product-form-view">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="brand-name">Brand name</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly={true}
                      value={product?.productData?.data?.brand.name}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="modelname">Model name</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly={true}
                      value={product?.productData?.data?.model.name}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="title">Title</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly={true}
                      value={product?.productData?.data?.title}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="accessories">Accessories</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly={true}
                      value={product?.productData?.data?.accessories?.name}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="price">Price</label>
                    <input
                      className="form-control"
                      value={product?.productData?.data?.price}
                      readOnly={true}
                      type="text"
                    />
                  </div>
                  {/* Watch Condition */}
                  <div className="col-md-6">
                    <label htmlFor="watch_condition">Watch condition</label>
                    <input
                      className="form-control"
                      type="text"
                      id="watch_condition"
                      readOnly={true}
                      value={product?.productData?.data?.watch_condition_text}
                    />
                  </div>

                  {/* */}

                  <div className="col-md-6">
                    <label htmlFor="dated" className="form-label">
                      Dated
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="dated"
                      readOnly={true}
                      value={product?.productData?.data?.dated}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="notCertain">Not Certain</label>
                    <input
                      className="form-control"
                      type="text"
                      id="notCertain"
                      readOnly={true}
                      value={product?.productData?.data?.no_certain}
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="description">Description</label>
                    {/* <input className="form-control"
                                            type="text"
                                            id="description"
                                            
                                            value=
                                        /> */}
                    <textarea
                      className="description-box form-control"
                      readOnly={true}
                      id=""
                      rows="10"
                      value={product?.productData?.data?.description}
                    ></textarea>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="gender" className="form-label">
                      Gender
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="gender"
                      readOnly={true}
                      value={product?.productData?.data?.gender_type}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="dial" className="form-label">
                      Dial
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="dial"
                      readOnly={true}
                      value={defaultDial}
                    />
                    <div className="text-danger"></div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="dialMarkers" className="form-label">
                      Dial Markers
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="dialMarkers"
                      readOnly={true}
                      value={defaultDialMarker}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="caseSize">Case Size</label>
                    <input
                      className="form-control"
                      type="text"
                      id="caseSize"
                      readOnly={true}
                      value={defaultCaseSize}
                    />
                    <div className="text-danger"></div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="movement">Movement</label>
                    <input
                      className="form-control"
                      type="text"
                      id="movement"
                      readOnly={true}
                      value={defaultCaseSize}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="caseMaterial">Case Material</label>
                    <input
                      className="form-control"
                      type="text"
                      id="caseMaterial"
                      readOnly={true}
                      value={defaultCaseMaterial}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="bracelet" className="form-label">
                      Strap/Bracelet
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="bracelet"
                      readOnly={true}
                      value={defaultBracelet}
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="clasp" className="form-label">
                      Clasp
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="clasp"
                      readOnly={true}
                      value={defaultClasp}
                    />
                  </div>
                  {/* Gem Set */}
                  <div className="col-md-12">
                    <label htmlFor="factoryGem" className="form-label">
                      Factory Gem Set?
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="factoryGem"
                      readOnly={true}
                      value={product?.productData?.data?.factory_gem_set}
                    />
                  </div>

                  {product?.productData?.data?.factory_gem_set?.toLowerCase() ===
                  "yes" ? (
                    <>
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="factoryGemDial">Dial</label>
                        </div>
                        <div className="col-md-9 pb-2">
                          <input
                            className="form-control"
                            type="text"
                            id="factoryGemDial"
                            readOnly={true}
                            value={defaultFactoryDial}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="factory_gemBracelet">Bracelet</label>
                        </div>
                        <div className="col-md-9 pb-2">
                          <input
                            className="form-control"
                            type="text"
                            id="factory_gemBracelet"
                            readOnly={true}
                            value={defaultFactoryBracelet}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="factory_gemBezel">Bezel</label>
                        </div>
                        <div className="col-md-9 pb-2">
                          <input
                            className="form-control"
                            type="text"
                            id="factory_gemBezel"
                            readOnly={true}
                            value={defaultFactoryBezel}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="factory_gemLugs">Lugs</label>
                        </div>
                        <div className="col-md-9 pb-2">
                          <input
                            className="form-control"
                            type="text"
                            id="factory_gemLugs"
                            readOnly={true}
                            value={defaultFactoryLugs}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="factory_gemclasp">Clasp</label>
                        </div>
                        <div className="col-md-9 pb-2">
                          <input
                            className="form-control"
                            type="text"
                            id="factory_gemclasp"
                            readOnly={true}
                            value={defaultFactoryClasp}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="col-md-12">
                    <label htmlFor="locationDefault" className="form-label">
                      Location of this product
                    </label>
                    <input
                      className="form-control"
                      name="locationDefault"
                      type="text"
                      readOnly={true}
                      value={product?.productData?.data?.location}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Custom Gem Set?</label>
                    <input
                      className="form-control"
                      type="text"
                      id="customGem"
                      readOnly={true}
                      value={product?.productData?.data?.custom_gem_set}
                    />
                  </div>
                  {product?.productData?.data?.custom_gem_set?.toLowerCase() ===
                  "yes" ? (
                    <>
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="customGemcase">Case</label>
                        </div>
                        <div className="col-md-9 pb-2">
                          <input
                            className="form-control"
                            type="text"
                            id="customGemcase"
                            readOnly={true}
                            value={defaultCustomGemCase}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="customGemDial">Dial</label>
                        </div>
                        <div className="col-md-9 pb-2">
                          <input
                            className="form-control"
                            type="text"
                            id="customGemDial"
                            readOnly={true}
                            value={defaultCustomGemDial}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="custom_gemBracelet">Bracelet</label>
                        </div>
                        <div className="col-md-9 pb-2">
                          <input
                            className="form-control"
                            type="text"
                            id="custom_gemBracelet"
                            readOnly={true}
                            value={defaultCustomGemBracelet}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="custom_gemBezel">Bezel</label>
                        </div>
                        <div className="col-md-9 pb-2">
                          <input
                            className="form-control"
                            type="text"
                            id="custom_gemBezel"
                            readOnly={true}
                            value={defaultCustomGemBezel}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="custom_gemLugs">Lugs</label>
                        </div>
                        <div className="col-md-9 pb-2">
                          <input
                            className="form-control"
                            type="text"
                            id="custom_gemLugs"
                            readOnly={true}
                            value={defaultCustomGemLugs}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="custom_gemclasp">Clasp</label>
                        </div>
                        <div className="col-md-9 pb-2">
                          <input
                            className="form-control"
                            type="text"
                            id="custom_gemclasp"
                            readOnly={true}
                            value={defaultCustomGemClasp}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mt-5">
                <PriceLineChart />
              </div>

              <div className="mt-5">
                    <ProductBoostDetail/>
              </div>
              
              <Link
                className="btn btn-primary mt-3"
                to={
                  localStorage.getItem("user_role") === "sub_admin"
                    ? "/product-management/Modify"
                    : "/product-management"
                }
              >
                Back
              </Link>
            </div>
          </div>
        </div>
        {/* </form> */}
      </div>
      
    </>
  );
}
export default ProductView;
