import { combineReducers } from "@reduxjs/toolkit";
import { persistedSidebarReducer } from "../components/layout/sidebarSlice";
import loginSlice from "../components/Auth/loginSlice";
import userSlice from "../components/UserManagement/userSlice";
import sliderSlice from "../components/SliderBanner/sliderSlice";
import emailSlice from "../components/EmailTemplates/emailSlice";
import brandModelSlice from "../components/BrandAndModel/brandModelSlice";
import brandSlice from "../components/BrandAndModel/brandSlice";
import settingsSlice from "../components/GeneralSettings/settingsSlice";
import productSlice from "../components/Product/productSlice";
import { staticReducer } from "../components/StaticContent/staticContentSlice";
import bulkEmailSlice from "../components/BulkEmail/bulkEmailSlice";
import priceChartSlice from "../components/BrandModelPriceChart/priceChartSlice";
import boostSlice from "../components/BoostPlan/boostSlice";
import CoinsHistorySlice from "../components/CoinsPlan/CoinsHistorySlice";
import staffManagementSlice from "../components/StaffManagement/staffManagementSlice";
import bulkPushSlice from "../components/BulkPushNotification/bulkPushSlice";
import filterSlice from "../components/FilterManagement/filterSlice";
import autoFillsSlice from "../components/AutoFils/autoFilsSlice";

const rootReducer = combineReducers({
  sidebar: persistedSidebarReducer,
  login: loginSlice,
  user: userSlice,
  banner: sliderSlice,
  email_templates: emailSlice,
  brandModel: brandModelSlice,
  brand: brandSlice,
  settings: settingsSlice,
  product: productSlice,
  static_contents: staticReducer,
  priceChartData: priceChartSlice,
  bulk_email: bulkEmailSlice,
  bulkPush: bulkPushSlice,
  boosts: boostSlice,
  CoinsHistory: CoinsHistorySlice,
  staffManagement: staffManagementSlice,
  filter: filterSlice,
  autoFills: autoFillsSlice,
});

export default rootReducer;
