import React from "react";
import $ from "jquery";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect, useState } from "react";
import { Link, NavLink, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { fetchBannerList } from "./sliderSlice";
import { fetchUserManagementPermission } from "../UserManagement/userSlice";
import { AccessControlComponent } from "../../../routes/AccessControlOnRoutes";
import swal from "sweetalert";
import axios from "axios";
import moment from "moment";

export default function SliderBannerManagement() {
  let { action } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const banners = useSelector((state) => state.banner.bannerList);
  console.log("banners", banners);
  const isLoading = useSelector((state) => state.banner.isLoading);
  const error = useSelector((state) => state.banner.error);
  const [permissionToModify, setPermissionToModify] = useState(true);
  const dashboardPermission = useSelector((state) => state.user.dashboardPermission.data);
  const isdashboardPermissionLoading = useSelector((state) => state.sidebar.data.isLoading);
  const dashboardPermissionError = useSelector((state) => state.sidebar.data.error);
  useEffect(() => {
    if (dashboardPermission && localStorage.getItem("user_role") !== "super_admin") {
      let permission = AccessControlComponent({ dashboardKey: process.env.REACT_APP_HOMESCREENBANNER, data: dashboardPermission[0] });
      setPermissionToModify(permission);
    }
  }, [dashboardPermission]);
  
  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();
    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [
          {
            extend: "excelHtml5",
            title: "",
            text: "Export",
            filename: "Banners",
            className: "btn btn-info mt-2",

          },
        ],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
      });
    }, 1000);
  };
  useEffect(() => {
  }, []);

  useEffect(() => {
    dispatch(fetchBannerList());
    initializeDataTable();
  }, [dispatch, location.key,dashboardPermission]);
  useEffect(() => {
    dispatch(fetchUserManagementPermission());
  }, []);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure want to delete?",
      icon: "warning",
      buttons: ["Cancel", "Submit"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteChange(id);
      }
    });
  };
  const handleDeleteChange = async (id) => {
    await axios
      .get(process.env.REACT_APP_API_KEY + "slider-banner-delete/" + id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        swal({
          title: "Updated!",
          text: "Delete Successfully",
          icon: "success",
          button: "Okay",
        });
        initializeDataTable();
        dispatch(fetchBannerList());
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  if (isLoading || isdashboardPermissionLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  const capitalizeFirstWord = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (error || dashboardPermissionError) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }
  const handleChange = async (id) => {
    await axios
      .get(process.env.REACT_APP_API_KEY + "banner-status/" + id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        swal({
          title: "Updated!",
          text: "Status Updated Successfully",
          icon: "success",
          button: "Okay",
        });
        initializeDataTable();
        dispatch(fetchBannerList());
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderUser = (
    <table id="myTable" className="table table-striped my-3">
      <thead className="table-dark">
        <tr>
          <th scope="col">Sr.No.</th>
          <th scope="col">Name</th>
          {/* <th scope="col">Department</th> */}

          <th scope="col">Link</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col" data-orderable="false">
            Image
          </th>
          <th scope="col">Status</th>
          {permissionToModify && (<th scope="col" data-orderable="false">
            Action(s)
          </th>)}
        </tr>
      </thead>
      <tbody>
        {banners?.data?.map((item, index) => (
          <React.Fragment key={item.id}>
            <tr>
              <td>{++index}</td>
              <td>{item.title}</td>
              <td>{item.link}</td>
              <td data-sort={moment(item.start_date).format('YYYY.MM.DD HH.mm.ss')}>{moment(item.start_date).format(process.env.REACT_APP_DATE_FORMAT)}</td>
              <td data-sort={moment(item.end_date).format('YYYY.MM.DD HH.mm.ss')}>{moment(item.end_date).format(process.env.REACT_APP_DATE_FORMAT)}</td>
              <td>
                <img src={item?.image} width="60px" alt="img" />
              </td>
              <td>{capitalizeFirstWord(item.status)}</td>
              {permissionToModify && (<td>
                <Link
                  to={{ pathname: "/banner-list/edit-banner" }}
                  state={{ id: item.id, action: action }}
                >
                  <span className="material-icons" title="Edit">
                    edit
                  </span>
                </Link>
                &nbsp;&nbsp;
                <span
                  style={
                    item.status === "active"
                      ? { color: "green", cursor: "pointer" }
                      : { color: "red", cursor: "pointer" }
                  }
                  className="material-icons-outlined"
                  onClick={() => {
                    handleChange(item.id);
                  }}
                  title={item.status === "active" ? "Active" : "Inactive"}
                >
                  {item.status === "active" ? "toggle_on" : "toggle_off"}
                </span>
                &nbsp;&nbsp;
                <span
                    style={{ color: "red", cursor: "pointer" }}
                    className="material-icons-outlined cursor-default"
                    onClick={() => {
                      handleDelete(item.id);
                    }}
                    title="Delete"
                  >
                    delete
                  </span>

              </td>)}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Home Screen Banner Management</h1>
          </div>
        </div>
      </div>
      {permissionToModify &&(<>
        <NavLink className="btn btn-info" to="/banner-list/add-banner">
          Add Banner
        </NavLink>
      </>)}
      {isLoading ? <LoadingSpinner /> : renderUser}
    </div>
  );
}
