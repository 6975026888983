import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchProductAccessoriesList = createAsyncThunk(
  "brandModel/fetchData",
  async (brandId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "get-product-accessories",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            type: "all",
            brand_info: brandId,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchProductAccessoriesType = createAsyncThunk(
  "brandModel/fetchProductType",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "get-accessories-type",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchSingleAccessories = createAsyncThunk(
  "model/fetchSingleMode",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "get-accessories-detail/" + id,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const filterSlice = createSlice({
  name: "brand-model",
  initialState: {
    productAccessoriesList: [],
    productAccessoriesType: [],
    isLoading: false,
    filterData: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductAccessoriesList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProductAccessoriesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productAccessoriesList = action.payload;
      })
      .addCase(fetchProductAccessoriesList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchProductAccessoriesType.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProductAccessoriesType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productAccessoriesType = action.payload;
      })
      .addCase(fetchProductAccessoriesType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSingleAccessories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleAccessories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.filterData = action.payload;
      })
      .addCase(fetchSingleAccessories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { bannersData } = filterSlice.actions;
export default filterSlice.reducer;
