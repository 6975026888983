import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../Loader/LoadingSpinner";
import moment from "moment";
function EditBulkPushNotification() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const navigate = useNavigate();
  const params = useLocation();
  useEffect(() => {
    getBrandDataById();
  }, []);

  const getBrandDataById = async () => {
    setIsLoading(true);
    await axios
      .get(process.env.REACT_APP_API_KEY + "get-push-notification/" + params.state.id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setInputs(res.data.data);
        
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error>>", error.message);
      });
  };
  
 

  

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>View Notification</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                  <strong>Subject Name:</strong>
                  </label><br/>
                  {inputs.subject_name}
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputStartDate" className="form-label">Send Date: </label>
                  <br/>
                  {moment(inputs.date).format(process.env.REACT_APP_DATE_FORMAT)}
                  
                </div>
                <div className="col-md-6">
                    <label htmlFor="inputStartDate" className="form-label"><strong>Send To:</strong></label>
                    <br/>
                    {inputs.send_to}
                    
                </div> 
                <div className="col-12">
                    <label htmlFor="message" className="form-label">
                        <strong>Tell the customers about this watch:</strong>
                    </label><br/>
                    {inputs.message}
                    
                </div>
                
                <div className="col-12">
                  

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                         "/bulk-push-notification"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditBulkPushNotification;
