import { React, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Select from "react-select";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const options = [
    { value: "super_admin", label: "Super Admin" },
    { value: "sub_admin", label: "Sub Admin" },
  ];
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .trim()
      .email("Email is invalid"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      role: "super_admin",
    },
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (data) => {
      setIsLoading(true);
      await axios
        .post(process.env.REACT_APP_API_KEY + "forgot-password", data, {
          params: {
            type: data.role,
          },
        })
        .then((res) => {
          if (res.data.success) {
            formik.values.email = "";
            swal(
              "Success",
              "Forgot password link has been sent to your registered email!",
              "success"
            );
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          swal("Oops", error.response.data.message, "error");
        });
      // console.log(JSON.stringify(data, null, 2));
    },
  });

  return (
    <div className="app app-auth-forgot-pass align-content-stretch d-flex flex-wrap justify-content-end">
      <div className="app-auth-background"></div>
      <div className="app-auth-container">
        <div className="sign-in-container">
          <div className="logo-forgot">
            <NavLink to="#"></NavLink>
          </div>
          <form className="row g-3" onSubmit={formik.handleSubmit}>
            <div className="auth-credentials m-b-xxl" style={{ marginTop: 30 }}>
              <h3>Forgot Password ?</h3> No worries,we’ll send you reset
              Instructions
              <div className="form-group">
                <label htmlFor="signInEmail" className="form-label mt-3">
                  Enter Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  className={
                    formik.errors.email
                      ? "form-control error-border "
                      : "form-control"
                  }
                  id="signInEmail"
                  aria-describedby="signInEmail"
                  placeholder="Email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <div className="text-danger">
                  {formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : null}
                </div>
              </div>
              <div className="form-group my-3">
                <label htmlFor="signInPassword" className="form-label">
                  User Role
                </label>
                <div className="icon-input">
                  <Select
                    name="role"
                    onChange={(selected) => {
                      formik.setFieldValue("role", selected.value);
                    }}
                    options={options}
                    defaultValue={{
                      value: "super_admin",
                      label: "Super Admin",
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                </div>
              </div>
            </div>

            <div className="auth-submit">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <i className="fa fa-refresh fa-spin"></i>Loading
                  </>
                ) : (
                  "Submit"
                )}
              </button>
              <NavLink to="/sign-in" className="auth-forgot-password float-end">
                Login
              </NavLink>
            </div>
          </form>
          <div className="divider" />
        </div>
      </div>
    </div>
  );
}
