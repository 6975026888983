import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext, useEffect } from "react";
import React from "react";
import { fetchDashboardPermission } from "../app/components/layout/sidebarSlice";
import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "../app/components/Loader/LoadingSpinner";

const RouteAccessControl = ({ dashboardKey }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchDashboardPermission());
    }, [])
    const data = useSelector((state) => state.sidebar.dashboardPermission);
    const isLoading = useSelector((state) => state.sidebar.data.isLoading);
    const error = useSelector((state) => state.sidebar.data.error);
    if (isLoading) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    let dashboard;
    if (localStorage.getItem("user_role") !== "super_admin") {
        dashboard = data?.data?.[0]?.dashboard_items.find(item => item.key
            === dashboardKey);
    }
    else {
        return <Outlet />;
    }
    return <>{dashboard?.pivot?.permission === 'none' ? <Navigate to="/permission-denied" /> : <Outlet /> }</>;
};
export default RouteAccessControl;
