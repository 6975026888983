import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
//import { fetchBrandList } from "../BrandAndModel/brandSlice";
import { fetchProductAccessoriesType } from "./filterSlice";
import { fetchBrandList } from "../BrandAndModel/brandSlice";

import Select from "react-select";
function AddFilter() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const optionsAccessories = [];
  const brandOptions = [];
  const brandsAccessory = useSelector((state) => state.filter.productAccessoriesType);
  if (brandsAccessory) {
    brandsAccessory.data?.forEach((element) => {
      optionsAccessories.push({ value: element.type, label: element.type });
    });
  }

  const brands = useSelector((state) => state.brand.brandsList);
  if (brands) {
    
    brands.data?.forEach((element) => {
      brandOptions.push({ value: element.id, label: element.name });
    });
  }

  
  useEffect(() => {
    dispatch(fetchProductAccessoriesType());
    dispatch(fetchBrandList());
  }, [dispatch]);

  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
    // return undefined;
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(30, "Name must not exceed 30 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
    type: Yup.string().required("Type is required"),
    //brand_id: Yup.string().required("Brand is required")"FACTTORYGEM" || "CUSTOMFACTTORYGEM" || "MOVEMENT" ||

    brand_id: Yup.mixed().when("type", {
      is: (val) => 
      {
        var finCon =  val?.toUpperCase().includes("ACCESSORIES") ||  val?.toUpperCase().includes("FACTTORYGEM") ||  val?.toUpperCase().includes("CUSTOMFACTTORYGEM") ||  val?.toUpperCase().includes("MOVEMENT") ? false : true;
        
       return finCon; // val?.toUpperCase() !== "ACCESSORIES"
       },
        // || val?.toUpperCase() !== "FACTTORYGEM" || val?.toUpperCase() !== "CUSTOMFACTTORYGEM" || val?.toUpperCase() !== "MOVEMENT",
      then: () =>
        Yup.string().required("Brand is required")
        
    }),


  });

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      brand_id: "",
    },
    validationSchema,
    validateOnChange: true,
    // validateOnBlur: false,
    onSubmit: async (data) => {
      setIsLoading(true);
      await axios
        .post(process.env.REACT_APP_API_KEY + "add-product-accessories", data, {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            type: "model",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Created Successfully!",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/filter-management")
                : navigate("/model-management/Modify");
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            swal("Oops", error.response.data.message, "error");
          }
          setIsLoading(false);
        });
    },
  });

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Add Filter</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
              <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Select Brand
                  </label>
                  <Select
                    name="brand_id"
                    onChange={(selected) => {
                      formik.setFieldValue("brand_id", selected.value);
                    }}
                    options={brandOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors.brand_id && formik.touched.brand_id
                      ? formik.errors.brand_id
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Select Type
                  </label>
                  <Select
                    name="type"
                    onChange={(selected) => {
                      formik.setFieldValue("type", selected.value);
                    }}
                    options={optionsAccessories}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors.type && formik.touched.type
                      ? formik.errors.type
                      : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <label htmlFor="inputTitle" className="form-label">
                    Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    autoComplete="off"
                    placeholder="Name"
                  />
                  <div className="text-danger">
                    {formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : null}
                  </div>
                </div>

                

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Saving...
                      </>
                    ) : (
                      "Save"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={"/filter-management"}
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddFilter;
