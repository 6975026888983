import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettingsData } from "./settingsSlice";
import LoadingSpinner from "../Loader/LoadingSpinner";
function GeneralSettings() {
  const allData = useSelector((state) => state.settings.settingList);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.settings?.adminData);
  const adminDataLoad = useSelector((state) => state.settings?.adminDataLoad);
  const options = [];
  
  
  if (allData) {
    allData.data?.forEach((element) => {
      options[element.option_key] = element.option_value;
    });
  }

  const validationSchema = Yup.object().shape({
    outlier: Yup.number()
      .required("Outlier is required")
      .positive("Must be more than 0")  
      .max(99999, "Outlier must not exceed 5 digits")
      .typeError("Outlier must be a number"),
    
    // email: Yup.string().required("Required*").email("Email is invalid"),
  });

  useEffect(() => {
    dispatch(fetchSettingsData());
  }, []);

  const formik = useFormik({
    initialValues: {
      outlier: options?.outlier,
    },
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    // validateOnBlur: false,
    onSubmit: async (data) => {
      console.log('DDDDD>>>>', data)
      setIsLoading(true);
      await axios
        .post(process.env.REACT_APP_API_KEY + "general-update", data, {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            swal({
              title: "Success!",
              text: res.data.message,
              icon: "success",
              button: "Okay",
            });

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/dashboard")
                : navigate("/dashboard");
            }
            setIsLoading(false);
          }
        })
        .catch((error) => {
          swal("Oops", error.response.data.message, "error");
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    console.log(formik);
  }, [formik]);

  if (adminDataLoad) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>General Setting</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
              
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                  Outlier
                  </label>
                  <div className="icon-input">
                    <input
                      name="outlier"
                      type="text"
                      className="form-control eye-icon"
                      onChange={(e) => {
                        formik.setFieldValue("outlier", e.target.value);
                      }}
                      value={formik.values.outlier}
                      
                      autoComplete="off"
                      placeholder="Outlier"
                    />
                  </div>

                  <div className="text-danger">
                    {formik.errors.outlier && formik.touched.outlier
                      ? formik.errors.outlier
                      : null}
                  </div>
                </div>
             
                
                
                

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Updating...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/dashboard"
                        : "/dashboard"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GeneralSettings;
