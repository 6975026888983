import React from "react";
import $ from "jquery";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect,useState } from "react";
import { Link, NavLink, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { fetchAutoFillsList } from "./watchIdSlice";
import { fetchUserManagementPermission } from "../UserManagement/userSlice";
import { AccessControlComponent } from "../../../routes/AccessControlOnRoutes";
import axios from "axios";
import swal from "sweetalert";
export default function BrandAndModelManagement() {
  let { action } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.autoFills.autoFillsList);
  const isLoading = useSelector((state) => state.autoFills.isLoading);
  const error = useSelector((state) => state.autoFills.error);
  const [permissionToModify, setPermissionToModify] = useState(true);
  const dashboardPermission = useSelector((state) => state.user.dashboardPermission.data);
  const isdashboardPermissionLoading = useSelector((state) => state.sidebar.data.isLoading);
  const dashboardPermissionError = useSelector((state) => state.sidebar.data.error);
 
  useEffect(() => {
    if (dashboardPermission && localStorage.getItem("user_role") !== "super_admin") {
      let permission = AccessControlComponent({ dashboardKey: process.env.REACT_APP_BRANDMANAGEMENT, data: dashboardPermission[0] });
      setPermissionToModify(permission);
    }
  }, [dashboardPermission]);
  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();
    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [
          {
            extend: "excelHtml5",
            title: "",
            text: "Export",
            filename: "Watch_ids_managements",
            className: "btn btn-info mt-2",
          },
        ],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
        order: [],
        //order:[[1, 'DESC']]
      });
    }, 1000);
  };
  useEffect(() => {
    dispatch(fetchAutoFillsList());
    initializeDataTable();
  }, [dispatch, location.key, dashboardPermission]);
  useEffect(() => {
    dispatch(fetchUserManagementPermission());
  }, []);

  if (isLoading || isdashboardPermissionLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } 
  if (error || dashboardPermissionError) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }
  const handleChange = async (id) => {
    await axios
      .get(process.env.REACT_APP_API_KEY + "update-status-watch-id/" + id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        swal({
          title: "Updated!",
          text: "Status Updated Successfully",
          icon: "success",
          button: "Okay",
        });
        initializeDataTable();
        dispatch(fetchAutoFillsList());
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function capitalizeFirstWord(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  

  const handleStatus = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to update this action.",
      icon: "warning",
      buttons: ["Cancel", "Submit"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleChange(id);
      }
    });
  };


  const handleDeleteChange = async (id) => {
    await axios
      .get(process.env.REACT_APP_API_KEY + "delete-watch-id/" + id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        swal({
          title: "Updated!",
          text: "Delete Successfully",
          icon: "success",
          button: "Okay",
        });
        initializeDataTable();
        dispatch(fetchAutoFillsList());
      })
      .catch(function (error) {
        console.log(error);
      });
  };



  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure want to delete?",
      icon: "warning",
      buttons: ["Cancel", "Submit"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteChange(id);
      }
    });
  };




  const renderUser = (
    <table id="myTable" className="table table-striped my-3">
      <thead className="table-dark">
        <tr>
          <th>Sr.No.</th>
          <th>Reference No.</th>
          <th>Brand</th>
          <th>Model</th>
          <th>Dial</th>
          <th>Bracelet</th>
          
          <th>Status</th>
         {permissionToModify &&( <th data-orderable="false">Action(s)</th>)}
        </tr>
      </thead>
      <tbody>
        {brands?.data?.map((brand, index) => (
          <React.Fragment key={brand.id}>
            {/* Duplicate rows */}

            <tr key={brand.id}>
              <td>{++index}</td>
              <td>{brand.watch_id}</td>
              <td>{brand.brand_name}</td>
              <td>{brand.model_name}</td>
              <td>{brand.dial_name}</td>
              <td>{brand.bracelet_acc_name}</td>
              
              
              <td>{capitalizeFirstWord(brand.status)}</td>
              {permissionToModify &&(<td>
                <Link
                  to={{ pathname: "/watch-id-management/edit-watch-id" }}
                  state={{ id: brand.id, action: action }}
                >
                  <span className="material-icons" title="Edit">
                    edit
                  </span>
                </Link>
                &nbsp;&nbsp;
                <span
                  style={
                    brand.status === "Active"
                      ? { color: "green", cursor: "pointer" }
                      : { color: "red", cursor: "pointer" }
                  }
                  className="material-icons-outlined"
                  onClick={() => {
                    handleStatus(brand.id);
                  }}
                  title={brand.status === "Active" ? "Active" : "Inactive"}
                >
                  {brand.status === "Active" ? "toggle_on" : "toggle_off"}
                </span>
                &nbsp;&nbsp;
                <span
                    style={{ color: "red", cursor: "pointer" }}
                    className="material-icons-outlined cursor-default"
                    onClick={() => {
                      handleDelete(brand.id);
                    }}
                    title="Delete"
                  >
                    delete
                  </span>
              </td>)}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Watch Id Management</h1>
          </div>
        </div>
      </div>
     {permissionToModify && ( <>
        <NavLink className="btn btn-info" to="/watch-id-management/add-watch-id">
          Add Watch Id
        </NavLink>
      </>)}
      {isLoading ? <LoadingSpinner /> : renderUser}
    </div>
  );
}
