import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchStaffManagement = createAsyncThunk(
  "staffManagement/fetchData",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "staff-list",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("response==========", response.data);
      const data = response.data?.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchIndividualStaff = createAsyncThunk(
  "individualStaff/fetchData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + `get-staff/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("response==========", response.data);
      const data = response.data?.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchDashBoardItems = createAsyncThunk(
  "dashboardItems/fetchData",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "dashboard-items",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("response==========", response.data);
      const data = response.data?.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const staffManagementSlice = createSlice({
  name: "staffManagement",
  initialState: {
    staffManagement: [],
    dashboardItems: [],
    isLoading: false,
    error: null,
    staff: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStaffManagement.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchStaffManagement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.staffManagement = action.payload;
      })
      .addCase(fetchStaffManagement.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDashBoardItems.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDashBoardItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dashboardItems = action.payload;
      })
      .addCase(fetchDashBoardItems.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchIndividualStaff.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchIndividualStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.staff = action.payload;
      })
      .addCase(fetchIndividualStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { staffManagementData } = staffManagementSlice.actions;
export default staffManagementSlice.reducer;
