import { React, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { SideBarAccessControl } from "../../../routes/AccessControlOnRoutes";
import axios from "axios";

export default function SideBar({ data, permissions }) {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };
  const parentMenuItems = data?.filter((item) => item.parentMenuId == null);
  const navigate = useNavigate();
  const Logout = async () => {
    await axios
      .post(
        process.env.REACT_APP_API_KEY + "logout",
        {
          user_id: localStorage.getItem("userID"), 
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        localStorage.clear();
        navigate("/sign-in");
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          navigate("/sign-in");
        }
        console.error("Logout error:", error);
      });
  };

  function SidebarMenuItem({ item, data }) {
    const subMenuItems = data?.filter(
      (subItem) => subItem.parentMenuId == item.id
    );
    return (
      <li key={item.id}>
        <NavLink to={item.route} className={item.route}>
          <i className="material-icons-two-tone">{item.icon}</i>
          {item.name}
        </NavLink>
        {subMenuItems?.length > 0 && (
          <i
            className={`material-icons has-sub-menu ${
              isSubMenuOpen ? "up-arrow" : "down-arrow"
            }`}
            onClick={handleSubMenuToggle}
          >
            {isSubMenuOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          </i>
        )}
        {isSubMenuOpen && subMenuItems.length > 0 && (
          <ul className="sub-menu">
            {subMenuItems?.map((subItem) => (
              <SidebarMenuItem key={subItem.id} item={subItem} data={data} />
            ))}
          </ul>
        )}
      </li>
    );
  }

  return (
    <div className="app align-content-stretch d-flex flex-wrap">
      <div className="app-sidebar">
        <div className="logo">
          <NavLink to="/dashboard" className="logo-icon">
            {/* <span className="logo-text">CPS</span> */}
          </NavLink>
        </div>
        <div className="app-menu">
          <ul>
            {parentMenuItems?.map((item) => {
              let access = true;
              if (permissions) {
                access = SideBarAccessControl({
                  dashboardKey: item.key,
                  data: permissions[0],
                });
                console.log("Permission", access);
              }
              if (!access) {
                return (
                  <SidebarMenuItem key={item.id} item={item} data={data} />
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      </div>
      <div className="app-container">
        <div className="app-header">
          <nav className="navbar navbar-light navbar-expand-lg">
            <div className="container-fluid">
              <div className="navbar-nav" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link hide-sidebar-toggle-button" href="#">
                      <i className="material-icons">first_page</i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="d-flex">
                <ul className="navbar-nav">
                  <li className="nav-item hidden-on-mobile">
                    <a
                      className="nav-link language-dropdown-toggle"
                      href="#"
                      id="languageDropDown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i
                        className="material-icons-two-tone"
                        title="Profile"
                        style={{ fontSize: "34px" }}
                      >
                        manage_accounts
                      </i>
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-end language-dropdown false"
                      aria-labelledby="languageDropDown"
                      data-bs-popper="none"
                    >
                      <li>
                        <NavLink to="/update-profile" className="dropdown-item">
                          Profile
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/change-password"
                          className="dropdown-item"
                        >
                          Change Password
                        </NavLink>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#" onClick={Logout}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
