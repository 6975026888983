import React from "react";
import $ from "jquery";
import swal from "sweetalert";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { fetchUserList } from "./userSlice";
import { fetchUserManagementPermission } from "./userSlice";
// import { fetchDashboardPermission } from "../layout/sidebarSlice";
import moment from "moment";
import { AccessControlComponent } from "../../../routes/AccessControlOnRoutes";
import dollar from "../../../assests/images/dollar.png";
import active from "../../../assests/images/Check_fill.png";
import inactive from "../../../assests/images/Done_ring_round.png";
import premium_user from "../../../assests/images/User_circle.png";
import normal_user from "../../../assests/images/User_cicrle2.png";
import seller from "../../../assests/images/Seller.png";
import price_show from "../../../assests/images/Money_fill.png";
import price_hide from "../../../assests/images/Money.png";
import rating from "../../../assests/images/Star.png";

export default function UserManagement() {
  const location = useLocation();
  let { action } = useParams();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.user.userList);
  const isLoading = useSelector((state) => state.user.isLoading);
  const error = useSelector((state) => state.user.error);
  const dashboardPermission = useSelector(
    (state) => state.user.dashboardPermission.data
  );
  let permissionToModify = true;
  if (
    dashboardPermission &&
    localStorage.getItem("user_role") !== "super_admin"
  ) {
    permissionToModify = AccessControlComponent({
      dashboardKey: process.env.REACT_APP_USERMANAGEMENT,
      data: dashboardPermission[0],
    });
  }
  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();
    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [
          {
            extend: "excelHtml5",
            title: "",
            text: "Export",
            filename: "User_managements",
            className: "btn btn-info mt-2",
            exportOptions: {
              columns: ":not(.no-export)", // Exclude columns with the "no-export" class
            },
          },
        ],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
      });
    }, 1200);
  };

  useEffect(() => {
    dispatch(fetchUserList());
    initializeDataTable();
    document.body.className = "pace-done no-loader users";
    if ($("body").hasClass("users")) {
      $(".dt-buttons").addClass("csv-button");
    }
    return () => {
      document.body.className = "pace-done no-loader";
    };
  }, [dispatch, location.key, dashboardPermission]);

  useEffect(() => {
    dispatch(fetchUserManagementPermission());
  }, []);

  const addCoinsToUser = async (id, coins) => {
    await axios
      .post(
        process.env.REACT_APP_API_KEY + "admin-add-coins/" + id,
        {
          coins: coins, // Corrected: Send coins directly without the 'data' wrapper
        },
        {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        initializeDataTable();
        swal({
          title: "Success!",
          text: "Coins Added Successfully",
          icon: "success",
          button: "Okay",
        });
        dispatch(fetchUserList());
      })
      .catch((error) => {
        swal("Oops", error.response.data.message, "error");
      });
  };
  const handleShowAlert = (id) => {
    swal({
      title: "Add Coins ",
      // text: "Created Successfully!",
      icon: "success",
      content: {
        element: "input", // Use an input field
        attributes: {
          placeholder: "Enter a number",
          type: "number",
          min: 1, // Minimum allowed value
          step: 1, // Step size for incrementing/decrementing
        },
      },
      buttons: {
        confirm: {
          text: "Submit",
          value: true,
          closeModal: true,
        },
        cancel: "Cancel",
      },
    }).then((value) => {
      if (value) {
        addCoinsToUser(id, value);
      }
    });
  };
  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
  if (error) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }

  const handleChange = (id, type) => {
    swal({
      title: "Are you sure?",
      text: "You want to update this action.",
      icon: "warning",
      buttons: ["Cancel", "Submit"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleChangeSubmit(id, type);
      }
    });
  };

  const handleChangeSubmit = async (id, type) => {
    var data = {
      actionType: type,
    };
    await axios
      .post(process.env.REACT_APP_API_KEY + "user-update-action/" + id, data, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        swal({
          title: "Updated!",
          text: "Data updated successfully.",
          icon: "success",
          button: "Okay",
        });
        initializeDataTable();
        dispatch(fetchUserList());
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRole = async (id) => {
    await axios
      .get(process.env.REACT_APP_API_KEY + "update-role/" + id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        swal({
          title: "Updated!",
          text: "Role Updated Successfully",
          icon: "success",
          button: "Okay",
        });
        initializeDataTable();
        dispatch(fetchUserList());
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function capitalizeFirstWord(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function capitalizeFirstWord(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderUser = (
    <table id="myTable" className="table table-striped my-3">
      <thead className="table-dark">
        <tr>
          <th scope="col">Sr.No.</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Registered On</th>
          <th scope="col">Status</th>
          <th scope="col">Role</th>
          <th scope="col">Premium User</th>
          <th scope="col">Coins</th>
          {permissionToModify && (
            <>
              <th scope="col" className="no-export" data-orderable="false">
                Action(s)
              </th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {users?.data?.map((item, index) => (
          <React.Fragment key={item.id}>
            <tr>
              <td>{++index}</td>
              <td>{item.name}</td>
              <td>{item.email}</td>

              <td data-sort={moment(item.created_at).format('YYYY.MM.DD HH.mm.ss')}>{moment(item.created_at).format(process.env.REACT_APP_DATE_FORMAT)}</td>
              <td>{capitalizeFirstWord(item.status)}</td>
              <td>{capitalizeFirstWord(item.role)}</td>
              <td>{capitalizeFirstWord(item.premium_user)}</td>
              <td>{item.blance == null ? 0 : item.blance}</td>
              {permissionToModify && (
                <td>
                  <span
                    className="material-icons-outlined"
                    style={
                      item.status === "active"
                        ? { color: "green", cursor: "pointer" }
                        : { color: "red", cursor: "pointer" }
                    }
                    title={item.status === "active" ? "Active" : "Inactive"}
                    onClick={() => {
                      handleChange(item.id, "status");
                    }}
                  >
                    {item.status === "active" ? "toggle_on" : "toggle_off"}
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <span
                    style={{ cursor: "pointer" }}
                    title={item.role === "seller" ? "Seller" : item.role}
                  >
                    {item.role === "seller" ? (
                      <img
                        src={seller}
                        alt="active_icon"
                        className="mb-2"
                        onClick={() => {
                          //handleRole(item.id);
                          handleChange(item.id, "role");
                        }}
                      ></img>
                    ) : (
                      <img
                        src={normal_user}
                        alt="active_icon"
                        className="mb-2"
                        onClick={() => {
                          //handleRole(item.id);
                          handleChange(item.id, "role");
                        }}
                      ></img>
                    )}
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <span
                    style={{ cursor: "pointer" }}
                    title={
                      item.premium_user === "yes"
                        ? "Premium User"
                        : "Normal User"
                    }
                  >
                    {item.premium_user === "yes" ? (
                      <img
                        src={premium_user}
                        alt="premium_user"
                        className="mb-2"
                        onClick={() => {
                          handleChange(item.id, "premium_user");
                        }}
                      ></img>
                    ) : (
                      <img
                        src={normal_user}
                        alt="normal_user"
                        className="mb-2"
                        onClick={() => {
                          handleChange(item.id, "premium_user");
                        }}
                      ></img>
                    )}
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  {item.role === "seller" ? (
                    <span
                      style={{ cursor: "pointer" }}
                      title={
                        item.price_chart === "yes"
                          ? "Price will show in chart"
                          : "Price will not show in chart"
                      }
                    >
                      {item.price_chart === "yes" ? (
                        <img
                          src={price_show}
                          alt="price_show"
                          className="mb-2"
                          onClick={() => {
                            handleChange(item.id, "price_chart");
                          }}
                        ></img>
                      ) : (
                        <img
                          src={price_hide}
                          alt="price_hide"
                          className="mb-2"
                          onClick={() => {
                            handleChange(item.id, "price_chart");
                          }}
                        ></img>
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                  &nbsp;&nbsp;&nbsp;
                  {item.role === "seller" ? (
                    <Link
                      to={{
                        pathname: "/user-management/user-rating/" + item.id,
                      }}
                      // state={{ UserId: user.id }}
                    >
                      <span
                        className="material-icons"
                        title="User Rating"
                        style={{ cursor: "pointer" }}
                      >
                        <img src={rating} alt="rating" className="mb-2"></img>
                      </span>
                    </Link>
                  ) : (
                    ""
                  )}
                  &nbsp;&nbsp;&nbsp;
                  {item.role === "seller" ? (
                    <span
                      className="material-icons"
                      title="User Coins"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={dollar}
                        alt="coins"
                        className="mb-2"
                        onClick={(e) => {
                          handleShowAlert(item.id);
                        }}
                      ></img>
                    </span>
                  ) : (
                    ""
                  )}
                </td>
              )}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>User Management</h1>
          </div>
        </div>
      </div>
      <></>
      {isLoading ? <LoadingSpinner /> : renderUser}
    </div>
  );
}
