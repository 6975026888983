import React from "react";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  PointElement,
  LinearScale,
  Legend,
  Tooltip,
} from "chart.js";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

const PriceLineChart = () => {
  const [filter, setFilter] = useState("last7days");
  const [chartData, setChartData] = useState([]);
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    getPriceChartData();
  }, [filter]);

  //products-chart?product_id=4&duration=
  // lastdays => 30, lastmonths = 6, lastyear = 1
  const getPriceChartData = async () => {
    await axios({
      url: `${process.env.REACT_APP_API_KEY}products-chart?product_id=${state.id}&duration=${filter}`,
      method: "get",
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        console.log("response from PriceChart==>", response.data.data);
        setChartData(response.data.data);
      })
      .catch((error) => {
        console.log("error from Price Chart==>", error);
      });
  };

  const filterData = () => {
    const filteredData = {
      labels: [],
      datasets: [
        {
          label: "Sample Line Chart",
          data: [],
          fill: false,
          borderColor: "rgb(75, 192, 192)",
        },
      ],
    };
    console.log("filter", filter);

    // Apply filtering based on selected filter
    switch (filter) {
      case "last7days":
        filteredData.labels = chartData?.chart?.label;
        filteredData.datasets[0].data = chartData?.chart?.value;
        break;
      case "lastdays":
        filteredData.labels = chartData?.chart?.label;
        filteredData.datasets[0].data = chartData?.chart?.value;
        break;
      case "lastmonths":
        filteredData.labels = chartData?.chart?.label;
        filteredData.datasets[0].data = chartData?.chart?.value;
        break;
      case "lastyear":
        filteredData.labels = chartData?.chart?.label;
        filteredData.datasets[0].data = chartData?.chart?.value;
        break;
      default:
        break;
    }

    return filteredData;
  };

  const filteredData = filterData();

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return "Price: $" + context.parsed.y;
          },
        },
      },
      legend: true,
    },
    elements: {
      line: {
        borderWidth: 3,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.4,
        fill: true, // Set fill to true to fill the area below the line chart
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Background color for the area below the line chart
        shadowOffsetX: 0,
        shadowOffsetY: 4,
        shadowBlur: 6,
        shadowColor: "rgba(0, 0, 0, 0.3)",
      },
      // Set the background color of the covered area (area below the line chart)
      coveredArea: {
        backgroundColor: "rgba(255, 0, 0, 0.2)", // Replace this with the desired color
      },
    },
  };

  const chartContainerStyle = {
    height: "400px",
    width: "600px",
  };

  return (
    <div style={chartContainerStyle}>
      <select value={filter} onChange={handleFilterChange}>
        <option value="last7days">7 Days</option>
        <option value="lastdays">30 Days</option>
        <option value="lastmonths">Lass 6 Months</option>
        <option value="lastyear">Year</option>
      </select>
      <Line data={filteredData} options={options} />
    </div>
  );
};

export default PriceLineChart;
