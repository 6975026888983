import React from "react";
import { useRef, useMemo, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import { Field } from "formik";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as Yup from "yup";
import img from "../../../logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrandList, fetchModelList } from "../BrandAndModel/brandSlice";
import CreatableSelect from "react-select/creatable";
import LoadingSpinner from "../Loader/LoadingSpinner";

// import { set } from "immer/dist/internal";

function ProductAdd() {
  const [allDropDown, setAllDropDown] = useState([]);
  const [modelId, setModelId] = useState();
  const [brandName, setBrandName] = useState();
  const [newBrand, setNewBrand] = useState();
  const [newModel, setNewModel] = useState(false);
  const [newCreatedModel, setNewCreatedModel] = useState(false);
  const [date, setDate] = useState();
  const [notCertain, setNotCertain] = useState(false);
  const [factoryGem, setFactoryGem] = useState(false);
  const [customGemSet, setCustomGemSet] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesPrimary, setImagesPrimary] = useState();
  const [imageSecondaryTarget, setImageSecondaryTarget] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const videoRef = useRef(null);
  const videoSecondaryRef = useRef(null);
  const canvasRef = useRef(null);
  const canvasSecondaryRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [factoryDial, setFactoryDial] = useState("");
  const [factoryBracelet, setFactoryBracelet] = useState("");
  const [factoryClasp, setFactoryClasp] = useState("");
  const [factoryLugs, setFactoryLugs] = useState("");
  const [factoryBezel, setFactoryBezel] = useState("");

  const [customDial, setCustomDial] = useState("");
  const [customBracelet, setCustomBracelet] = useState("");
  const [customClasp, setCustomClasp] = useState("");
  const [customLugs, setCustomLugs] = useState("");
  const [customBezel, setCustomBezel] = useState("");
  const [customCase, setCustomCase] = useState("");

  const accessories = []; //accessories
  const dialOption = []; //DIAL
  const dialMarOption = []; //DIALMARKERS
  const caseSizeOption = []; //CASESIZE
  const movementOption = []; //MOVEMENT
  const caseMaterialOption = []; //CASEMATERIAL
  const strapOption = []; //STRAPBRACELET
  const claspOption = []; //CLASP

  const options = [];
  const modelOptions = [];

  useEffect(() => {
    dispatch(fetchBrandList());
    if (modelId) {
      dispatch(fetchModelList(modelId));
      getAllDropDownList(modelId);
    }
    
  }, [dispatch, modelId]);

  const brands = useSelector((state) => state.brand.brandsList);
  const dataLoading = useSelector((state) => state.brand.isLoading);
  if (brands) {
    brands.data?.forEach((element) => {
      // console.log(element);
      options.push({ value: element.id, label: element.name });
    });
    // let length = options.length - 1;
    // let lastElement = options[length];
    // console.log('lastElement', lastElement.value + 1);
    // options.push({ value: lastElement?.value + 1, label: "Other" });
  }
  const models = useSelector((state) => state.brand.modelList);
  if (models) {
    models.data?.forEach((element) => {
      modelOptions.push({ value: element.id, label: element.name });
    });
  }

  const optionss = {
    fields: ["address_components", "geometry", "name"],
    types: ["establishment"],
  };
  useEffect(() => {
    setTimeout(() => {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        optionss
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        formik.setFieldValue("location", place.name);
        formik.setFieldValue("latitude", place?.geometry?.location?.lat());
        formik.setFieldValue("longitude", place?.geometry?.location?.lng());
      });
    }, 2000);
  }, [allDropDown]);

  const getAllDropDownList = async (modelId) => {
    await axios({
      url: process.env.REACT_APP_API_KEY + "get-product-dropdown/"+modelId,
      method: "get",
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        console.log("response==>", response.data.data);
        setAllDropDown(response.data.data);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
  };

  if (allDropDown) {
    allDropDown.ACCESSORIES?.forEach((element) => {
      accessories.push({ value: element.id, label: element.name });
    });
    allDropDown.DIAL?.forEach((element) => {
      dialOption.push({ value: element.id, label: element.name });
    });
    allDropDown.DIALMARKERS?.forEach((element) => {
      dialMarOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CASESIZE?.forEach((element) => {
      caseSizeOption.push({ value: element.id, label: element.name });
    });
    allDropDown.MOVEMENT?.forEach((element) => {
      movementOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CASEMATERIAL?.forEach((element) => {
      caseMaterialOption.push({ value: element.id, label: element.name });
    });
    allDropDown.STRAPBRACELET?.forEach((element) => {
      strapOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CLASP?.forEach((element) => {
      claspOption.push({ value: element.id, label: element.name });
    });
  }

  const Images = ({ images, setImages }) => {
    return (
      <div className="img_collection">
        {images.map((el, i) => {
          if (el[0].type === "video") {
            return (
              <>
                <div key={i} className="collection_gallery">
                  {el[0].thumbnail && (
                    <img src={el[0].thumbnail} alt="Video Thumbnail" />
                  )}
                  <span
                    onClick={(event) => {
                      setImages(
                        images.filter((el, index) => {
                          return index !== i;
                        })
                      );
                      setImageSecondaryTarget(
                        imageSecondaryTarget.filter((el, index) => {
                          return index !== i;
                        })
                      );
                    }}
                    className="kill-btn"
                  >
                    X
                  </span>
                </div>
              </>
            );
          }
          return (
            <div key={i} className="collection_gallery">
              <img src={el[0].url} alt="" />
              <span
                onClick={(event) => {
                  setImages(
                    images.filter((el, index) => {
                      return index !== i;
                    })
                  );
                  setImageSecondaryTarget(
                    imageSecondaryTarget.filter((el, index) => {
                      return index !== i;
                    })
                  );
                }}
                className="kill-btn"
              >
                X
              </span>
            </div>
          );
        })}
        <div className="upload-btn-wrapper">
          <button className="btn">+</button>
          <input
            type="file"
            onChange={(event) => {
              const selectedImages = [];
              const targetImages = event.target.files;
              const targetImagesObject = [...targetImages];
              setImageSecondaryTarget([
                ...imageSecondaryTarget,
                targetImagesObject,
              ]);

              targetImagesObject.map((file, i) => {
                if (file.type === "video/mp4" || file.type === "video/ogg") {
                  // console.log(images.length);
                  const video = videoSecondaryRef.current;
                  const canvas = canvasSecondaryRef.current;

                  video.src = URL.createObjectURL(file);
                  video.onloadedmetadata = () => {
                    video.currentTime = 5; // Capture the frame at 5 seconds
                    video.onseeked = () =>
                      generateSecondaryThumbnail(canvas, images.length);
                  };
                  // const thumb = generateSecondaryThumbnail(file,images.length);
                  // console.log("thumb",thumb)
                  return selectedImages.push({
                    url: URL.createObjectURL(file),
                    type: "video",
                  });
                }
                return selectedImages.push({
                  index: i,
                  url: URL.createObjectURL(file),
                  type: "image",
                });
              });
              setImages([...images, selectedImages]);
            }}
            name="myfile"
          />
        </div>
      </div>
    );
  };

  const generateThumbnail = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const thumbnailDataUrl = canvas.toDataURL();
    setThumbnailUrl(thumbnailDataUrl);
  };
  const generateSecondaryThumbnail = (canvas, i) => {
    const video = videoSecondaryRef.current;
    const context = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const thumbnailDataUrl = canvas.toDataURL();
    // return thumbnailDataUrl;
    setImages((oldArr) => {
      const newArr = [...oldArr];
      // console.log('222', i,oldArr)
      newArr[i][0]["thumbnail"] = thumbnailDataUrl;
      return newArr;
    });
  };

  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
    // return undefined;
  };

  const validationSchema = Yup.object().shape({
    brand_id: Yup.string().required("Brand is required"),
    model_id: Yup.string().required("Model is required"),
    dated: Yup.string().required("Dated is required"),
    title: Yup.string()
      .required("Title is required")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(30, "Name must not exceed 30 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
    watch_condition: Yup.string().required("Watch Condition is required"),
    /*
    dated: Yup.string().when("notCertain", {
      is: true,
      then: Yup.string().required("Date is required"),
    }),
    */
    price: Yup.number()
      .required("Price is required")
      .typeError("Price must be a number")
      .test("is-decimal", "Field must be a valid number", (value) => {
        if (value === undefined || value === null) {
          return true;
        }
        return /^-?\d*\.?\d+$/.test(value);
      })
      .test("is-positive", "Price must be greater than 0", (value) => {
        return value > 0;
      })
      .test(
        "total-digits",
        "Price must have at most 7 digits with 2 decimal places",
        (value) => {
          if (value === undefined || value === null) {
            return true;
          }
          const stringValue = value.toString();
          const [, decimalPart] = stringValue.split(".");

          if (decimalPart) {
            return stringValue.replace(".", "").length <= 7; // 7 digits for the integer part, 2 for decimal point
          }
          return stringValue.length <= 5; //5 digits allowed if there is no decimal part
        }
      ),
    // dial: Yup.string().required("Dial is required"),
    dial: Yup.mixed().when("brand_id", {
      is: (val) =>
        brandName?.toLowerCase() ==
        ("rolex" || "audemars piguet" || "patek philippe"),
      then: () =>
        Yup.string().test({
          test: function (value, ctx) {
            if (!value) {
              return ctx.createError({ message: "Please select a dial value" });
            }
            return true;
          },
          // message: 'At least one of the values from case, dial, bracelet, bezel, lugs, clasp is required and must be of a valid format',
        }),
    }),
    bracelet: Yup.mixed().when("brand_id", {
      is: (val) =>
        brandName?.toLowerCase() ==
        ("rolex" || "audemars piguet" || "Patek Philippe"),
      then: () =>
        Yup.string().test({
          test: function (value, ctx) {
            if (!value) {
              return ctx.createError({
                message: "Please select a bracelet value",
              });
            }
            return true;
          },
        }),
    }),
    location: Yup.string().required("Location is required."),
    gender_type: Yup.string().required("Gender is required"),
    accessories: Yup.string().required("Accessories is required"),
    description: Yup.string().test(
      "tags",
      "Invalid Input, No Tags allowed",
      (value, ctx) => {
        const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
        if (regex.test(value)) {
          return false;
        } else {
          return true;
        }
      }
    ),
    factory_gem_set: Yup.string().required(
      "Factory Checkbox needs to be selected"
    ),
    factory_gem: Yup.mixed().when("factory_gem_set", {
      is: (val) => val?.toLowerCase() === "yes",
      then: () =>
        Yup.object().test({
          test: function (value, ctx) {
            const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
            const { dial, bracelet, bezel, lugs, clasp } = value;
            console.log("test dial", regex.test(dial));

            if (
              regex.test(dial) ||
              regex.test(bracelet) ||
              regex.test(bezel) ||
              regex.test(lugs) ||
              regex.test(clasp)
            ) {
              return ctx.createError({
                message: "Invalid Format, No  Tags allowed",
              });
            }
            if (
              !(
                dial !== undefined ||
                bracelet !== undefined ||
                bezel !== undefined ||
                lugs !== undefined ||
                clasp !== undefined
              )
            ) {
              return ctx.createError({
                message:
                  "'At least one of the values from dial, bracelet, bezel, lugs, clasp is required",
              });
            }
            return true;
          },
          // message: 'At least one of the values from case, dial, bracelet, bezel, lugs, clasp is required and must be of a valid format',
        }),
    }),
    custom_gem_set: Yup.string().required(
      "Custom CheckBox needs to be selected"
    ),
    custom_gem: Yup.object().when("custom_gem_set", {
      is: (val) => val?.toLowerCase() === "yes",
      then: () =>
        Yup.object().test({
          test: function (value, ctx) {
            const { dial, bracelet, bezel, lugs, clasp } = value;
            console.log("value", value);
            const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
            if (
              regex.test(value?.case) ||
              regex.test(dial) ||
              regex.test(bracelet) ||
              regex.test(bezel) ||
              regex.test(lugs) ||
              regex.test(clasp)
            ) {
              return ctx.createError({
                message: "Invalid Format, No  Tags allowed",
              });
            }
            if (
              !(
                value?.case !== undefined ||
                dial !== undefined ||
                bracelet !== undefined ||
                bezel !== undefined ||
                lugs !== undefined ||
                clasp !== undefined
              )
            ) {
              return ctx.createError({
                message:
                  "'At least one of the values from dial, bracelet, bezel, lugs, clasp is required",
              });
            }
            return true;
          },
        }),
    }),
    thumb_image: Yup.mixed()
      .required("File is required")
      .test("fileType", "Only Video, and Image files are allowed", (value) => {
        if (!value) return true; // Allow empty value
        const supportedFormats = [
          // "video/mp4",
          // "video/ogg",
          // "video/avi",
          "image/jpeg",
          "image/png",
          "image/jpg",
        ];
        return supportedFormats.includes(value.type);
      })
      .test("fileSize", "Maximum file size exceeded", (value) => {
        if (!value) return true; // Allow empty value
        const maxSize = value.type.includes("video")
          ? 5 * 1024 * 1024
          : 2 * 1024 * 1024;
        return value.size <= maxSize;
      }),
  });

  const userId = localStorage.getItem("userID");
  const userRole = localStorage.getItem("user_role");

  const formik = useFormik({
    initialValues: {
      title: "",
      user_id: userId,
      newBrand: false,
      newModel: false,
      brand_id: "",
      model_id: "",
      price: "",
      watch_condition: "",
      dated: "",
      no_certain: false,
      accessories: "",
      description: "",
      gender_type: "",
      dial: "",
      dial_markers: "",
      case_size: "",
      movement: "",
      case_materials: "",
      bracelet: "",
      clasp: "",
      factory_gem_set: "",
      factory_gem: {
        dial: "",
        bracelet: "",
        bezel: "",
        lugs: "",
        clasp: "",
      },
      custom_gem: {
        case: "",
        dial: "",
        bracelet: "",
        bezel: "",
        lugs: "",
        clasp: "",
      },
      custom_gem_set: "",
      location: "",
      longitude: "",
      latitude: "",
      thumb_image: "",
      product_file: "",
      user_role: userRole,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (data) => {
      // console.log("data>>DDD", data);
      // return;
      if (data.product_file.length > 4) {
        swal(
          "Oops",
          "Selected secondary images/videos count should be less than 5 ",
          "error"
        );
        return;
      }
      setIsLoading(true);
      let newBrandStatus;
      if (brands) {
        brands.data.find((el, i) => el.id === data.brand_id)
          ? (newBrandStatus = false)
          : (newBrandStatus = true);
        if (newBrandStatus) {
          console.log("newBrand");
          data.newBrand = true;
          data.newModel = true;
        } else {
          data.newBrand = false;
          data.newBrand = false;
        }
      }

      let newModelStatus;
      if (models.data.length > 0) {
        console.log("models 635", models.data);
        models.data.find((el, i) => el.id === data.model_id)
          ? (newModelStatus = false)
          : (newModelStatus = true);
        if (newModelStatus) {
          data.newModel = true;
        } else {
          data.newModel = false;
        }
      }
      // console.log("Old Option",data);
      await axios
        .post(process.env.REACT_APP_API_KEY + "add-product", data, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            type: "brand",
          },
        })
        .then((res) => {
          if (res?.status === 200) {
            swal({
              title: "Success!",
              text: "Created Successfully!",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/product-management")
                : navigate("/product-management");
            }
          }
        })
        .catch((error) => {
          swal("Oops", error.response.data.message, "error");
          setIsLoading(false);
        });
    },
  });

  if (dataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Add Product</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Choose Brand
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <CreatableSelect
                    name="brand_id"
                    isClearable
                    isDisabled={isSelectLoading}
                    isLoading={isSelectLoading}
                    onChange={(selected) => {
                      console.log(selected, "==================>>>");
                      formik.setFieldValue("brand_id", selected?.value);
                      setNewBrand(selected);
                      setModelId(selected?.value);
                      setBrandName(selected?.label);
                      setNewCreatedModel(null);
                    }}
                    onCreateOption={(inputValue) => {
                      setIsSelectLoading(true);
                      setTimeout(() => {
                        const newOption = {
                          value: inputValue,
                          label: inputValue,
                        };
                        setIsSelectLoading(false);
                        // setOptions((prev) => [...prev, newOption]);
                        options.push(newOption);
                        console.log("options", options);
                        setNewBrand(newOption);
                        setNewCreatedModel(null);

                        formik.setFieldValue("brand_id", newOption.value);
                      }, 1000);

                      // modelOptions.length = 0;
                    }}
                    options={options}
                    value={newBrand}
                  />
                  <div className="text-danger">
                    {formik.errors.brand_id && formik.touched.brand_id
                      ? formik.errors.brand_id
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="modelId" className="form-label">
                    Model <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <CreatableSelect
                    name="model_id"
                    isClearable
                    isDisabled={isSelectLoading}
                    isLoading={isSelectLoading}
                    onChange={(selected) => {
                      formik.setFieldValue("model_id", selected?.value);
                      setNewCreatedModel(selected);
                    }}
                    onCreateOption={(inputValue) => {
                      setIsSelectLoading(true);
                      setTimeout(() => {
                        const newOption = {
                          value: inputValue,
                          label: inputValue,
                        };
                        setIsSelectLoading(false);
                        options.push(newOption);
                        console.log("options", options);
                        setNewCreatedModel(newOption);
                        formik.setFieldValue("model_id", newOption.value);
                      }, 1000);
                    }}
                    options={modelOptions}
                    value={newCreatedModel}
                  />
                  <div className="text-danger">
                    {formik.errors.model_id && formik.touched.model_id
                      ? formik.errors.model_id
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="title" className="form-label">
                    Title <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <input
                    name="title"
                    id="title"
                    type="text"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    autoComplete="off"
                    placeholder="Enter product title"
                  />
                  <div className="text-danger">
                    {formik.errors.title && formik.touched.title
                      ? formik.errors.title
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="title" className="form-label">
                    Accessories{" "}
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <Select
                    name="accessories"
                    onChange={(selected) => {
                      formik.setFieldValue("accessories", selected.value);
                    }}
                    options={accessories}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors.accessories && formik.touched.accessories
                      ? formik.errors.accessories
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="price" className="form-label">
                    Price <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <input
                    name="price"
                    id="price"
                    type="text"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    autoComplete="off"
                    placeholder="Enter product price"
                  />
                  <div className="text-danger">
                    {formik.errors.price && formik.touched.price
                      ? formik.errors.price
                      : null}
                  </div>
                </div>
                {/* Watch Condition */}
                <div className="col-md-12">
                  <label className="form-label">
                    Watch Condition{" "}
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <div className="department">
                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="watchCondition"
                        value="brand_new"
                        id="flexRadioDefault1"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "watch_condition",
                            event.target.value
                          );
                        }}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Brand New
                      </label>
                    </div>

                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="watchCondition"
                        value="pre_owned"
                        id="flexRadioDefault2"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "watch_condition",
                            event.target.value
                          );
                        }}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Pre-Owned
                      </label>
                    </div>
                  </div>
                  <div className="text-danger">
                    {formik.errors.watch_condition &&
                    formik.touched.watch_condition
                      ? formik.errors.watch_condition
                      : null}
                  </div>
                </div>

                {/* */}
                <div className="col-md-4 no_certain">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={notCertain}
                      onChange={() => {
                        let holder = notCertain;
                        setNotCertain(!notCertain);
                        formik.setFieldValue("no_certain", !holder);
                      }}
                      id="notCertain"
                    />
                    <label class="form-check-label bg-unset1" for="notCertain">
                      Not Certain
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <label htmlFor="dated" className="form-label">
                    Dated
                  </label>
                  <div className="datepicker-icon">
                    <DatePicker
                      showIcon
                      name="dated"
                      selected={date}
                      onChange={(date) => {
                        setDate(date);
                        formik.setFieldValue(
                          "dated",
                          moment(date).format("yyyy-MM-DD")
                        );
                      }}
                      maxDate={new Date()}
                      className="form-control"
                      autoComplete="off"
                      placeholderText="Dated"
                      dateFormat="yyyy-MM-dd"
                      // disabled={notCertain}
                    />
                    <span className="new-date-icon">
                      <i class="material-icons-two-tone">calendar_today</i>
                    </span>
                  </div>
                  <div className="text-danger">
                    {formik.errors.dated && formik.touched.dated
                      ? formik.errors.dated
                      : null}
                  </div>
                </div>

                <div className="col-md-12">
                  <label htmlFor="description" className="form-label">
                    Tell the customers about this watch
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    onChange={(event) => {
                      formik.setFieldValue("description", event.target.value);
                    }}
                    className="form-control"
                  ></textarea>
                  <div className="text-danger">
                    {formik.errors.description && formik.touched.description
                      ? formik.errors.description
                      : null}
                  </div>
                </div>

                <div className="col-md-12">
                  <label htmlFor="inputTitle" className="form-label">
                    Gender <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <div className="department">
                    <div className="form-check dis">
                      <input
                        className="form-check-input brand_collection"
                        type="radio"
                        name="gender"
                        value="male"
                        id="male"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "gender_type",
                            event.target.value
                          );
                        }}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label className="form-check-label" htmlFor="male">
                        Male
                      </label>
                    </div>

                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        value="female"
                        id="female"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "gender_type",
                            event.target.value
                          );
                        }}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label className="form-check-label" htmlFor="female">
                        Female
                      </label>
                    </div>

                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        value="unisex"
                        id="unisex"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "gender_type",
                            event.target.value
                          );
                        }}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label className="form-check-label" htmlFor="unisex">
                        Unisex
                      </label>
                    </div>
                  </div>
                  <div className="text-danger">
                    {formik.errors.gender_type && formik.touched.gender_type
                      ? formik.errors.gender_type
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="dial" className="form-label">
                    Dial{" "}
                    {brandName?.toLowerCase() ==
                    ("rolex" || "audemars piguet" || "patek philippe") ? (
                      <span style={{ color: "red", fontSize: 16 }}>*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <Select
                    name="dial"
                    onChange={(selected) => {
                      formik.setFieldValue("dial", selected.value);
                    }}
                    options={dialOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors.dial && formik.touched.dial
                      ? formik.errors.dial
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="dialMarkers" className="form-label">
                    Dial Markers
                  </label>
                  <Select
                    name="dialMarkers"
                    id="dialMarkers"
                    onChange={(selected) => {
                      formik.setFieldValue("dial_markers", selected.value);
                    }}
                    options={dialMarOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger"></div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="caseSize" className="form-label">
                    Case Size
                  </label>
                  <Select
                    name="caseSize"
                    id="caseSize"
                    onChange={(selected) => {
                      formik.setFieldValue("case_size", selected.value);
                    }}
                    options={caseSizeOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger"></div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="movement" className="form-label">
                    Movement
                  </label>
                  <Select
                    name="movement"
                    id="movement"
                    onChange={(selected) => {
                      formik.setFieldValue("movement", selected.value);
                    }}
                    options={movementOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger"></div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="caseMaterial" className="form-label">
                    Case Material
                  </label>
                  <Select
                    name="caseMaterial"
                    onChange={(selected) => {
                      formik.setFieldValue("case_materials", selected.value);
                    }}
                    options={caseMaterialOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger"></div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="strapBracelet" className="form-label">
                    Strap/Bracelet
                    {brandName?.toLowerCase() ==
                    ("rolex" || "audemars piguet" || "patek philippe") ? (
                      <span style={{ color: "red", fontSize: 16 }}>*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <Select
                    name="strapBracelet"
                    id="strapBracelet"
                    onChange={(selected) => {
                      formik.setFieldValue("bracelet", selected.value);
                    }}
                    options={strapOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors.bracelet && formik.touched.bracelet
                      ? formik.errors.bracelet
                      : null}
                  </div>
                </div>

                <div className="col-md-12">
                  <label htmlFor="clasp" className="form-label">
                    Clasp
                  </label>
                  <Select
                    name="clasp"
                    id="clasp"
                    onChange={(selected) => {
                      formik.setFieldValue("clasp", selected.value);
                    }}
                    options={claspOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger"></div>
                </div>
                {/* Gem Set */}
                <div className="col-md-12">
                  <label className="form-label">Factory Gem Set?</label>
                  <div className="department">
                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="factoryGem"
                        value="Yes"
                        id="yes"
                        onChange={() => {
                          setFactoryGem(true);
                          formik.setFieldValue("factory_gem_set", "Yes");
                        }}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label className="form-check-label" htmlFor="yes">
                        Yes
                      </label>
                    </div>

                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="factoryGem"
                        value="no"
                        id="no"
                        onChange={() => {
                          setFactoryGem(false);
                          formik.setFieldValue("factory_gem_set", "No");
                        }}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label className="form-check-label" htmlFor="no">
                        No
                      </label>
                    </div>
                  </div>

                  {factoryGem ? (
                    <>
                      if yes, tick what's gem-setted
                      <div className="row">
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value="factoryGemDial"
                              id="flexCheckDefault"
                              onChange={(event) => {
                                event.target.checked
                                  ? setFactoryDial(true)
                                  : setFactoryDial(false);
                              }}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="flexCheckDefault"
                            >
                              Dial
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {factoryDial && (
                            <>
                              <label>Indicate what's Gem-setted</label>
                              <input
                                type="text"
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "factory_gem.dial",
                                    event.target.value
                                  );
                                }}
                                className="form-control"
                              />
                              <div className="text-danger">
                                {formik.errors.factory_gem?.dial &&
                                formik.touched.factory_gem?.dial
                                  ? formik.errors.factory_gem?.dial
                                  : null}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={(event) => {
                                event.target.checked
                                  ? setFactoryBracelet(true)
                                  : setFactoryBracelet(false);
                              }}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="flexCheckDefault"
                            >
                              Bracelet
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {factoryBracelet && (
                            <>
                              <label>Indicate what's Gem-setted</label>
                              <input
                                type="text"
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "factory_gem.bracelet",
                                    event.target.value
                                  );
                                }}
                                className="form-control"
                              />
                              <div className="text-danger">
                                {formik.errors.factory_gem?.bracelet &&
                                formik.touched.factory_gem?.bracelet
                                  ? formik.errors.factory_gem?.bracelet
                                  : null}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={(event) => {
                                event.target.checked
                                  ? setFactoryBezel(true)
                                  : setFactoryBezel(false);
                              }}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="flexCheckDefault"
                            >
                              Bezel
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="form-check-label-Indicate">
                            {factoryBezel && (
                              <>
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault1"
                                >
                                  Indicate what's customised
                                </label>
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      "factory_gem.bezel",
                                      event.target.value
                                    );
                                  }}
                                  className="form-control"
                                />
                                <div className="text-danger">
                                  {formik.errors.factory_gem?.bezel &&
                                  formik.touched.factory_gem?.bezel
                                    ? formik.errors.factory_gem?.bezel
                                    : null}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={(event) => {
                                event.target.checked
                                  ? setFactoryLugs(true)
                                  : setFactoryLugs(false);
                              }}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="flexCheckDefault"
                            >
                              Lugs
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {factoryLugs && (
                            <>
                              <label
                                className="form-check-label-Indicate"
                                htmlFor="flexRadioDefault1"
                              >
                                Indicate what's Gem-setted
                              </label>
                              <input
                                type="text"
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "factory_gem.lugs",
                                    event.target.value
                                  );
                                }}
                                className="form-control"
                              />
                              <div className="text-danger">
                                {formik.errors.factory_gem?.lugs &&
                                formik.touched.factory_gem?.lugs
                                  ? formik.errors.factory_gem?.lugs
                                  : null}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={(event) => {
                                event.target.checked
                                  ? setFactoryClasp(true)
                                  : setFactoryClasp(false);
                              }}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="flexCheckDefault"
                            >
                              Clasp
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {factoryClasp && (
                            <>
                              <label
                                className="form-check-label-Indicate"
                                htmlFor="flexRadioDefault1"
                              >
                                Indicate what's Gem-setted
                              </label>
                              <input
                                type="text"
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "factory_gem.clasp",
                                    event.target.value
                                  );
                                }}
                                className="form-control"
                              />
                              <div className="text-danger">
                                {formik.errors.factory_gem?.clasp &&
                                formik.touched.factory_gem?.clasp
                                  ? formik.errors.factory_gem?.clasp
                                  : null}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="text-danger errChck">
                    {formik.errors.factory_gem_set &&
                    formik.touched.factory_gem_set
                      ? formik.errors.factory_gem_set
                      : null}
                    {formik.errors.factory_gem && formik.touched.factory_gem
                      ? formik.errors.factory_gem
                      : null}
                  </div>
                </div>
                {/* Gem Set */}

                {/* Custom Gem Set */}
                <div className="col-md-12">
                  <label className="form-label">
                    Custom Gem Set?{" "}
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <div className="department">
                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="customGemSet"
                        value="Yes"
                        id="yesCustomGemSet"
                        onChange={() => {
                          setCustomGemSet(true);
                          formik.setFieldValue("custom_gem_set", "Yes");
                        }}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="yesCustomGemSet"
                      >
                        Yes
                      </label>
                    </div>

                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="customGemSet"
                        value="No"
                        id="noCustomGemSet"
                        onChange={() => {
                          setCustomGemSet(false);
                          formik.setFieldValue("custom_gem_set", "No");
                        }}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="noCustomGemSet"
                      >
                        No
                      </label>
                    </div>
                  </div>
                  {customGemSet ? (
                    <>
                      if yes, tick what's custom
                      <div className="row">
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="case"
                              onChange={(event) => {
                                event.target.checked
                                  ? setCustomCase(true)
                                  : setCustomCase(false);
                              }}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="case"
                            >
                              Case
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {customCase && (
                            <>
                              <label className="form-check-label-Indicate">
                                Indicate what's Gem-setted
                              </label>
                              <input
                                type="text"
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "custom_gem.case",
                                    event.target.value
                                  );
                                }}
                                className="form-control"
                              />
                              <div className="text-danger">
                                {formik.errors.custom_gem?.case &&
                                formik.touched.custom_gem?.case
                                  ? formik.errors.custom_gem?.case
                                  : null}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="dial"
                              onChange={(event) => {
                                event.target.checked
                                  ? setCustomDial(true)
                                  : setCustomDial(false);
                              }}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="dial"
                            >
                              Dial
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {customDial && (
                            <>
                              <label className="form-check-label-Indicate">
                                Indicate what's Gem-setted
                              </label>
                              <input
                                type="text"
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "custom_gem.dial",
                                    event.target.value
                                  );
                                }}
                                className="form-control"
                              />
                              <div className="text-danger">
                                {formik.errors.custom_gem?.dial &&
                                formik.touched.custom_gem?.dial
                                  ? formik.errors.custom_gem?.dial
                                  : null}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="bracelet"
                              onChange={(event) => {
                                event.target.checked
                                  ? setCustomBracelet(true)
                                  : setCustomBracelet(false);
                              }}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="bracelet"
                            >
                              Bracelet
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {customBracelet && (
                            <>
                              <label className="form-check-label-Indicate">
                                Indicate what's Gem-setted
                              </label>
                              <input
                                type="text"
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "custom_gem.bracelet",
                                    event.target.value
                                  );
                                }}
                                className="form-control"
                              />
                              <div className="text-danger">
                                {formik.errors.custom_gem?.bracelet &&
                                formik.touched.custom_gem?.bracelet
                                  ? formik.errors.custom_gem?.bracelet
                                  : null}
                              </div>
                            </>
                          )}
                        </div>

                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="bezel"
                              onChange={(event) => {
                                event.target.checked
                                  ? setCustomBezel(true)
                                  : setCustomBezel(false);
                              }}
                            />{" "}
                            <label
                              class="form-check-label bg-unset1"
                              for="bezel"
                            >
                              Bezel
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {customBezel && (
                            <>
                              <label className="form-check-label-Indicate">
                                Indicate what's Gem-setted
                              </label>
                              <input
                                type="text"
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "custom_gem.bezel",
                                    event.target.value
                                  );
                                }}
                                className="form-control"
                              />
                              <div className="text-danger">
                                {formik.errors.custom_gem?.bezel &&
                                formik.touched.custom_gem?.bezel
                                  ? formik.errors.custom_gem?.bezel
                                  : null}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="lugs"
                              onChange={(event) => {
                                event.target.checked
                                  ? setCustomLugs(true)
                                  : setCustomLugs(false);
                              }}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="lugs"
                            >
                              Lugs
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {customLugs && (
                            <>
                              <label className="form-check-label-Indicate">
                                Indicate what's Gem-setted
                              </label>
                              <input
                                type="text"
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "custom_gem.lugs",
                                    event.target.value
                                  );
                                }}
                                className="form-control"
                              />
                              <div className="text-danger">
                                {formik.errors.custom_gem?.lugs &&
                                formik.touched.custom_gem?.lugs
                                  ? formik.errors.custom_gem?.lugs
                                  : null}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="clasp"
                              onChange={(event) => {
                                event.target.checked
                                  ? setCustomClasp(true)
                                  : setCustomClasp(false);
                              }}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="clasp"
                            >
                              Clasp
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="form-check-label-Indicate">
                            {customClasp && (
                              <>
                                <label className="form-check-label">
                                  Indicate what's customised
                                </label>
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      "custom_gem.clasp",
                                      event.target.value
                                    );
                                  }}
                                  className="form-control"
                                />
                                <div className="text-danger">
                                  {formik.errors.custom_gem?.lugs &&
                                  formik.touched.custom_gem?.lugs
                                    ? formik.errors.custom_gem?.lugs
                                    : null}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="text-danger errChck">
                    {formik.errors.custom_gem_set &&
                    formik.touched.custom_gem_set
                      ? formik.errors.custom_gem_set
                      : null}
                    {formik.errors.custom_gem && formik.touched.custom_gem
                      ? formik.errors.custom_gem
                      : null}
                  </div>
                </div>
                {/* Custom Gem Set */}
                <div className="col-md-12">
                  <label htmlFor="location" className="form-label">
                    What is the location of this product?
                    <span style={{ color: "red", fontSize: 16 }}> *</span>
                  </label>
                  <input
                    name="location"
                    type="text"
                    className="form-control"
                    ref={inputRef}
                    autoComplete="off"
                    placeholder="Enter location of this product"
                  />
                  <div className="text-danger">
                    {formik.errors.location && formik.touched.location
                      ? formik.errors.location
                      : null}
                  </div>
                </div>

                <div className="container_watch">
                  <div className="row">
                    <div className="col-12">
                      <div className="uploads_images">
                        <h5>
                          Upload watch images <span className="start">*</span>
                        </h5>
                        <p>Please upload Image of max 10mb</p>
                      </div>
                      <div className="watch_box">
                        {/* <img src={img} alt="" /> */}
                        <div className="product-img-box">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="upload-btn-wrapper">
                                <button className="btn">+</button>
                                <input
                                  type="file"
                                  name="myfile"
                                  onChange={(event) => {
                                    let selectedImages = [];
                                    const targetImages = event.target.files;
                                    const targetImagesObject = [
                                      ...targetImages,
                                    ];
                                    targetImagesObject.map((file) => {
                                      if (
                                        file.type === "video/mp4" ||
                                        file.type === "video/ogg"
                                      ) {
                                        const video = videoRef.current;

                                        video.src = URL.createObjectURL(file);
                                        video.onloadedmetadata = () => {
                                          video.currentTime = 5; // Capture the frame at 5 seconds
                                          video.onseeked = generateThumbnail;
                                        };
                                        return (selectedImages = null);
                                      }
                                      setThumbnailUrl(null);
                                      return selectedImages.push(
                                        URL.createObjectURL(file)
                                      );
                                    });
                                    setImagesPrimary(selectedImages);
                                    formik.setFieldValue(
                                      "thumb_image",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="preview_box">
                                <div className="">
                                  {thumbnailUrl && (
                                    <img src={thumbnailUrl} alt="Thumbnail" />
                                  )}
                                  <video
                                    ref={videoRef}
                                    controls
                                    style={{ display: "none" }}
                                  ></video>
                                  <canvas
                                    ref={canvasRef}
                                    style={{ display: "none" }}
                                  ></canvas>
                                  <img src={imagesPrimary} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-danger">
                        {formik.errors.thumb_image && formik.touched.thumb_image
                          ? formik.errors.thumb_image
                          : null}
                      </div>
                      <div className="watch_txt">
                        <p>Select Secondary images/videos</p>
                      </div>
                    </div>
                  </div>
                  <Images images={images} setImages={setImages} />
                  <video
                    ref={videoSecondaryRef}
                    controls
                    style={{ display: "none" }}
                  ></video>
                  <canvas
                    ref={canvasSecondaryRef}
                    style={{ display: "none" }}
                  ></canvas>
                  {/* <div className="next_btn">
                    <button>Next</button>
                  </div> */}
                </div>

                <div className="col-12">
                  <button
                    type="submit"
                    onClick={(e) => {
                      formik.setFieldValue(
                        "product_file",
                        imageSecondaryTarget
                      );
                    }}
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Saving...
                      </>
                    ) : (
                      "Save"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/product-management/Modify"
                        : "/product-management"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProductAdd;
