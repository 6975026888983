import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchAutoFillsList = createAsyncThunk(
  "autoFills/fetchData",
  async (list_type, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "auto-fill-list",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params:{
            type: list_type
          }
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const autoFillsSlice = createSlice({
  name: "brand-model",
  initialState: {
    autoFillsList: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAutoFillsList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAutoFillsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.autoFillsList = action.payload;
      })
      .addCase(fetchAutoFillsList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { bannersData } = autoFillsSlice.actions;
export default autoFillsSlice.reducer;
