import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import PrivateRoutes from "./PrivateRoutes";
import FullLayout from "../app/components/layout/FullLayout";
import Dashboard from "../app/components/layout/Dashboard";
import NotFound from "../app/components/NotFound";
import Login from "../app/components/Auth/Login";
import UserManagement from "../app/components/UserManagement/UserManagement";
import SliderBannerManagement from "../app/components/SliderBanner/SliderBannerManagement";
import AddSliderBanner from "../app/components/SliderBanner/AddSliderBanner";
import EditSliderBanner from "../app/components/SliderBanner/EditSliderBanner";
import EmailTemplateList from "../app/components/EmailTemplates/EmailTemplateList";
import BulkEmailList from "../app/components/BulkEmail/BulkEmailList";
import EditEmailTemplate from "../app/components/EmailTemplates/EditEmailTemplate";
import StaticContentList from "../app/components/StaticContent/StaticContentList";
import EditStaticContent from "../app/components/StaticContent/EditStaticContent";
import ForgotPassword from "../app/components/Auth/ForgotPassword";
import ResetPassword from "../app/components/Auth/ResetPassword";
import BrandManagement from "../app/components/BrandAndModel/BrandManagement";
import EditBrand from "../app/components/BrandAndModel/EditBrand";
import ModelManagement from "../app/components/BrandAndModel/ModelManagement";
import EditModel from "../app/components/BrandAndModel/EditModel";
import AddBrand from "../app/components/BrandAndModel/AddBrand";
import AddModel from "../app/components/BrandAndModel/AddModel";
import GeneralSettings from "../app/components/GeneralSettings/GeneralSettings";
import ChangePassword from "../app/components/GeneralSettings/ChangePassword";
import ProductList from "../app/components/Product/ProductList";
import ProductAdd from "../app/components/Product/ProductAdd";
import PriceLineChart from "../app/components/Chart/PriceLineChart";
import ProductEdit from "../app/components/Product/ProductEdit";
import ProductView from "../app/components/Product/ProductView";
import PriceChartData from "../app/components/BrandModelPriceChart/PriceChartData";
import UploadPriceChart from "../app/components/BrandModelPriceChart/UploadPriceChart";
import EditProfile from "../app/components/GeneralSettings/EditProfile";
import NewBulkEmail from "../app/components/BulkEmail/NewBulkEmail";
import EditBulkEmail from "../app/components/BulkEmail/EditBulkEmail";
import BulkPushNotificationList from "../app/components/BulkPushNotification/BulkPushNotificationList";
import NewBulkPushNotification from "../app/components/BulkPushNotification/NewBulkPushNotification";
import EditBulkPushNotification from "../app/components/BulkPushNotification/EditBulkPushNotification";
import BoostManagement from "../app/components/BoostPlan/BoostManagement";
import AddBoost from "../app/components/BoostPlan/AddBoost";
import EditBoost from "../app/components/BoostPlan/EditBoost";
import CoinsManagement from "../app/components/CoinsPlan/CoinsManagement";
import EditCoins from "../app/components/CoinsPlan/EditCoins";
import AddCoins from "../app/components/CoinsPlan/AddCoins";
import CoinsHistory from "../app/components/CoinsPlan/CoinsHistory";
import UserRating from "../app/components/UserManagement/UserRating";
import StaffList from "../app/components/StaffManagement/StaffList";
import NewStaff from "../app/components/StaffManagement/NewStaff";
import EditStaff from "../app/components/StaffManagement/EditStaff";
import EditUserRating from "../app/components/UserManagement/EditUserRating";
import AccessControl from "./AccessControlOnRoutes";
import PermissionDenied from "../app/components/PermissionDenied";
import RouteAccessControl from "./RouteProtection";
import FilterManagement from "../app/components/FilterManagement/FilterManagement";
import AddFilter from "../app/components/FilterManagement/AddFilter";
import EditFilter from "../app/components/FilterManagement/EditFilter";

import AutoFilesManagement from  '../app/components/AutoFils/AutoFilesManagement'
import AddAutoFill from  '../app/components/AutoFils/AddAutoFill'
import EditAutoFill from  '../app/components/AutoFils/EditAutoFill'
import Reports from '../app/components/Reports/ReportsManagement'

import OutlierHistory from "../app/components/outlier/OutlierHistory";
import PctHistory from "../app/components/pctAtert/pctHistory";

import WatchIdManagement from "../app/components/WatchChart/WatchIdManagement"
import AddWatchId from "../app/components/WatchChart/AddWatchId"
import EditWatchId from "../app/components/WatchChart/EditWatchId"


// import UsersWithChat from "../app/components/Chat/UsersWithChat";
// import ReceiverChat from "../app/components/Chat/ReceiverChat";

export default function AllRoutes() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<FullLayout />}>
          <Route element={<PrivateRoutes />}>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_DASHBOARD}
                />
              }
            >
              <Route path="dashboard" element={<Dashboard />} />
            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_USERMANAGEMENT}
                />
              }
            >
              <Route path="user-management" element={<UserManagement />} />
            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_STAFFMANAGEMENT}
                />
              }
            >
              <Route path="staff-management" element={<StaffList />} />
              <Route path="auto-fills-management" element={<AutoFilesManagement />} />
              <Route path="auto-fills-management/add-auto-fill" element={<AddAutoFill />} />
              <Route path="auto-fills-management/edit-auto-fill" element={<EditAutoFill />}/>

              <Route path="watch-id-management" element={<WatchIdManagement />} />
              <Route path="watch-id-management/add-watch-id" element={<AddWatchId />} />
              <Route path="watch-id-management/edit-watch-id" element={<EditWatchId />}/>

              <Route path="analyze-reports-management" element={<Reports />}/>

            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_EMAILTEMPLATES}
                />
              }
            >
              <Route path="email-templates" element={<EmailTemplateList />} />
            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_BULKNOTIFICATION}
                />
              }
            >
              <Route path="bulk-email" element={<BulkEmailList />} />
            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_BULKMOBILENOTIFICATION}
                />
              }
            >
              <Route
                path="bulk-push-notification"
                element={<BulkPushNotificationList />}
              />
            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_BRANDMANAGEMENT}
                />
              }
            >
              <Route path="brand-management" element={<BrandManagement />} />
            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_STATICCONTENT}
                />
              }
            >
              <Route path="static-contents" element={<StaticContentList />} />
            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_MODELMANAGEMENT}
                />
              }
            >
              <Route path="model-management" element={<ModelManagement />} />
            </Route>

            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_MODELMANAGEMENT}
                />
              }
            >
              <Route path="filter-management" element={<FilterManagement />} />
              <Route
                path="filter-management/add-filter"
                element={<AddFilter />}
              />
              <Route
                path="filter-management/edit-filter"
                element={<EditFilter />}
              />
            </Route>

            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_PRODUCTMANAGEMENT}
                />
              }
            >
              <Route path="product-management" element={<ProductList />} />
            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_COINSMANAGEMENT}
                />
              }
            >
              <Route path="coins-management" element={<CoinsManagement />} />
            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_COINSPURCHASEHISTORY}
                />
              }
            >
              <Route path="coins-history" element={<CoinsHistory />} />
              <Route path="outlier-management" element={<OutlierHistory />} />
              <Route path="pct-management" element={<PctHistory />} />
            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_HOMESCREENBANNER}
                />
              }
            >
              <Route path="banner-list" element={<SliderBannerManagement />} />
            </Route>
            <Route
              element={
                <RouteAccessControl
                  dashboardKey={process.env.REACT_APP_BOOSTPLANMANAGEMENT}
                />
              }
            >
              <Route path="boost-management" element={<BoostManagement />} />
            </Route>

            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_STAFFMANAGEMENT}
                />
              }
            >
              <Route path="staff-management/add-staff" element={<NewStaff />} />
              <Route
                path="staff-management/edit-staff"
                element={<EditStaff />}
              />
            </Route>
            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_HOMESCREENBANNER}
                />
              }
            >
              <Route
                path="banner-list/add-banner"
                element={<AddSliderBanner />}
              />
              <Route
                path="banner-list/edit-banner"
                element={<EditSliderBanner />}
              />
            </Route>
            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_EMAILTEMPLATES}
                />
              }
            >
              <Route
                path="email-templates/edit-templates"
                element={<EditEmailTemplate />}
              />
            </Route>
            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_BULKNOTIFICATION}
                />
              }
            >
              <Route path="bulk-email/new-email" element={<NewBulkEmail />} />
              <Route path="bulk-email/edit-email" element={<EditBulkEmail />} />
            </Route>

            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_BULKMOBILENOTIFICATION}
                />
              }
            >
              <Route
                path="bulk-push-notification/new-push-notification"
                element={<NewBulkPushNotification />}
              />
              <Route 
                path="bulk-push-notification/update-push-notificatdion"
                element={<EditBulkPushNotification />}
              />
            </Route>
             
            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_BRANDMANAGEMENT}
                />
              }
            >
              <Route
                path="static-contents/edit-static-contents"
                element={<EditStaticContent />}
              />
            </Route>

            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_BRANDMANAGEMENT}
                />
              }
            >
              <Route
                path="brand-management/edit-brand"
                element={<EditBrand />}
              />
              <Route path="brand-management/add-brand" element={<AddBrand />} />
            </Route>
            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_MODELMANAGEMENT}
                />
              }
            >
              <Route path="model-management/add-model" element={<AddModel />} />
              <Route
                path="model-management/edit-model"
                element={<EditModel />}
              />
            </Route>
            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_BOOSTPLANMANAGEMENT}
                />
              }
            >
              <Route
                path="boost-management/edit-boost"
                element={<EditBoost />}
              />
              <Route path="boost-management/add-boost" element={<AddBoost />} />
            </Route>

            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_COINSMANAGEMENT}
                />
              }
            >
              <Route
                path="coins-management/edit-coins"
                element={<EditCoins />}
              />
              <Route path="coins-management/add-coins" element={<AddCoins />} />
            </Route>

            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_PRODUCTMANAGEMENT}
                />
              }
            >
              <Route
                path="product-management/add-product"
                element={<ProductAdd />}
              />
              <Route
                path="product-management/edit-product"
                element={<ProductEdit />}
              />
            </Route>

            <Route
              path="product-management/view-product"
              element={<ProductView />}
            />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="update-profile" element={<EditProfile />} />
            <Route path="general-settings" element={<GeneralSettings />} />
            <Route path="chart" element={<PriceLineChart />} />
            <Route path="price-chart-data" element={<PriceChartData />} />
            <Route
              element={
                <AccessControl
                  dashboardKey={process.env.REACT_APP_PRICECHARTDATA}
                />
              }
            >
              <Route
                path="price-chart-data/add-file"
                element={<UploadPriceChart />}
              />
            </Route>

            <Route
              path="user-management/user-rating/:userID"
              element={<UserRating />}
            />
            <Route
              path="user-management/user-rating-edit/:id/:uid"
              element={<EditUserRating />}
            />

            {/* <Route path="chat-users-list" element={<UsersWithChat />} />
            <Route path="user-chat" element={<ReceiverChat />} /> */}
          </Route>
        </Route>
        <Route path="/*" element={<NotFound />} />
        <Route path="/sign-in" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route path="/unsubscribe" element={<ResetPassword />} />
        <Route path="/permission-denied" element={<PermissionDenied />} />
      </Routes>
    </div>
  );
}
