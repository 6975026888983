import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminProfile } from "./settingsSlice";
import LoadingSpinner from "../Loader/LoadingSpinner";
function EditProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.settings?.adminData);
  const adminDataLoad = useSelector((state) => state.settings?.adminDataLoad);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Required*")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(30, "Name must not exceed 30 characters"),
    // email: Yup.string().required("Required*").email("Email is invalid"),
  });

  useEffect(() => {
    dispatch(fetchAdminProfile());
  }, []);

  const formik = useFormik({
    initialValues: {
      name: data?.data?.name,
      image: null,
    },
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    // validateOnBlur: false,
    onSubmit: async (data) => {
      var formData = new FormData();
      formData.append("image", formik.values.image);
      formData.append("name", formik.values.name);
      //  dispatch(AddFile(formData));
      setIsLoading(true);
      await axios
        .post(process.env.REACT_APP_API_KEY + "update-profile", formData, {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            swal({
              title: "Success!",
              text: res.data.message,
              icon: "success",
              button: "Okay",
            });

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/dashboard")
                : navigate("/dashboard");
            }
            setIsLoading(false);
          }
        })
        .catch((error) => {
          swal("Oops", error.response.data.message, "error");
          setIsLoading(false);
        });
    },
  });
  if (adminDataLoad) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Profile</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Name
                  </label>
                  <div className="icon-input">
                    <input
                      name="name"
                      type="text"
                      className="form-control eye-icon"
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                      defaultValue={data?.data?.name}
                      autoComplete="off"
                      placeholder="Name"
                    />
                  </div>

                  <div className="text-danger">
                    {formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Email
                  </label>
                  <div className="icon-input">
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      onChange={formik.handleChange}
                      defaultValue={formik.values.email || data?.data?.email}
                      autoComplete="off"
                      placeholder="Email"
                      disabled
                    />
                  </div>
                  <div className="text-danger">
                    {formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : null}
                  </div>
                </div>
                <div className="col-md-6 mt-5">
                  <input
                    type="file"
                    name="image"
                    onChange={(e) => {
                      formik.setFieldValue("image", e.currentTarget.files[0]);
                    }}
                    placeholder="Upload Excel File"
                  />
                  <div className="text-danger">
                    {formik.errors.file && formik.touched.file
                      ? formik.errors.file
                      : null}
                  </div>
                </div>
                <span>
                  <img
                    src={data?.data?.image}
                    alt="profile_img"
                    style={{ height: 60 }}
                  />
                </span>

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Updating...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/dashboard"
                        : "/dashboard"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditProfile;
