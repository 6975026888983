import React from "react";
import { NavLink } from "react-router-dom";

export default function PermissionDenied() {
    return (
        <div className="app not-found-page app-error align-content-stretch d-flex flex-wrap">
            <div className="app-error-info">
                <h5>Oops!</h5>
                <span>
                    You don't have permission to access this page.
                    <br />
                    Please contact your administrator.
                </span>
                <NavLink to="/dashboard" className="btn btn-dark">
                    Go to dashboard
                </NavLink>
            </div>
            <div className="app-error-background" />
        </div>
    );
}