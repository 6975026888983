import { React, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [password, setPassword] = useState("password");
  const [passwordConfirm, setPasswordConfirm] = useState("password");
  const params = useParams();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
    checkUserExit();
  }, []);

  const checkUserExit = async () => {
    console.log(params.id);
    await axios
      .get(
        process.env.REACT_APP_API_KEY + "check-reset-password/" + params.id,
        {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("Succ");
      })
      .catch((error) => {
        console.log("Fail");
        swal("Oops", error.response.data.message, "error");
      });
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#_%*?&])[A-Za-z\d#_@$!%*?&]{8,}$/,
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  console.log(params.id);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (data) => {
      setIsLoading(true);
      await axios
        .post(
          process.env.REACT_APP_API_KEY + "save-reset-password/" + params.id,
          data
        )
        .then((res) => {
          if (res.data.success) {
            swal(
              "Success",
              "Password Reset Successfully, Please Login",
              "success"
            );
            if (
              res.data.data === "super_admin" ||
              res.data.data === "sub_admin"
            ) {
              navigate("/sign-in");
            }
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          swal("Oops", error.response.data.message, "error");
          console.log("Error", error.response);
        });
      // console.log(JSON.stringify(data, null, 2));
    },
  });

  const togglePassword = () => {
    if (password === "password") {
      setPassword("text");
    } else {
      setPassword("password");
    }
  };
  const togglePasswordConfirm = () => {
    if (passwordConfirm === "password") {
      setPasswordConfirm("text");
    } else {
      setPasswordConfirm("password");
    }
  };
  return (
    <div className="app app-auth-logo-reset-pass reset-container">
      <div className="app-auth-background"></div>
      <div className="app-auth-container">
        <div className="sign-in-container">
          <div className="logo-reset-forgot">
            <NavLink to="#"></NavLink>
          </div>
          <form className="row g-3" onSubmit={formik.handleSubmit}>
            <div className="auth-credentials m-b-xxl" style={{ marginTop: 30 }}>
              <h3>Reset Password !</h3>
              Your new password must be different from previous used password
              <div className="form-group">
                <label htmlFor="signInEmail" className="form-label mt-3">
                  Password ff
                </label>
                <div className="icon-input">
                  <input
                    type={password}
                    name="password"
                    className={
                      formik.errors.password
                        ? "form-control error-border "
                        : "form-control"
                    }
                    placeholder="Password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  {password === "password" ? (
                    <i
                      className="fa fa-eye"
                      aria-hidden="true"
                      onClick={togglePassword}
                      style={
                        formik.values.password.length > 0
                          ? {}
                          : { display: "none" }
                      }
                    ></i>
                  ) : (
                    <i className="fa fa-eye-slash" onClick={togglePassword}></i>
                  )}
                </div>
                <div className="text-danger">
                  {formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : null}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="signInEmail" className="form-label mt-3">
                  Confirm Password
                </label>
                <div className="icon-input">
                  <input
                    type={passwordConfirm}
                    name="confirmPassword"
                    className={
                      formik.errors.confirmPassword
                        ? "form-control error-border "
                        : "form-control"
                    }
                    placeholder="Confirm Password"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                  />

                  {passwordConfirm === "password" ? (
                    <i
                      className="fa fa-eye"
                      aria-hidden="true"
                      onClick={togglePasswordConfirm}
                      style={
                        formik.values.confirmPassword.length > 0
                          ? {}
                          : { display: "none" }
                      }
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye-slash"
                      onClick={togglePasswordConfirm}
                    ></i>
                  )}
                </div>
                <div className="text-danger">
                  {formik.errors.confirmPassword &&
                  formik.touched.confirmPassword
                    ? formik.errors.confirmPassword
                    : null}
                </div>
              </div>
            </div>

            <div className="auth-submit">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <i className="fa fa-refresh fa-spin"></i>Loading
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
          <div className="divider" />
        </div>
      </div>
    </div>
  );
}
