import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../Loader/LoadingSpinner";
import * as Yup from "yup";
import moment from "moment";
function EditUserRating() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [userId, setUserID] = useState(0);

  const { id, uid } = useParams();
  
  useEffect(() => {
    getSingleSliderBanner();
  }, []);

  const getSingleSliderBanner = async () => {
    setIsLoading(true);
    await axios
      .get(process.env.REACT_APP_API_KEY + "single-rating/" + id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setInputs({
          rated_by: res.data.data?.rated_by_user?.name,
          user: res.data.data?.rated_user?.name,
          rating: res.data.data.rating,
          description: res.data.data.description,
        });
        setUserID(res.data.data?.rated_user?.id);
        formik.setFieldValue("description", res.data.data.description);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error>>", error.message);
      });
  };

  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
    // return undefined;
  };
  const ratingValidator = (value) => {
    if (!value) return false;
    const numericValue = Number(value);
    return !isNaN(numericValue) && numericValue >= 1 && numericValue <= 5;
  };

  const validationSchema = Yup.object().shape({
   
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      //rating: inputs.rating ? inputs.rating : "",
      description: inputs.description ? inputs.description : "",
    },
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    // validateOnBlur: false,
    onSubmit: async (data) => {
      setIsLoading(true);
      await axios
        .post(
          process.env.REACT_APP_API_KEY + "update-user-rating/" + id,
          data,
          {
            headers: {
              "Content-type": "Application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            swal({
              title: "Success!",
              text: "Data Updated Successfully",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            navigate("/user-management/user-rating/" + userId);
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            swal("Oops", error.response.data.message, "error");
          }
          setIsLoading(false);
        });
    },
  });
  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Edit Rating</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form
                className="row g-3"
                onSubmit={formik.handleSubmit}
                // enctype="multipart/form-data"
              >
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    To User
                  </label>
                  <input
                    name="user"
                    type="text"
                    className="form-control"
                    id="inputTitle"
                    onChange={formik.handleChange}
                    defaultValue={inputs.user}
                    autoComplete="off"
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Rated By
                  </label>
                  <input
                    name="rated_by"
                    type="text"
                    className="form-control"
                    id="inputTitle"
                    onChange={formik.handleChange}
                    defaultValue={inputs.rated_by}
                    autoComplete="off"
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Rating
                  </label>
                  <input
                    name="rating"
                    type="text"
                    className="form-control"
                    id="inputTitle"
                    onChange={(e) => {
                      formik.setFieldValue("rating", e.target.value);
                    }}
                    disabled
                    defaultValue={inputs.rating}
                    autoComplete="off"
                    placeholder="Rating"
                  />
                  <div className="text-danger">
                    {formik.errors.rating && formik.touched.rating
                      ? formik.errors.rating
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Description
                  </label>
                  <textarea
                    name="description"
                    type="text"
                    className="form-control"
                    id="inputTitle"
                    onChange={(e) => {
                      formik.setFieldValue("description", e.target.value);
                    }}
                    defaultValue={inputs.description}
                    autoComplete="off"
                    placeholder="Description"
                  />
                  <div className="text-danger">
                    {formik.errors.description && formik.touched.description
                      ? formik.errors.description
                      : null}
                  </div>
                </div>

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Saving...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/user-management/user-rating/Modify/"+uid
                        : "/user-management/user-rating/"+uid
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditUserRating;
