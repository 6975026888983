import React from "react";
import $ from "jquery";
import swal from "sweetalert";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect,useState } from "react";
import { NavLink, useParams, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import moment from "moment";
import { fetchStaffManagement } from "./staffManagementSlice";
import { AccessControlComponent } from "../../../routes/AccessControlOnRoutes";
import { fetchUserManagementPermission } from "../UserManagement/userSlice";

const StaffList = () => {

    const location = useLocation();
    let { action } = useParams();
    const dispatch = useDispatch();
    const [permissionToModify, setPermissionToModify] = useState(true);
    const users = useSelector((state) => state.staffManagement.staffManagement);
    const isLoading = useSelector((state) => state.staffManagement.isLoading);
    const error = useSelector((state) => state.staffManagement.error);
    
    const dashboardPermission = useSelector((state) => state.user.dashboardPermission.data);
    const isdashboardPermissionLoading = useSelector((state) => state.sidebar.data.isLoading);
    const dashboardPermissionError = useSelector((state) => state.sidebar.data.error);
    
    const initializeDataTable = () => {
        $("#myTable").DataTable().clear().destroy();
        setTimeout(function () {
            $("#myTable").DataTable({
                buttons: [
                    {
                      extend: "excelHtml5",
                      title: "",
                      text: "Export",
                      filename: "Staff",
                      className: "btn btn-info mt-2",
                      exportOptions: {
                        columns: ":not(.no-export)", // Exclude columns with the "no-export" class
                      },
                    },
                  ],
                bDestroy: true,
                fixedHeader: true,
                pagingType: "full_numbers",
                pageLength: 10,
                processing: true,
                dom: "Bfrtip",
                select: true,
            });
        }, 1200);
    };

    useEffect(() => {
        dispatch(fetchStaffManagement());
        initializeDataTable();
    }, [dispatch, location.key, dashboardPermission]);
    useEffect(() => {
        dispatch(fetchUserManagementPermission());
    }, []);
    useEffect(() => {
        if (dashboardPermission && localStorage.getItem("user_role") !== "super_admin") {
            let permission = AccessControlComponent({ dashboardKey: process.env.REACT_APP_STAFFMANAGEMENT, data: dashboardPermission[0] });
            console.log("permission", permission);
            setPermissionToModify(permission);
        }
    }, [dashboardPermission]);

    if (isLoading || isdashboardPermissionLoading) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }

    if (error || dashboardPermissionError) {
        return (
            <div className="content mt-3">
                <div className="row">
                    <div className="mt-3" align="center">
                        <h5>Some Thing Went Wrong</h5>
                    </div>
                </div>
            </div>
        );
    }

    const handleChange = (id, type) => {
        swal({
            title: "Are you sure?",
            text: "You want to update this action.",
            icon: "warning",
            buttons: ["Cancel", "Submit"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                handleChangeSubmit(id, type);
            }
        });
    };

    const handleChangeSubmit = async (id, type) => {
        var data = {
            actionType: type,
        };
        await axios
            .post(process.env.REACT_APP_API_KEY + "user-update-action/" + id, data, {
                headers: {
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then(function (response) {
                swal({
                    title: "Updated!",
                    text: "Data updated successfully.",
                    icon: "success",
                    button: "Okay",
                });
                initializeDataTable();
                dispatch(fetchStaffManagement());
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleRole = async (id) => {
        await axios
            .get(process.env.REACT_APP_API_KEY + "update-role/" + id, {
                headers: {
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then(function (response) {
                swal({
                    title: "Updated!",
                    text: "Role Updated Successfully",
                    icon: "success",
                    button: "Okay",
                });
                initializeDataTable();
                dispatch(fetchStaffManagement());
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    function capitalizeFirstWord(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function capitalizeFirstWord(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const renderUser = (
        <table id="myTable" className="table table-striped my-3">
            <thead className="table-dark">
                <tr>
                    <th scope="col">Sr.No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Registered On</th>
                    <th scope="col">Status</th>
                    <th scope="col">Role</th>
                    {permissionToModify && (<>
                        <th scope="col" className="no-export" data-orderable="false">
                            Action(s)
                        </th>
                    </>)}
                </tr>
            </thead>
            <tbody>
                {users?.map((item, index) => (
                    <React.Fragment key={item.id}>
                        <tr>
                            <td>{++index}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>

                            <td data-sort={moment(item.created_at).format('YYYY.MM.DD HH.mm.ss')}>{moment(item.created_at).format(process.env.REACT_APP_DATE_FORMAT)}</td>
                            <td>{capitalizeFirstWord(item.status)}</td>
                            <td>{item.role == 'sub_admin' ? 'Sub Admin' : 'Super Admin'}</td>
                            {permissionToModify && (
                                <td>
                                    <span
                                        style={
                                            item.status === "active"
                                                ? { color: "green", cursor: "pointer" }
                                                : { color: "red", cursor: "pointer" }
                                        }
                                        className="material-icons-outlined"
                                        onClick={() => {
                                            handleChange(item.id, "status");
                                        }}
                                        title={item.status === "active" ? "Active" : "Inactive"}
                                    >
                                        {item.status === "active" ? "toggle_on" : "toggle_off"}
                                    </span>
                                    &nbsp;&nbsp;&nbsp;
                                    <Link
                                        to={{ pathname: "/staff-management/edit-staff" }}
                                        state={{ id: item.id, action: action }}
                                    >
                                        <span className="material-icons" title="Edit">
                                            edit
                                        </span>
                                    </Link>
                                </td>
                            )}
                        </tr>
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col">
                    <div className="page-description">
                        <h1>Staff Management</h1>
                    </div>
                </div>
            </div>

           {permissionToModify &&( <>
                <NavLink className="btn btn-info mb-2" to="/staff-management/add-staff">
                    Add New Staff
                </NavLink>
            </>)}
            {isLoading ? <LoadingSpinner /> : renderUser}
        </div>
    );
}

export default StaffList