import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../Loader/LoadingSpinner";
import * as Yup from "yup";
import moment from "moment";
function EditSliderBanner() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [selected_image, setImage] = useState("");
  const params = useLocation();
  useEffect(() => {
    getSingleSliderBanner();
  }, []);

  const getSingleSliderBanner = async () => {
    setIsLoading(true);
    await axios
      .get(
        process.env.REACT_APP_API_KEY +
          "slider-banner/" +
          params.state.id +
          "/edit",
        {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setInputs({
          title: res.data.data.title,
          image: res.data.data.image,
          link: res.data.data.link,
          start_date: setStartDate(
            moment(res.data.data.start_date, "YYYY-MM-DD").isValid()
              ? moment(res.data.data.start_date, "YYYY-MM-DD").toDate()
              : null
          ),
          end_date: setEndDate(
            moment(res.data.data.end_date, "YYYY-MM-DD").isValid()
              ? moment(res.data.data.end_date, "YYYY-MM-DD").toDate()
              : null
          ),
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error>>", error.message);
      });
  };

  const FILE_SIZE = 11200 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];
  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
    // return undefined;
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .trim()
      .min(3, "Minimum 3 characters")
      .max(20, "Title must not exceed 20 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date must be after start date"),
  });

  const formik = useFormik({
    initialValues: {
      title: inputs.title ? inputs.title : "",
      image: null,
      link: inputs.link ? inputs.link : "",
      startDate: moment(startDate).format("yyyy-MM-DD"),
      endDate: moment(endDate).format("yyyy-MM-DD"),
    },
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    // validateOnBlur: false,
    onSubmit: async (data) => {
      //setIsLoading(true);
      const rowId = params?.state.id;
      const formData = new FormData();
      formData.append("image", formik.values.image);
      formData.append("title", formik.values.title);
      formData.append("link", formik.values.link);
      formData.append("startDate", formik.values.startDate);
      formData.append("endDate", formik.values.endDate);
      console.log(rowId);
      console.log("formData Edit", formik);
      await axios
        .post(
          process.env.REACT_APP_API_KEY + "slider-banner-edit/" + rowId,
          formData,
          {
            headers: {
              //"Content-type": "Application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            swal({
              title: "Success!",
              text: "Data Updated Successfully",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/banner-list")
                : navigate("/banner-list/Modify");
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            swal("Oops", error.response.data.message, "error");
          }
          setIsLoading(false);
        });
    },
  });
  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Edit Slider Banner</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form
                className="row g-3"
                onSubmit={formik.handleSubmit}
                // enctype="multipart/form-data"
              >
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Title
                  </label>
                  <input
                    name="title"
                    type="text"
                    className="form-control"
                    id="inputTitle"
                    onChange={formik.handleChange}
                    defaultValue={inputs.title}
                    autoComplete="off"
                    placeholder="Enter Title"
                  />
                  <div className="text-danger">
                    {formik.errors.title && formik.touched.title
                      ? formik.errors.title
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    External Url
                  </label>
                  <input
                    name="link"
                    type="text"
                    className="form-control"
                    id="inputTitle"
                    onChange={formik.handleChange}
                    defaultValue={inputs.link}
                    autoComplete="off"
                    placeholder="Enter External Url"
                  />
                </div>
                <div className="col-md-6 my_calender">
                  <label htmlFor="inputStartDate" className="form-label">
                    Start Date
                  </label>
                  <br></br>

                  <DatePicker
                    name="startDate"
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      formik.setFieldValue(
                        "startDate",
                        moment(date).format("yyyy-MM-DD")
                      );
                    }}
                    className="form-control"
                    autoComplete="off"
                    placeholderText="Start Date"
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    required
                  />
                  <span className="calendar-icon">
                    <i class="material-icons-two-tone">calendar_today</i>
                  </span>

                  <div className="text-danger">
                    {formik.errors.startDate && formik.touched.startDate
                      ? formik.errors.startDate
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputStartDate" className="form-label">
                    End Date
                  </label>
                  <br></br>
                  <DatePicker
                    className="form-control"
                    name="endDate"
                    dateFormat="yyyy-MM-dd"
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      formik.setFieldValue(
                        "endDate",
                        moment(date).format("yyyy-MM-DD")
                      );
                    }}
                    dropdownMode="select"
                    minDate={startDate}
                    adjustDateOnChange
                  />
                  <span className="calendar-icon">
                    <i class="material-icons-two-tone">calendar_today</i>
                  </span>
                  <div className="text-danger">
                    {formik.errors.endDate && formik.touched.endDate
                      ? formik.errors.endDate
                      : null}
                  </div>
                </div>
                <div className="item-wrapper">
                  <div className="row-item">
                    <input
                      type="file"
                      name="image"
                      onChange={(e) => {
                        formik.setFieldValue("image", e.currentTarget.files[0]);
                      }}
                    />
                    <div className="text-danger">
                      {formik.errors.image && formik.touched.image
                        ? formik.errors.image
                        : null}
                    </div>
                   
                  </div>
                  
                  <img
                    src={inputs.image}
                    alt="img"
                    height={100}
                    className="mt-3"
                  ></img>
                  
                </div>
                <label style={{ fontSize: 10, color: "blue" }}>
                    Best resolution for the banner image 2659 X 1406.
                    </label>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Saving...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/banner-list/Modify"
                        : "/banner-list"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditSliderBanner;
