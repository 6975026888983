import React from "react";
import { useState , useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
function NewBulkPushNotification() {
    const [isLoading, setIsLoading] = useState(false);

    const [sentDate, setSentDate] = useState(new Date(Date.now() + 1*24*60*60*1000));
    const navigate = useNavigate();


    const validationSchema = Yup.object().shape({
        subject: Yup.string().required("Subject is required").trim().max(100),
        sentDate: Yup.string().required("Date is required").trim().max(100),
        roles: Yup.string().required("Select a role").trim().max(100),
        message: Yup.string().required("Message is required").trim().max(250),
    });

    const initialValues = {
        subject: "",
        sentDate: sentDate,
        message: "",
        roles: "",
    };
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (data) => {
            data = {
                subject: data?.subject,
                message: data?.message,
                sentDate: data.sentDate,
                roles: data?.roles,
            };
            console.log("data=====>", data);
            setIsLoading(true);
            axios
                .post(
                    process.env.REACT_APP_API_KEY + "sent-bulk-push-notification",
                    data,
                    {
                        headers: {
                            "Content-type": "Application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((res) => {
                    if (res.status === 200) {
                        swal({
                            title: "Success!",
                            text: "Updated Successfully",
                            icon: "success",
                            button: "Okay",
                        });
                        setIsLoading(false);
                        navigate("/bulk-push-notification");
                    }
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        swal("Oops", error.response.data.message, "error");
                    }
                    setIsLoading(false);
                });
        },
    });

    useEffect(()=>{
        console.log('errors',formik.errors);
    },[formik])

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="page-description">
                        <h1>Create Notification</h1>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="example-container">
                        <div className="example-content">
                            <form className="row g-3" onSubmit={formik.handleSubmit}>
                                <div className="col-md-6">
                                    <label htmlFor="inputEmail4" className="form-label">
                                        Subject Name
                                    </label>
                                    <input
                                        name="subject"
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                            formik.setFieldValue("subject", e.target.value);
                                        }}
                                        value={formik.values.subject}
                                        autoComplete="false"
                                        placeholder="Subject Name"
                                        onBlur={formik.handleBlur}
                                    />
                                    {/* <i className="fa fa-plus-circle" aria-hidden="true"></i> */}
                                    <div className="text-danger">
                                        {formik.errors.subject && formik.touched.subject
                                            ? formik.errors.subject
                                            : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="inputStartDate" className="form-label">
                                        Send Date
                                    </label>
                                    <br></br>
                                    <DatePicker
                                        name="sentDate"
                                        selected={sentDate}
                                        onChange={(date) => {
                                            setSentDate(date);
                                            formik.setFieldValue(
                                                "sentDate",
                                                moment(date).format("YYYY-MM-DD")
                                            );
                                        }}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholderText="Send Date"
                                        minDate={sentDate}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    <div className="text-danger">
                                        {formik.errors.sentDate && formik.touched.sentDate
                                            ? formik.errors.sentDate
                                            : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="roles" className="form-label">
                                        Send To
                                    </label>
                                    <div className="department send-to-input">
                                        <div className="form-check dis">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="roles"
                                                id="user"
                                                onChange={() => {
                                                    formik.setFieldValue("roles", "user");
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="user">
                                                Buyers
                                            </label>
                                        </div>
                                        <div className="form-check dis">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="roles"
                                                id="seller"
                                                onChange={() => {
                                                    formik.setFieldValue("roles", "seller");
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="seller">
                                                Seller
                                            </label>
                                        </div>
                                        <div className="form-check dis">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="roles"
                                                id="both"
                                                onChange={() => {
                                                    formik.setFieldValue("roles", "both");
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="both">
                                                Both
                                            </label>
                                        </div>

                                    </div>
                                    <div className="text-danger">
                                        {formik.errors.roles && formik.touched.roles
                                            ? formik.errors.roles
                                            : null}
                                    </div>

                                </div>
                                <div className="col-12">
                                    <label htmlFor="message" className="form-label">
                                        Tell the customers about this watch
                                    </label>
                                    <textarea
                                        name="message"
                                        id="message"
                                        onChange={(event) => {
                                            formik.setFieldValue("message", event.target.value);
                                        }}
                                        className="form-control"
                                    ></textarea>
                                    <div className="text-danger">
                                        {formik.errors.message && formik.touched.message
                                            ? formik.errors.message
                                            : null}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">
                                        {isLoading ? (
                                            <>
                                                <i className="fa fa-refresh fa-spin"></i>Sending
                                            </>
                                        ) : (
                                            "Send"
                                        )}
                                    </button>

                                    <Link
                                        className="btn btn-primary mx-3"
                                        to={
                                            "/bulk-push-notification"
                                        }
                                    >
                                        Back
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewBulkPushNotification;
