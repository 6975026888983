import React from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import JoditEditor from "jodit-react";
import Select from "react-select";
function NewBulkEmail() {
  const [isLoading, setIsLoading] = useState(false);

  const [inputs, setInputs] = useState({});
  const [message, setContent] = useState("");
  const [subject, setSubject] = useState("");
  const [sentDate, setSentDate] = useState(
    new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)
  );
  const editor = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getEmailTemplate();
  }, []);
  const getEmailTemplate = () => {
    axios
      .get(process.env.REACT_APP_API_KEY + "get-email-template", {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setInputs({
          subject: res.data.data.subject,
          alias: res.data.data.alias,
          content: res.data.data.message,
          send_date: res.data.data.send_date,
        });
        // setSentDate(
        //     moment(res.data.data.send_date, "YYYY-MM-DD").isValid()
        //         ? moment(res.data.data.send_date, "YYYY-MM-DD").toDate()
        //         : null
        // );
        setSubject(res.data.data.subject);
      })
      .catch((error) => {
        console.log("error>>", error.message);
      });
  };

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required").trim().max(100),
    sentDate: Yup.string().required("Date is required").trim().max(100),
    roles: Yup.string().required("Select a Send To").trim().max(100),
  });

  const initialValues = {
    subject: inputs.subject,
    sentDate: sentDate,
    message: inputs.content,
    roles: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (data) => {
      data = {
        subject: data?.subject,
        message: data?.message,
        sentDate: data.sentDate,
        roles: data?.roles,
      };
      console.log("data=====>", formik);
      setIsLoading(true);
      axios
        .post(process.env.REACT_APP_API_KEY + "sent-bulk-mail", data, {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Updated Successfully",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);
            navigate("/bulk-email");
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            swal("Oops", error.response.data.message, "error");
          }
          setIsLoading(false);
        });
    },
  });

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Create New Email Notification</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="inputEmail4" className="form-label">
                    Subject Name
                  </label>
                  <input
                    name="subject"
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      formik.setFieldValue("subject", e.target.value);
                    }}
                    value={formik.values.subject}
                    autoComplete="false"
                    placeholder="Subject Name"
                    onBlur={formik.handleBlur}
                  />
                  {/* <i className="fa fa-plus-circle" aria-hidden="true"></i> */}
                  <div className="text-danger">
                    {formik.errors.subject && formik.touched.subject
                      ? formik.errors.subject
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputEmail4" className="form-label">
                    Variable Name
                  </label>
                  <input
                    name="alias"
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    onChange={formik.handleChange}
                    defaultValue={inputs.alias}
                    autoComplete="false"
                    placeholder="Enter Role Name"
                    readOnly
                  />
                  <div className="text-danger">
                    {formik.errors.alias && formik.touched.alias
                      ? formik.errors.alias
                      : null}
                  </div>
                </div>
                {inputs?.alias === "bulk_email" ? (
                  <div className="col-md-6">
                    <label htmlFor="inputStartDate" className="form-label">
                      Send Date
                    </label>
                    <br></br>
                    <DatePicker
                      name="sentDate"
                      selected={sentDate}
                      onChange={(date) => {
                        if (date <= new Date()) {
                          // Disable selection of current date
                          return;
                        }
                        setSentDate(date);
                      }}
                      className="form-control"
                      autoComplete="off"
                      placeholderText="Send Date"
                      minDate={new Date()} // Disable the current date
                      dateFormat="yyyy-MM-dd"
                      required
                    />
                  </div>
                ) : null}
                <div className="col-md-6">
                  <label htmlFor="roles" className="form-label">
                    Send To
                  </label>
                  <div className="department send-to-input">
                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="roles"
                        id="user"
                        onChange={() => {
                          formik.setFieldValue("roles", "user");
                        }}
                      />
                      <label className="form-check-label" htmlFor="user">
                        Buyers
                      </label>
                    </div>
                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="roles"
                        id="seller"
                        onChange={() => {
                          formik.setFieldValue("roles", "seller");
                        }}
                      />
                      <label className="form-check-label" htmlFor="seller">
                        Seller
                      </label>
                    </div>
                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="roles"
                        id="both"
                        onChange={() => {
                          formik.setFieldValue("roles", "both");
                        }}
                      />
                      <label className="form-check-label" htmlFor="both">
                        Both
                      </label>
                    </div>
                  </div>
                  <div className="text-danger">
                    {formik.errors.roles && formik.touched.roles
                      ? formik.errors.roles
                      : null}
                  </div>
                </div>
                <div className="col-12">
                  <JoditEditor
                    ref={editor}
                    value={inputs.content}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => {
                      setContent(newContent);
                      formik.setFieldValue("message", newContent);
                    }}
                  />
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary">
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Sending
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/bulk-email/Modify"
                        : "/bulk-email"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewBulkEmail;
