import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import LoadingSpinner from "../Loader/LoadingSpinner";
function AddBrand() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const navigate = useNavigate();
  const params = useLocation();
  useEffect(() => {
    getBrandDataById();
  }, []);

  const getBrandDataById = async () => {
    setIsLoading(true);
    await axios
      .get(process.env.REACT_APP_API_KEY + "model-data/" + params.state.id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setInputs({
          name: res.data.data.name,
          id: res.data.data.id,
        });
        setIsLoading(false);
        formik.setFieldValue("name", res.data.data.name);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error>>", error.message);
      });
  };
  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
    // return undefined;
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Brand name is required")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(30, "Brand name must not exceed 30 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    validateOnChange: true,
    // validateOnBlur: false,
    onSubmit: async (data) => {
      setIsLoading(true);

      await axios
        .post(
          process.env.REACT_APP_API_KEY + "update-brand/" + params.state.id,
          data,
          {
            headers: {
              "Content-type": "Application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              type: "brand",
            },
          }
        )
        .then((res) => {
          console.log("SSSSS0001");
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Created Successfully!",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/brand-management")
                : navigate("/brand-management/Modify");
            }
          }
        })
        .catch((error) => {
          swal("Oops", error.response.data.message, "error");
          setIsLoading(false);
        });
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Edit Brand</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Brand Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    autoComplete="off"
                    placeholder="Brand Name"
                  />
                  <div className="text-danger">
                    {formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : null}
                  </div>
                </div>

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Updating...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                         "/brand-management"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddBrand;
