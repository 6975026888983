import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchBannerList = createAsyncThunk(
  "banner/fetchData",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "slider-banner",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const sliderSlice = createSlice({
  name: "slider-banner",
  initialState: {
    bannerList: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannerList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBannerList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bannerList = action.payload;
      })
      .addCase(fetchBannerList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { bannersData } = sliderSlice.actions;
export default sliderSlice.reducer;
