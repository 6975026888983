import React from "react";
import $ from "jquery";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect } from "react";
import { Link, NavLink, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { fetchHistoryLists } from "./CoinsHistorySlice";
import axios from "axios";
import swal from "sweetalert";
export default function BoostManagement() {
  let { action } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const allLists = useSelector((state) => state.CoinsHistory.historyList);

  const isLoading = useSelector((state) => state.CoinsHistory.isLoading);
  const error = useSelector((state) => state.CoinsHistory.error);
  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();

    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [
          {
            extend: "excelHtml5",
            title: "",
            text: "Export",
            filename: "Coins",
            className: "btn btn-info mt-2",
          },
        ],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
        order: [],
        //order:[[1, 'DESC']]
      });
    }, 1000);
    console.log("WWWWWWWW3");
  };
  useEffect(() => {
    console.log("WWWWWWWW1234567");
    dispatch(fetchHistoryLists());
    initializeDataTable();
  }, [dispatch, location.key]);

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
  if (error) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }

  const renderUser = (
    <table id="myTable" className="table table-striped my-3">
      <thead className="table-dark">
        <tr>
          <th>Sr.No.</th>
          <th>Name</th>
          <th>Email</th>
          <th>Description</th>
          <th>TransactionId</th>
          <th>Amount (S$)</th>
          <th>Coins</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {allLists?.data?.map((allList, index) => (
          <React.Fragment key={allList?.id}>
            {/* Duplicate rows */}

            <tr key={allList.id}>
              <td>{++index}</td>
              <td>{allList?.user?.name}</td>
              <td>{allList?.user?.email}</td>
              <td>{allList?.description}</td>
              <td>{allList?.transaction_id}</td>
              <td>{allList?.amount_dis}</td>
              <td>{allList?.coins_value}</td>
              <td> {allList?.created_at_dis}</td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Coins Purchase History Management</h1>
          </div>
        </div>
      </div>

      {isLoading ? <LoadingSpinner /> : renderUser}
    </div>
  );
}
