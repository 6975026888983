import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import { fetchSidebarData } from "./sidebarSlice";
import { fetchUserManagementPermission } from "../UserManagement/userSlice";
import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
export default function FullLayout() {
  const navigate = useNavigate();
  const data = useSelector((state) => state.sidebar.data.data);
  const isLoading = useSelector((state) => state.sidebar.data.isLoading);
  const error = useSelector((state) => state.sidebar.data.error);
  const dashboardPermission = useSelector(
    (state) => state.user.dashboardPermission.data
  );
  const isdashboardPermissionLoading = useSelector(
    (state) => state.sidebar.data.isLoading
  );
  const dashboardPermissionError = useSelector(
    (state) => state.sidebar.data.error
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/sign-in");
      return;
    }
    dispatch(fetchSidebarData(localStorage.getItem("token")));
  }, []);
   useEffect(() => {
     dispatch(fetchUserManagementPermission());
   }, []);

  if (isLoading || isdashboardPermissionLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error || dashboardPermissionError) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="app align-content-stretch d-flex flex-wrap">
      <SideBar data={data} permissions={dashboardPermission} />

      <div className="app-container app-content">
        <Outlet />
      </div>
    </div>
  );
}
