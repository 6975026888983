import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchUserList = createAsyncThunk(
  "user/fetchData",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "users-list",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserRating = createAsyncThunk(
  "user/fetchDataRating",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "single-user-rating/" + id,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchUserManagementPermission = createAsyncThunk(
  "dashboard/fetchDashboardPermission",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "fetch-dashboard-permission",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    userList: [],
    dashboardPermission: [],
    isLoading: false,
    userRating: [],
    isLoadingRating: false,
    error: null,
    errorRating: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userList = action.payload;
      })
      .addCase(fetchUserList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserRating.pending, (state) => {
        state.isLoadingRating = true;
        state.errorRating = null;
      })
      .addCase(fetchUserRating.fulfilled, (state, action) => {
        state.isLoadingRating = false;
        state.userRating = action.payload;
      })
      .addCase(fetchUserRating.rejected, (state, action) => {
        state.isLoadingRating = false;
        state.errorRating = action.error.message;
      })
      .addCase(fetchUserManagementPermission.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserManagementPermission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dashboardPermission = action.payload;
      })
      .addCase(fetchUserManagementPermission.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });;
  },
});

export const { usersData } = userSlice.actions;
export default userSlice.reducer;
