import React from 'react'
import swal from "sweetalert";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect, useState } from "react";
import { NavLink, Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import moment from "moment";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import $ from "jquery";
import { fetchDashBoardItems } from './staffManagementSlice';
function NewStaff() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { action } = useParams();
    const [permission, setPermission] = useState({});
    const users = useSelector((state) => state.staffManagement.dashboardItems);
    console.log('users', users);
    const isLoading = useSelector((state) => state.staffManagement.isLoading);
    const error = useSelector((state) => state.staffManagement.error);
    const [loading, setLoading] = useState(false);
    const initializeDataTable = () => {
        $("#myTable").DataTable().clear().destroy();
        setTimeout(function () {
            $("#myTable").DataTable({
                buttons: [
                    {
                        extend: "excelHtml5",
                        title: "",
                        text: "Export",
                        filename: "Staffs",
                        className: "btn btn-info mt-2",
                        exportOptions: {
                            columns: ":not(.no-export)", // Exclude columns with the "no-export" class
                        },
                    },
                ],
                bDestroy: true,
                fixedHeader: true,
                pagingType: "full_numbers",
                pageLength: 10,
                processing: true,
                dom: "Bfrtip",
                select: true,
            });
        }, 1200);
    };

    useEffect(() => {
        initializeDataTable();
    }, []);
    useEffect(() => {
        dispatch(fetchDashBoardItems());
    }, [dispatch, location.key]);

    function capitalizeFirstWord(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function capitalizeFirstWord(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleChange = (id, type) => {
        swal({
            title: "Are you sure?",
            text: "You want to update this action.",
            icon: "warning",
            buttons: ["Cancel", "Submit"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                handleChangeSubmit(id, type);
            }
        });
    };

    const handleChangeSubmit = async (id, type) => {
        var data = {
            actionType: type,
        };
        await axios
            .post(process.env.REACT_APP_API_KEY + "user-update-action/" + id, data, {
                headers: {
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then(function (response) {
                swal({
                    title: "Updated!",
                    text: "Your data updated successfully.",
                    icon: "success",
                    button: "Okay",
                });
                initializeDataTable();
                dispatch(fetchDashBoardItems());
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const handlePermissionChange = (id, value) => {
        setPermission((prevPermissions) => ({
            ...prevPermissions,
            [id]: value,
        }));
    };
   

    const initialValues = {
        name: "",
        email: "",
        mobile: "",
        role: "",
    };

    users.forEach((item) => {
        if(item.key === 'dashboard') {
            initialValues[`permission-${item.id}`] = 'view';
        } else {
            initialValues[`permission-${item.id}`] = 'none';
        }
        
    });

    useEffect(() => {
        users?.forEach((item) => {
            let aeecAll = 'none';
            if(item.key === 'dashboard') {
                aeecAll = 'view';
            } 
            setPermission((prevPermissions) => ({
                ...prevPermissions,
                [item.id]: aeecAll,
            }))

        })
    }, [users])
    useEffect(()=>{
        console.log('permission-', permission)
    },[permission])

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required")
            .max(100, "Name must not exceed 100 characters"),
        email: Yup.string().email("Invalid email").required("Email is required")
            .max(100, "Email must not exceed 100 characters"),
        mobile: Yup.string().required("Mobile is required")
            .max(13, "Mobile must not exceed 13 characters")
            .matches(/^\+?\d+$/, 'Phone number is not valid'),
        role: Yup.string().required("Role is required"),
        ...users.reduce((acc, item) => {
            acc[`permission-${item.id}`] = Yup.mixed().when('role', {
            is: (val) => val?.toLowerCase() === 'sub_admin',
            then: () => Yup.string().test({
                test: function (value, ctx) {                    
                    if (!value) {
                        return ctx.createError({ message: "Please select a permission" })
                    }
                    return true;
                },
                // message: 'At least one of the values from case, dial, bracelet, bezel, lugs, clasp is required and must be of a valid format',
            }),

        });
            return acc;
        }, {}),
    });

    // const validationSchema = Yup.object().shape({
    //     name: Yup.string().required("Name is required"),
    //     email: Yup.string().email("Invalid email").required("Email is required"),
    //     mobile: Yup.string().required("Mobile is required"),
    //     role: Yup.string().required("Role is required"),
    // })
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (data) => {
            console.log("data>>DDD", data);
            setLoading(true);

            axios
                .post(process.env.REACT_APP_API_KEY + "staff-list/create", data, {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    params: {
                        type: "brand",
                    },
                })
                .then((res) => {
                    if (res?.status === 200) {
                        swal({
                            title: "Success!",
                            text: "Created Successfully!",
                            icon: "success",
                            button: "Okay",
                        });
                        setLoading(false);

                        // eslint-disable-next-line no-lone-blocks
                        {
                            localStorage.getItem("user_role") === "super_admin"
                                ? navigate("/staff-management")
                                : navigate("/staff-management");
                        }
                    }
                })
                .catch((error) => {
                    formik.setFieldError("email", error.response.data.message)
                    // swal("Oops", error.response.data.message, "error");
                    setLoading(false);
                });
        },
    });
    useEffect(()=>{
        console.log('errors',formik.errors);
    },[formik])

    const renderUser = (
        <table id="myTable" className="table table-striped my-3">
            <thead className="table-dark">
                <tr>
                    <th width="10%" scope="col">Sr.No.</th>
                    <th scope="col" width="30%">Name</th>
                    {/* <th scope="col">Status</th> */}
                    <th width="60%" scope="col" className="no-export" data-orderable="false">
                        Permission(s)
                    </th>
                </tr>
            </thead>
            <tbody>

                {users?.map((item, index) => (
                    <React.Fragment key={item.id}>
                        <tr>
                            <td>{++index}</td>
                            <td>{item.name} {item.key}</td>
                            {/* <td>{capitalizeFirstWord(item.status)}</td> */}
                            <td>
                                <div id='permissions_radio'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name={`permission-${item.id}`}
                                            id={`permission-view-${item.id}`}
                                            value="view"
                                            onChange={() => {
                                                handlePermissionChange(item.id, 'view');
                                                formik.setFieldValue(`permission-${item.id}`, 'view')
                                            }}
                                            checked={permission[item.id] === 'view'} />
                                        <label className="form-check-label" htmlFor={`permission-view-${item.id}`} >
                                            View
                                        </label>
                                    </div>
                                    
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name={`permission-${item.id}`}
                                            id={`permission-modify-${item.id}`}
                                            value="modify"
                                            disabled = {(item.key === 'dashboard' ? true : false) }
                                            onChange={() => {
                                                handlePermissionChange(item.id, 'modify');
                                                formik.setFieldValue(`permission-${item.id}`, 'modify')
                                            }}
                                            checked={permission[item.id] === 'modify'} />
                                        <label className="form-check-label" htmlFor={`permission-modify-${item.id}`}>
                                            Modify
                                        </label>
                                    </div>
                                    
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name={`permission-${item.id}`}
                                            id={`permission-none-${item.id}`}
                                            value="none"
                                            disabled = {(item.key === 'dashboard' ? true : false) }
                                            onChange={() => {
                                                handlePermissionChange(item.id, 'none');
                                                formik.setFieldValue(`permission-${item.id}`, 'none')
                                            }}
                                            checked={permission[item.id] === 'none'} />
                                        <label className="form-check-label" htmlFor={`permission-none-${item.id}`}>
                                            None
                                        </label>
                                    </div>
                                    <div className="text-danger">
                                        {formik.errors[`permission-${item.id}`] && formik.touched[`permission-${item.id}`]
                                            ? formik.errors[`permission-${item.id}`]
                                            : null}
                                    </div>
                                </div>
                            </td>
                           
                        </tr>
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="page-description">
                        <h1>Add New Staff User</h1>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="example-container">
                        <div className="example-content">
                            <form className="row g-3" onSubmit={formik.handleSubmit}>
                                <div className="col-md-6">
                                    <label htmlFor="name" className="form-label">
                                        Name
                                    </label>
                                    <input
                                        name="name"
                                        type="text"
                                        id='name'
                                        className="form-control"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        autoComplete="false"
                                        placeholder="Name"
                                        onBlur={formik.handleBlur}
                                    />
                                    {/* <i className="fa fa-plus-circle" aria-hidden="true"></i> */}
                                    <div className="text-danger">
                                        {formik.errors.name && formik.touched.name
                                            ? formik.errors.name
                                            : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="email" className="form-label">
                                        Email
                                    </label>
                                    <input
                                        name="email"
                                        id='email'
                                        type="text"
                                        className="form-control"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        autoComplete="false"
                                        placeholder="Email"
                                        onBlur={formik.handleBlur}
                                    />
                                    {/* <i className="fa fa-plus-circle" aria-hidden="true"></i> */}
                                    <div className="text-danger">
                                        {formik.errors.email && formik.touched.email
                                            ? formik.errors.email
                                            : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="role" className="form-label">
                                        Role
                                    </label>
                                    <div className="department send-to-input">
                                        <div className="form-check dis">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="role"
                                                id="superAdmin"
                                                onChange={() => {
                                                    formik.setFieldValue("role", "super_admin");
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="superAdmin">
                                                Super Admin
                                            </label>
                                        </div>
                                        <div className="form-check dis">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="role"
                                                id="subAdmin"
                                                onChange={() => {
                                                    formik.setFieldValue("role", "sub_admin");
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="subAdmin">
                                                Sub Admin
                                            </label>
                                        </div>
                                        
                                        {/* <div className="form-check dis">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="roles"
                                                id="both"
                                                onChange={() => {
                                                    formik.setFieldValue("roles", "both");
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="both">
                                                Both
                                            </label>
                                        </div> */}
                                    </div>
                                    <div className="text-danger">
                                        {formik.errors.role && formik.touched.role
                                            ? formik.errors.role
                                            : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="email" className="form-label">
                                        Mobile
                                    </label>
                                    <input
                                        name="mobile"
                                        id='mobile'
                                        type="text"
                                        className="form-control"
                                        onChange={formik.handleChange}
                                        value={formik.values.mobile}
                                        autoComplete="false"
                                        placeholder="mobile"
                                        onBlur={formik.handleBlur}
                                    />
                                    {/* <i className="fa fa-plus-circle" aria-hidden="true"></i> */}
                                    <div className="text-danger">
                                        {formik.errors.mobile && formik.touched.mobile
                                            ? formik.errors.mobile
                                            : null}
                                    </div>
                                </div>
                                {formik.values.role === "sub_admin" ? renderUser : ""}
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">
                                        {loading ? (
                                            <>
                                                <i className="fa fa-refresh fa-spin"></i>Sending
                                            </>
                                        ) : (
                                            "Create"
                                        )}
                                    </button>

                                    <Link
                                        className="btn btn-primary mx-3"
                                        to={
                                            localStorage.getItem("user_role") === "sub_admin"
                                                ? "/staff-management"
                                                : "/staff-management"
                                        }
                                    >
                                        Back
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewStaff