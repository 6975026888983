  import React from "react";
import $ from "jquery";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect, useState } from "react";
import { Link, NavLink, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
//import { fetchBrandModelList } from "../BrandAndModel/brandModelSlice";
import { fetchProductAccessoriesList } from "./filterSlice";
import Select from "react-select";
import { AccessControlComponent } from "../../../routes/AccessControlOnRoutes";
import { fetchUserManagementPermission } from "../UserManagement/userSlice";
import axios from "axios";
import swal from "sweetalert";
export default function FilterManagement() {
  let { action } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.brandModel.brandModelList);
  const isLoading = useSelector((state) => state.brandModel.isLoading);
  const error = useSelector((state) => state.brandModel.error);
  const [permissionToModify, setPermissionToModify] = useState(true);
  const dashboardPermission = useSelector(
    (state) => state.user.dashboardPermission.data
  );
  const isdashboardPermissionLoading = useSelector(
    (state) => state.sidebar.data.isLoading
  );
  const dashboardPermissionError = useSelector(
    (state) => state.sidebar.data.error
  );
 

  const [allBrandOption, setAllBrandOption] = useState([]);
  const [brandId, setBrandId] = useState('');

  const [defaultBrand, setDefaultBrand] = useState({
    value: "",
    label: "",
  });


  useEffect(() => {
    if (
      dashboardPermission &&
      localStorage.getItem("user_role") !== "super_admin"
    ) {
      let permission = AccessControlComponent({
        dashboardKey: process.env.REACT_APP_MODELMANAGEMENT,
        data: dashboardPermission[0],
      });
      setPermissionToModify(permission);
    }
  }, [dashboardPermission]);
  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();
    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [
          {
            extend: "excelHtml5",
            title: "",
            text: "Export",
            filename: "Filter_managements",
            className: "btn btn-info mt-2",
          },
        ],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
        order: [],
      });
    }, 1000);
  };
  useEffect(() => {
    dispatch(fetchProductAccessoriesList(brandId));
    initializeDataTable();
  }, [dispatch, location.key, dashboardPermission, brandId]);
  useEffect(() => {
    dispatch(fetchUserManagementPermission());
    getAllBrandLists();
  }, []);



  const getAllBrandLists = async () => {
    await axios({
      url: process.env.REACT_APP_API_KEY + "brand-list",
      method: "get",
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        console.log("response==>", response.data?.data);
        var allBrands = response.data.data;
        if (allBrands.length) {
          var brandOptions = [];
          brandOptions.push({ value: '', label: ' --- Select ---' });
          allBrands.forEach((element) => {
            brandOptions.push({ value: element.id, label: element.name });
          });
          setAllBrandOption(brandOptions)
        }
        console.log("response2==>", brandOptions);
        
        //setAllDropDown(response.data.data);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
  };


  if (isLoading || isdashboardPermissionLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
  if (error || dashboardPermissionError) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }

  const handleStatus = (id, statusType) => {
    swal({
      title: "Are you sure?",
      text: "You want to update this action.",
      icon: "warning",
      buttons: ["Cancel", "Submit"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleChange(id, statusType);
      }
    });
  };

  const handleChange = async (id, statusType) => {
    var APIURL = 'accessories-status';
    if(statusType == 'chartstatus') {
      var APIURL = 'accessories-chart-status';
    }
    
    await axios
      .get(process.env.REACT_APP_API_KEY + APIURL+"/" + id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        swal({
          title: "Updated!",
          text: "Status Updated Successfully",
          icon: "success",
          button: "Okay",
        });
        initializeDataTable();
        dispatch(fetchProductAccessoriesList(brandId));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function capitalizeFirstWord(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderUser = (
    <table id="myTable" className="table table-striped my-3">
      <thead className="table-dark">
        <tr>
          <th>Sr.No.</th>
          <th>Name</th>
          <th>Brand</th>
          <th>Type</th>
          <th>Status</th>
          {permissionToModify && <th data-orderable="false">Action(s)</th>}
        </tr>
      </thead>
      <tbody>
        {brands?.data?.map((model, index) => (
          <React.Fragment key={model.id}>
            {/* Duplicate rows */}

            <tr key={model.id}>
              <td>{++index}</td>
              <td>{model.name}</td>
              <td>{model.brand_name}</td>
              <td>{model.type}</td>
              <td>{capitalizeFirstWord(model.status)}</td>
              {permissionToModify && (
                <td>
                  <Link
                    to={{ pathname: "/filter-management/edit-filter" }}
                    state={{ id: model.id, action: action }}
                  >
                    <span className="material-icons" title="Edit">
                      edit
                    </span>
                  </Link>
                  &nbsp;&nbsp;
                  <span
                    style={
                      model.status === "active"
                        ? { color: "green", cursor: "pointer" }
                        : { color: "red", cursor: "pointer" }
                    }
                    className="material-icons-outlined"
                    onClick={() => {
                      handleStatus(model.id, 'status');
                    }}
                    title={model.status === "active" ? "Active" : "Inactive"}
                  >
                    {model.status === "active" ? "toggle_on" : "toggle_off"}
                  </span>
                  
                
                </td>
              )}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Filter Management</h1>
          </div>
        </div>
      </div>
      <div class="row mt-3">
      <div className="col-md-3 ">
                <Select
                    name="brand_id"
                    onChange={(selected) => {
                      setBrandId(selected?.value)
                      console.log(selected)
                      setDefaultBrand({
                        value: selected?.value,
                        label: selected?.label,
                      });

                      
                    }}
                    defaultValue = {defaultBrand}
                    options={allBrandOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
        </div>
        </div>

      {permissionToModify && (
        <>
          <NavLink
            className="btn btn-info"
            to="/filter-management/add-filter"
          >
            Add Filter
          </NavLink>
        </>
      )}
      {isLoading ? <LoadingSpinner /> : renderUser}
    </div>
  );
}
