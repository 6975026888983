import React from "react";
import $ from "jquery";
import { useEffect,useState,  } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { fetchStaticContent } from "./staticContentSlice";
import { fetchUserManagementPermission } from "../UserManagement/userSlice";
import { AccessControlComponent } from "../../../routes/AccessControlOnRoutes";
export default function StaticContentList() {
  let { action } = useParams();
  const dispatch = useDispatch();
  const [permissionToModify, setPermissionToModify] = useState(true);
  const content = useSelector(
    (state) => state?.static_contents?.staticContents
  );
  const isLoading = useSelector((state) => state?.static_contents?.isLoading);
  const error = useSelector((state) => state?.static_contents?.error);
  const dashboardPermission = useSelector((state) => state.user.dashboardPermission.data);
  const isdashboardPermissionLoading = useSelector((state) => state.sidebar.data.isLoading);
  const dashboardPermissionError = useSelector((state) => state.sidebar.data.error);
  console.log('dashboardPermission 28', dashboardPermission);
  const removeTags = (str) => {
    if (str === null || str === "") {
      return false;
    } else {
      str = str.toString();
    }
    return str.replace(/(<([^>]+)>)/gi, "");
  };
  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();
    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [
          {
            extend: "excelHtml5",
            title: "",
            text: "Export",
            filename: "Static_contents",
            className: "btn btn-info mt-2",
          },
        ],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
      });
    }, 1000);
  };

  useEffect(() => {
  }, []);

  useEffect(() => {
    dispatch(fetchStaticContent());
    initializeDataTable();

  }, [dispatch,dashboardPermission]);
  useEffect(() => {
    dispatch(fetchUserManagementPermission());
  }, []);
  useEffect(() => {
    if (dashboardPermission && localStorage.getItem("user_role") !== "super_admin") {
      let permission = AccessControlComponent({ dashboardKey: process.env.REACT_APP_STATICCONTENT, data: dashboardPermission[0] });
      setPermissionToModify(permission);
    }
  }, [dashboardPermission]);

  if (isLoading || isdashboardPermissionLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } 


  if (error || dashboardPermissionError) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }

  const renderUser = (
    <table id="myTable" className="table table-striped my-3">
      <thead className="table-dark">
        <tr>
          <th scope="col">Sr.No.</th>
          <th scope="col">Subject</th>
          <th scope="col">Message</th>
          {permissionToModify &&(<th scope="col" data-orderable="false">
            Action
          </th>)}
        </tr>
      </thead>
      <tbody>
        {content?.map((item, index) => (
          <React.Fragment key={item.id}>
            <tr>
              <td>{++index}</td>
              <td>{item.subject}</td>
              <td>{removeTags(item.message?.substring(0, 150))}...</td>
              {permissionToModify &&(<td>
                <Link
                  to={{ pathname: "/static-contents/edit-static-contents" }}
                  state={{ id: item.id, action: action }}
                >
                  <span className="material-icons" title="Edit">
                    edit
                  </span>
                </Link>
              </td>)}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Static Content Management</h1>
          </div>
        </div>
      </div>

      {isLoading ? <LoadingSpinner /> : renderUser}
    </div>
  );
}
