import React from "react";
import $ from "jquery";
import swal from "sweetalert";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { fetchUserRating } from "./userSlice";
import moment from "moment";
export default function UserRating() {
  const location = useLocation();
  let { userID } = useParams();
  console.log("user_id", userID);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.userRating);
  const isLoadingRating = useSelector((state) => state.user.isLoadingRating);

  const error = useSelector((state) => state.user.errorRating);
  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();
    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [
          {
            extend: "excelHtml5",
            title: "",
            text: "Export",
            filename: "User_ratings",
            className: "btn btn-info mt-2",
            exportOptions: {
              columns: ":not(.no-export)", // Exclude columns with the "no-export" class
            },
          },
        ],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
      });
    }, 1200);
  };

  useEffect(() => {
    initializeDataTable();
  }, []);

  useEffect(() => {
    dispatch(fetchUserRating(userID));
    // document.body.className = "pace-done no-loader users";
    // if ($("body").hasClass("users")) {
    //   $(".dt-buttons").addClass("csv-button");
    // }
    return () => {
      document.body.className = "pace-done no-loader";
    };
  }, [dispatch, location.key]);

  if (isLoadingRating) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else {
    initializeDataTable();
  }

  if (error) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }

  const handleChange = (id, type) => {
    swal({
      title: "Are you sure?",
      text: "You want to update this action.",
      icon: "warning",
      buttons: ["Cancel", "Submit"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleChangeSubmit(id, type);
      }
    });
  };

  const handleChangeSubmit = async (id, type) => {
    var data = {
      actionType: type,
    };
    await axios
      .post(process.env.REACT_APP_API_KEY + "user-rating-status/" + id, data, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        swal({
          title: "Updated!",
          text: "Your data updated successfully.",
          icon: "success",
          button: "Okay",
        });
        initializeDataTable();
        dispatch(fetchUserRating(userID));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //   function capitalizeFirstWord(string) {
  //     return string.charAt(0).toUpperCase() + string.slice(1);
  //   }

  const renderUser = (
    <table id="myTable" className="table table-striped my-3">
      <thead className="table-dark">
        <tr>
          <th scope="col">Sr.No.</th>
          <th scope="col">Name</th>
          <th scope="col">Rated By</th>
          <th scope="col">Rating</th>
          <th scope="col">Description</th>
          <th scope="col">Rated On</th>
          <th scope="col">Rating Status</th>
          <th scope="col" className="no-export" data-orderable="false">
            Action(s)
          </th>
        </tr>
      </thead>
      <tbody>
        {users?.data?.list.map((item, index) => (
          <React.Fragment key={item.id}>
            <tr>
              <td>{++index}</td>
              <td>{item.rated_user?.name}</td>
              <td>{item.rated_by_user?.name}</td>
              <td>{item.rating}</td>
              <td>{item.description}</td>
              <td>{moment(item.created_at).format(process.env.REACT_APP_DATE_FORMAT)}</td>
              <td>{item.status}</td>
              <td>
                <span
                  style={
                    item.status === "Active"
                      ? { color: "green", cursor: "pointer" }
                      : { color: "red", cursor: "pointer" }
                  }
                  className="material-icons-outlined"
                  onClick={() => {
                    handleChange(item.id, "status");
                  }}
                  title={item.status}
                >
                  {item.status === "Active" ? "toggle_on" : "toggle_off"}
                </span>
                &nbsp;&nbsp; &nbsp;
                <Link
                  to={{
                    pathname: "/user-management/user-rating-edit/" + item.id+'/'+userID,
                  }}
                  // state={{ UserId: user.id }}
                >
                  <span
                    className="material-icons"
                    title="Edit Rating"
                    style={{ cursor: "pointer" }}
                  >
                    edit
                  </span>
                </Link>
                &nbsp;&nbsp;
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Rating</h1>
          </div>
        </div>
      </div>
      <></>
      {isLoadingRating ? <LoadingSpinner /> : renderUser}
    </div>
  );
}
