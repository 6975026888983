import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardCount } from "./sidebarSlice";
export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.sidebar?.dashboardData?.data);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/sign-in");
      return;
    }
    dispatch(fetchDashboardCount());
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="page-description">
                <h1>Dashboard</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <div className="card widget widget-stats">
                <div className="card-body">
                  <div className="widget-stats-container d-flex">
                    <div className="widget-stats-icon widget-stats-icon-warning">
                      <i className="material-icons-outlined">person</i>
                    </div>
                    <div className="widget-stats-content flex-fill">
                      <span className="widget-stats-title">Total Users</span>
                      <span className="widget-stats-amount">
                        {data?.userCount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card widget widget-stats">
                <div className="card-body">
                  <div className="widget-stats-container d-flex">
                    <div className="widget-stats-icon widget-stats-icon-success">
                      <i className="material-icons-outlined">business_center</i>
                    </div>
                    <div className="widget-stats-content flex-fill">
                      <span className="widget-stats-title">Total Products</span>
                      <span className="widget-stats-amount">
                        {data?.productCount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card widget widget-stats">
                <div className="card-body">
                  <div className="widget-stats-container d-flex">
                    <div className="widget-stats-icon widget-stats-icon-danger">
                      <i className="material-icons-outlined">watch</i>
                    </div>
                    <div className="widget-stats-content flex-fill">
                      <span className="widget-stats-title">Total Brands</span>
                      <span className="widget-stats-amount">
                        {data?.brandCount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
