import React from "react";
import { useRef, useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { fetchBrandList, fetchModelList } from "../BrandAndModel/brandSlice";
import { fetchSingleProductForAutoFill } from "./watchIdSlice";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
function AddBrand() {
  const location = useLocation();
  const state = location.state;
  const [allDropDown, setAllDropDown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modelId, setModelId] = useState();
  const params = useLocation();
  const [inputs, setInputs] = useState({});
  const [brandName, setBrandName] = useState();
  const [newCreatedModel, setNewCreatedModel] = useState(false);
  const dispatch = useDispatch();
  const dialOption = []; //DIAL
  const strapOption = []; //STRAPBRACELET
  const [defaultModel, setDefaultModel] = useState({ value: "", label: "" });
  const [defaultBrand, setDefaultBrand] = useState({ value: "", label: "" });
  const [defaultDial, setDefaultDial] = useState({ value: "", label: "" });
  const [defaultBracelet, setDefaultBracelet] = useState({value: "",label: ""});
  
  const [defaultReferenceNumber, setDefaultReferenceNumber] = useState();
  

  
  const [newBrand, setNewBrand] = useState();
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const options = [];
  const modelOptions = [];

  const models = useSelector((state) => state.brand.modelList);
  if (models) {
    models.data?.forEach((element) => {
      modelOptions.push({ value: element.id, label: element.name });
    });
  }
  const navigate = useNavigate();
  const productAutFill = useSelector(
    (state) => state.autoFills.productForAutoFillList
  );
  const isLoadingAutoFill = useSelector((state) => state.autoFills.isLoading);
  const error = useSelector((state) => state.autoFills.error);
  useEffect(() => {
    dispatch(fetchBrandList());
    if(modelId){
      dispatch(fetchModelList(modelId));
      getAllDropDownList(modelId);
    } else {
      getAutoFillDataById();
    }
    

  }, [dispatch, modelId]);

  const brands = useSelector((state) => state.brand.brandsList);
  const dataLoading = useSelector((state) => state.brand.isLoading);
  if (brands) {
    brands.data?.forEach((element) => {
      options.push({ value: element.id, label: element.name });
    });
  }
  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
  };
  const validationSchema = Yup.object().shape({
    watch_id: Yup.string()
      .required("Reference Number")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(30, "Reference Name must not exceed 30 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
  });

  const getAllDropDownList = async () => {
    await axios({
      url: process.env.REACT_APP_API_KEY + "get-product-dropdown/"+modelId,
      method: "get",
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        setAllDropDown(response.data.data);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    
    // Append the selected image to the FormData
    formik.setFieldValue("thumb_image", file);
  };
  if (allDropDown) {
    
    allDropDown.DIAL?.forEach((element) => {
      dialOption.push({ value: element.id, label: element.name });
    });
    allDropDown.STRAPBRACELET?.forEach((element) => {
      strapOption.push({ value: element.id, label: element.name });
    });
    
  }

  const getAutoFillDataById = () => {
    setIsLoading(true);
    axios
      .get(
        process.env.REACT_APP_API_KEY +
          "get-watch-id-by-id/" +
          params.state.id,
        {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )

      .then((res) => {
        setIsLoading(false);

        formik.setFieldValue("brand", res.data.data.brand);
        setModelId(res.data.data.brand)
        setDefaultBrand({
          value: res.data.data.brand_id,
          label: res.data.data.brand_name,
        });
        

        formik.setFieldValue("model", res.data.data.model);
        console.log("========>>>>>>",res.data.data.brand)
        dispatch(fetchModelList(res.data.data.brand));
        setDefaultModel({
          value: res.data.data.model_id,
          label: res.data.data.model_name,
        });

        console.log(res.data.data.model,"",res.data.data.model_name)
        formik.setFieldValue("dial", res.data.data.dial);
        setDefaultDial({
          value: res.data.data.dial,
          label: res.data.data.dial_name,
        });
        formik.setFieldValue("dial_markers", res.data.data.dial_markers);
        
        formik.setFieldValue("bracelet", res.data.data.bracelet);
        setDefaultBracelet({
          value: res.data.data.bracelet,
          label: res.data.data.bracelet_acc_name,

        });
        

        formik.setFieldValue(
          "watch_id",
          res.data.data.watch_id
        );
        setDefaultReferenceNumber(res.data.data.watch_id);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error>>", error.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      watch_id: defaultReferenceNumber,
      newBrand: false,
      newModel: false,
      brand: defaultBrand?.value,
      model: defaultModel?.value,
      dial: defaultDial.value,
      bracelet: defaultBracelet.value,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (data) => {
      setIsLoading(true);
      axios
        .post(
          process.env.REACT_APP_API_KEY + `update-watch-id/${state.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res?.status === 200) {
            swal({
              title: "Success!",
              text: "Updated Successfully!",
              icon: "success",
              button: "Okay",
            });

            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/watch-id-management")
                : navigate("/watch-id-management");
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 422 || error.response.status == 500) {
            swal("Oops", error.response.data.message, "error");
          } else {
            var erroMSG = '';
          error.response.data.errors.forEach(function(number) {
            erroMSG = erroMSG+number;
          });

            swal("Oops", erroMSG, "error");

          }
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {}, [formik]);

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Edit Watch Id</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="title" className="form-label">
                    Watch Id 
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <input
                    name="watch_id"
                    id="watch_id"
                    type="text"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.watch_id}
                    autoComplete="off"
                    placeholder="Enter reference number."
                  />
                  <div className="text-danger">
                    {formik.errors.watch_id &&
                    formik.touched.watch_id
                      ? formik.errors.watch_id
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Choose Brand{" "}
                  </label>
                  
                  {defaultBrand.value && (
                    <Select
                      name="brand"
                      id="brand"
                      onChange={(selected) => {
                        formik.setFieldValue("brand", selected.value);
                        setModelId(selected?.value);
                        
                        setDefaultModel({value: '',label: ''});
                        setDefaultDial({value: '',label: ''});
                        setDefaultBracelet({value: '',label: ''});
                        

                      }}
                      defaultValue={defaultBrand}
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultBrand.value && (
                    <Select
                      name="brand"
                      id="brand"
                      onChange={(selected) => {
                        formik.setFieldValue("brand", selected.value);
                      }}
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger">
                    {formik.errors.brand && formik.touched.brand
                      ? formik.errors.brand
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="model" className="form-label">
                    Model
                  </label>

                  {defaultModel.value && (
                    <Select
                      name="model"
                      id="model"
                      onChange={(selected) => {
                        formik.setFieldValue("model", selected.value);
                      }}
                      defaultValue={defaultModel}
                      options={modelOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultModel.value && (
                    <Select
                      name="model"
                      id="model"
                      onChange={(selected) => {
                        formik.setFieldValue("model", selected.value);
                      }}
                      options={modelOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}

                  <div className="text-danger">
                    {formik.errors.model && formik.touched.model
                      ? formik.errors.model
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="dial" className="form-label">
                    Dial
                   
                  </label>
                  {defaultDial.value && (
                    <Select
                      name="dial"
                      id="dial"
                      onChange={(selected) => {
                        formik.setFieldValue("dial", selected.value);
                      }}
                      defaultValue={defaultDial}
                      options={dialOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultDial.value && (
                    <Select
                      name="dial"
                      id="dial"
                      onChange={(selected) => {
                        formik.setFieldValue("dial", selected.value);
                      }}
                      options={dialOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger">
                    {formik.errors.dial && formik.touched.dial
                      ? formik.errors.dial
                      : null}
                  </div>
                </div>
                

                
                

                

                <div className="col-md-6">
                  <label htmlFor="bracelet" className="form-label">
                    Strap/Bracelet
                   
                  </label>
                  {defaultBracelet.value && (
                    <Select
                      name="bracelet"
                      id="strapBracelet"
                      onChange={(selected) => {
                        formik.setFieldValue("bracelet", selected.value);
                      }}
                      defaultValue={defaultBracelet}
                      options={strapOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultBracelet.value && (
                    <Select
                      name="strapBracelet"
                      id="strapBracelet"
                      onChange={(selected) => {
                        formik.setFieldValue("bracelet", selected.value);
                      }}
                      // defaultValue={defaultBracelet}
                      options={strapOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger">
                    {formik.errors.bracelet && formik.touched.bracelet
                      ? formik.errors.bracelet
                      : null}
                  </div>
                  
                </div>
                
                <div>

                  
                  
                </div>

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Updating...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={"/watch-id-management"}
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddBrand;
