import React from "react";
import $ from "jquery";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect, useState } from "react";
import { Link, NavLink, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { fetchBoostLists } from "./boostSlice";
import axios from "axios";
import swal from "sweetalert";
import { AccessControlComponent } from "../../../routes/AccessControlOnRoutes";
import { fetchUserManagementPermission } from "../UserManagement/userSlice";
export default function BoostManagement() {
  let { action } = useParams();
  const location = useLocation()
  const dispatch = useDispatch();
  const allLists = useSelector((state) => state.boosts.boostsList);

  const isLoading = useSelector((state) => state.boosts.isLoading);
  const error = useSelector((state) => state.boosts.error);
  const [permissionToModify, setPermissionToModify] = useState(true);
  const dashboardPermission = useSelector((state) => state.user.dashboardPermission.data);
  const isdashboardPermissionLoading = useSelector((state) => state.sidebar.data.isLoading);
  const dashboardPermissionError = useSelector((state) => state.sidebar.data.error);

  useEffect(() => {
    if (dashboardPermission && localStorage.getItem("user_role") !== "super_admin") {
      let permission = AccessControlComponent({ dashboardKey: process.env.REACT_APP_BOOSTPLANMANAGEMENT, data: dashboardPermission[0] });
      setPermissionToModify(permission);
    }
  }, [dashboardPermission]);
  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();

    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [
          {
            extend: "excelHtml5",
            title: "",
            text: "Export",
            filename: "Boost_plan_managements",
            className: "btn btn-info mt-2",
          },
        ],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
        order: [],
        //order:[[1, 'DESC']]
      });
    }, 1000);

  };
  useEffect(() => {
    dispatch(fetchBoostLists('boost_plan'));
    initializeDataTable();
  }, [dispatch, location.key, dashboardPermission]);
  useEffect(() => {
    dispatch(fetchUserManagementPermission());
  }, []);


  if (isLoading || isdashboardPermissionLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } 

  if (error || dashboardPermissionError) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }
  const handleChange = async (id) => {
    await axios
      .get(process.env.REACT_APP_API_KEY + "change-plan-status/" + id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        swal({
          title: "Updated!",
          text: "Status Updated Successfully",
          icon: "success",
          button: "Okay",
        });
        dispatch(fetchBoostLists('boost_plan'));
        initializeDataTable();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function capitalizeFirstWord(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleStatus = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to update this action.",
      icon: "warning",
      buttons: ["Cancel", "Submit"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleChange(id);
      }
    });
  };

  const renderUser = (
    <table id="myTable" className="table table-striped my-3">
      <thead className="table-dark">
        <tr>
          <th>Sr.No.</th>
          <th>Name</th>
          <th>Cost (Coins)</th>
          <th>Duration (Days)</th>
          <th>Status</th>
          {permissionToModify && (
            <th data-orderable="false">Action(s)</th>
          )}
        </tr>
      </thead>
      <tbody>
        {allLists?.data?.map((allList, index) => (
          <React.Fragment key={allList.id}>
            {/* Duplicate rows */}
            <tr key={allList.id}>
              <td>{++index}</td>
              <td>{allList.plan_name}</td>
              <td>{allList.coins_value}</td>
              <td>{allList.duration}</td>
              <td>{capitalizeFirstWord(allList.status)}</td>
              {permissionToModify && (<td>
                <Link
                  to={{ pathname: "/boost-management/edit-boost" }}
                  state={{ id: allList.id, action: action }}
                >
                  <span className="material-icons" title="Edit">
                    edit
                  </span>
                </Link>
                &nbsp;&nbsp;
                <span
                  style={
                    allList.status === "Active"
                      ? { color: "green", cursor: "pointer" }
                      : { color: "red", cursor: "pointer" }
                  }
                  className="material-icons-outlined"
                  onClick={() => {
                    handleStatus(allList.id);
                  }}
                  title={allList.status}
                >
                  {allList.status === "Active" ? "toggle_on" : "toggle_off"}
                </span>
                &nbsp;&nbsp;
              </td>)}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Boost Plan Management</h1>
          </div>
        </div>
      </div>
      {permissionToModify && (<>
        <NavLink className="btn btn-info" to="/boost-management/add-boost">
          Add New Plan
        </NavLink>
      </>)}
      {isLoading ? <LoadingSpinner /> : renderUser}
    </div>
  );
}
