import React from "react";
import $ from "jquery";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect, useState } from "react";
import { Link, NavLink, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Loader/LoadingSpinner";
import axios from "axios";
import swal from "sweetalert";
import { AccessControlComponent } from "../../../routes/AccessControlOnRoutes";
import { fetchUserManagementPermission } from "../UserManagement/userSlice";
import { DeleteRowtData, fetchDataList } from "./outLierSlice";
import moment from "moment";
import DatePicker from "react-datepicker";

export default function OutlierHistory() {
  let { action } = useParams();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const location = useLocation()
  const dispatch = useDispatch();
  const chartsList = useSelector((state) => state.priceChartData?.chartList);
  const isLoading = useSelector((state) => state.priceChartData?.isLoading);
  const error = useSelector((state) => state.priceChartData.error);
  const [permissionToModify, setPermissionToModify] = useState(true);
  const dashboardPermission = useSelector((state) => state.user.dashboardPermission.data);
  const isdashboardPermissionLoading = useSelector((state) => state.sidebar.data.isLoading);
  const dashboardPermissionError = useSelector((state) => state.sidebar.data.error);
  useEffect(() => {
    if (dashboardPermission && localStorage.getItem("user_role") !== "super_admin") {
      let permission = AccessControlComponent({ dashboardKey: process.env.REACT_APP_PRICECHARTDATA, data: dashboardPermission[0] });
      setPermissionToModify(permission);
    }
  }, [dashboardPermission]);
  
  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();
    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
      });
    }, 1200);
  };
  useEffect(() => {
    dispatch(fetchDataList());
    initializeDataTable();
  }, [dispatch, location.key, dashboardPermission]);
  useEffect(() => {
    dispatch(fetchUserManagementPermission());
  }, []);

  if (isLoading || isdashboardPermissionLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } 

  if (error || dashboardPermissionError) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(DeleteRowtData(id));

      setTimeout(function () {
        dispatch(fetchDataList());

        if(isLoading === false){
          initializeDataTable();  
        }
      }, 1200);
        
      }
    });
  };

  function handleSelectAllCheckbox() {
    var selectedRow = (Array.from(
      document.querySelectorAll('input[class="check_row"]')
    ).map((input) => {
      $('#'+input.id).prop('checked', $('#checkAll').prop('checked'));
      
    }));
  }

  function handleDeleteSelected() {
    var selectedRow = (Array.from(
      document.querySelectorAll('input[class="check_row"]')
    ).map((input) => {
      if($('#'+input.id).prop('checked')){
        return input.value;
      } else {
        return 0;
      }
    }));
  
    console.log(selectedRow);
    var allIds = selectedRow.filter(function(val) {
      return val !== 0;
    });
    console.log('allIds', allIds);
    if(allIds.length){
      handleDelete(allIds)
      $('#checkAll').prop('checked', false);
    } else {
      $('#checkAll').prop('checked', false);
      swal({
        title: "Are you sure?",
        text: "Please select at least one list.",
        icon: "warning",
        dangerMode: true,
      })
    }
  
  }

  const exportExcel = async () => {
    

    await axios
		  .get(process.env.REACT_APP_API_KEY + "outlier-data-download" ,
		  {
        params:{
          sdate: fromDate,
          edate: toDate
        },
		    headers: {
		    "Content-type": "Application/json",
		    Authorization: `Bearer ${localStorage.getItem("token")}`,
		    }   
		  }).then(function (response) {  
        if(response.data.data.erro == false){
          window.location.href = response.data.data.url;
        }
		    
		    
		  }).catch(function (error) {        
		    console.log(error);
		  });
	    
  }

  const renderUser = (
    <table id="myTable" className="table table-striped my-3">
      <thead className="table-dark">
        <tr>
          <th>Sr.No.</th>
          <th>Brand</th>
          <th>Model</th>
          <th>Price(S$)</th>
          <th>Dated</th>
          <th>Dial</th>
          <th>Bracelet</th>
          <th>Imported By</th>
          <th>User ID</th>
          <th>Added Date</th>
          {permissionToModify && (<th data-orderable="false"><input id ="checkAll" type="checkbox"  onChange={handleSelectAllCheckbox} /></th>)}
          {permissionToModify && (<th data-orderable="false">Action(s)</th>)}
        </tr>
      </thead>
      <tbody>
        {chartsList?.data?.map((item, index) => (
          <React.Fragment key={item.id}>
            <tr>
              <td>{++index}</td>
              <td>{item.brand_custom ? item.brand_custom : item?.brand_name}</td>
              <td>{item.model_custom ? item.model_custom : item?.model_name}</td>
              <td>{item.price}</td>
              <td data-sort={moment(item.dated).format('YYYY.MM.DD HH.mm.ss')}>{moment(item.dated).format('MMM-YYYY')}</td>
              <td>{item.dial_custom ? item.dial_custom : item?.dial_name}</td>
              <td>{item.bracelet_custom ? item.bracelet_custom : item?.bracelet_acc_name}</td>
              <td>{item?.name}</td>
              <td>{item?.user_id_tag}</td>
              <td data-sort={moment(item.created_at).format('YYYY.MM.DD HH.mm.ss')}>{moment(item.created_at).format(process.env.REACT_APP_DATE_FORMAT)}</td>
              
              <td><input type="checkbox" value={item?.id} id={'row_'+item?.id} className="check_row" /></td>
              {permissionToModify && (<td>
                &nbsp;
                <span
                  style={{ color: "red", cursor: "pointer" }}
                  className="material-icons-outlined cursor-default"
                  onClick={() => {
                    handleDelete(item.id);
                  }}
                  title="Delete"
                >
                  delete
                </span>
              </td>)}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Product Outlier Data</h1>
          </div>
        </div>
      </div>
      
      <div class="row mt-3">
      <div className="col-md-3">
      <DatePicker
                      showIcon
                      name="dated"
                      selected={fromDate}
                      onChange={(date) => setFromDate(date)}
                      selectsStart
                      startDate={fromDate}
                      endDate={toDate}
                      maxDate={new Date()}
                      className="form-control"
                      autoComplete="off"
                      placeholderText="From Date"
                      dateFormat="yyyy-MM-dd"
                      
                    />
          </div>
          <div className="col-md-3">
                     <DatePicker
                      showIcon
                      name="dated"
                      selected={toDate}
                      onChange={(date) => setToDate(date)}
                      selectsEnd
                      startDate={fromDate}
                      endDate={toDate}
                      minDate={fromDate}
                      maxDate={new Date()}
                      className="form-control"
                      autoComplete="off"
                      placeholderText="To Date"
                      dateFormat="yyyy-MM-dd"
                      
                    />
       </div>
        <div className="col-md-6">
            <button type="submit" onClick={exportExcel} className="btn btn-primary btn-info mt-2">Export Data</button>
        </div>
      </div>
      <div class="row mt-3">
        <div className="col-md-12">
        <button className="btn btn-info" onClick={handleDeleteSelected}>Delete Selected</button>
        </div>
      </div>
      
      
      {isLoading ? <LoadingSpinner /> : renderUser}
    </div>
  );
}
