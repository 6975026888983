import React from "react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AddFile } from "./priceChartSlice";
import LoadingSpinner from "../Loader/LoadingSpinner";
function UploadPriceChart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.priceChartData?.addLoading);
  const error = useSelector((state) => state.priceChartData.error);
  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("File is required")
      .test(
        "fileType",
        "Only .xlsx files are allowed",
        (value) => value && value.name.endsWith(".xlsx")
      ),
  });

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema,
    validateOnChange: true,
    // validateOnBlur: false,
    onSubmit: async (data) => {
      var formData = new FormData();
      formData.append("file", formik.values.file);
      dispatch(AddFile(formData))
        .then((response) => {
          {
            localStorage.getItem("user_role") === "super_admin"
              ? navigate("/price-chart-data")
              : navigate("/price-chart-data/Modify");
          }
        })
        .catch((error) => {
          console.error("Error:", error); // Handle any errors that occurred during the API call
        });
    },
  });
  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="content mt-3">
        <div className="row">
          <div className="mt-3" align="center">
            <h5>Some Thing Went Wrong</h5>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Import File(Brand Model Price Excel)</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => {
                      formik.setFieldValue("file", e.currentTarget.files[0]);
                    }}
                    placeholder="Upload Excel File"
                  />
                  <div className="text-danger">
                    {formik.errors.file && formik.touched.file
                      ? formik.errors.file
                      : null}
                  </div>
                </div>

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Uploading...
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/price-chart-data/Modify"
                        : "/price-chart-data"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UploadPriceChart;
