import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchBoostLists = createAsyncThunk(
  "boost/fetchData",
  async (list_type, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_KEY + "boost-list",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params:{
            type: list_type
          }
        }
      );
      const data = response.data;
      // Process the data as needed
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const brandModelSlice = createSlice({
  name: "boost-data",
  initialState: {
    boostsList: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBoostLists.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBoostLists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.boostsList = action.payload;
      })
      .addCase(fetchBoostLists.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { bannersData } = brandModelSlice.actions;
export default brandModelSlice.reducer;
