import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import $ from "jquery";

const ProductBoostDetail = () => {
  const location = useLocation();
  const state = location.state;
  const [boostData, setBoostData] = useState([]);

  useEffect(() => {
    getBoostedData();
  }, []);

  const initializeDataTable = () => {
    $("#myTable").DataTable().clear().destroy();
    setTimeout(function () {
      $("#myTable").DataTable({
        buttons: [
          {
            extend: "excelHtml5",
            title: "",
            text: "Export",
            filename: "Products",
            className: "btn btn-info mt-2",

          },
        ],
        bDestroy: true,
        fixedHeader: true,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "Bfrtip",
        select: true,
        order: [],
        //order:[[1, 'DESC']]
      });
    }, 1000);
    console.log('WWWWWWWW3');
  };

  const getBoostedData = async () => {
    await axios({
      url: `${process.env.REACT_APP_API_KEY}product-boost/${state.id}`,
      method: "get",
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        console.log("response from Boost==>", response.data.data);
        setBoostData(response.data.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log("error from Price Chart==>", error);
      });
  };

  
  const renderBoosted = (
    <table id="myTable" className="table table-striped my-3">
      <thead className="table-dark">
        <tr>
          <th>Sr.No.</th>
          <th>Plan Name</th>
          <th>Coins</th>
          <th>Day(s)</th>
          <th>Start Date</th>
          <th>End Date</th>
        </tr>
      </thead>
      <tbody>
        {boostData?.map((allList, index) => (
          <React.Fragment key={allList.id}>
            {/* Duplicate rows */}

            <tr key={allList.id}>
              <td>{++index}</td>
              <td>{allList.plan_name}</td>
              <td>{allList.coins_value}</td>
              <td>{allList.duration}</td>
              <td>{moment(allList.boost_start).format(process.env.REACT_APP_DATE_FORMAT)}</td>
              <td>{moment(allList.boost_end).format(process.env.REACT_APP_DATE_FORMAT)}</td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );


  return (
    <div className="mt-5">
    {renderBoosted}
    </div>
  );
};

export default ProductBoostDetail;
