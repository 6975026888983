import React from "react";
import { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
function AddBrand() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
    // return undefined;
  };
  const validationSchema = Yup.object().shape({
    plan_name: Yup.string()
      .required("Plan Name is required")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(30, "Brand Name must not exceed 30 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
    duration: Yup.number().integer()
      .required("Duration is required")
      .positive("Must be more than 0")      
      .max(99999, "Plan Duration must not exceed 5 digits")
      .typeError("Duration must be a number"),
    coins_value: Yup.number()
      .required("Cost is required")
      .positive("Must be more than 0")  
      .max(99999, "Plan cost must not exceed 5 digits")
      .typeError("Cost must be a number"),

  });

  const formik = useFormik({
    initialValues: {
      plan_name: "",
      duration: "",
      cost: "",
      coins_value:""
    },
    validationSchema,
    validateOnChange: true,
    // validateOnBlur: false,
    onSubmit: async (data) => {
      setIsLoading(true);

      await axios
        .post(process.env.REACT_APP_API_KEY + "add-boost-data", data, {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            type: "boost_plan",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Created Successfully!",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/boost-management")
                : navigate("/boost-management/Modify");
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            swal("Oops", error.response.data.message, "error");
          }
          setIsLoading(false);
        });
    },
  });

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Add Boost Plan</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">Plan Name <span style={{ color: "red", fontSize: 16 }}>*</span></label>
                  <input
                    name="plan_name" type="text" className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.plan_name}
                    autoComplete="off" placeholder="Plan Name"
                  />
                  <div className="text-danger">
                    {formik.errors.plan_name && formik.touched.plan_name
                      ? formik.errors.plan_name
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">Plan Cost (Coins) <span style={{ color: "red", fontSize: 16 }}>*</span></label>
                  <input
                    name="coins_value" type="text" className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.coins_value}
                    autoComplete="off" placeholder="Plan Cost (Coins)"
                  />
                  <div className="text-danger">
                    {formik.errors.coins_value && formik.touched.coins_value
                      ? formik.errors.coins_value
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">Plan Duration (Days) <span style={{ color: "red", fontSize: 16 }}>*</span></label>
                  <input
                    name="duration" type="text" className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.duration}
                    autoComplete="off" placeholder="Plan Duration (Days)"
                  />
                  <div className="text-danger">
                    {formik.errors.duration && formik.touched.duration
                      ? formik.errors.duration
                      : null}
                  </div>
                </div>

                

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Saving...
                      </>
                    ) : (
                      "Save"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/boost-management/Modify"
                        : "/boost-management"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddBrand;
