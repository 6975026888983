import React from "react";
import { useRef, useMemo, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { fetchBrandList, fetchModelList } from "../BrandAndModel/brandSlice";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
function AddBrand() {
  const [allDropDown, setAllDropDown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modelId, setModelId] = useState();
  const [brandName, setBrandName] = useState();
  const [newCreatedModel, setNewCreatedModel] = useState(false);
  const dispatch = useDispatch();
  const dialOption = []; //DIAL
  const strapOption = []; //STRAPBRACELET

  const [newBrand, setNewBrand] = useState();
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const options = [];
  const modelOptions = [];

  const models = useSelector((state) => state.brand.modelList);
  if (models) {
    models.data?.forEach((element) => {
      modelOptions.push({ value: element.id, label: element.name });
    });
  }

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchBrandList());
    if (modelId) {
      dispatch(fetchModelList(modelId));
      getAllDropDownList(modelId);
    }
    
  }, [dispatch, modelId]);
  const brands = useSelector((state) => state.brand.brandsList);
  const dataLoading = useSelector((state) => state.brand.isLoading);
  if (brands) {
    brands.data?.forEach((element) => {
      options.push({ value: element.id, label: element.name });
    });
  }
  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
  };
  const validationSchema = Yup.object().shape({
    watch_id: Yup.string()
      .required("Watch id")
      .trim()
      .min(3, "Minimum 3 Character")
      .max(30, "Watch id must not exceed 10 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
    
  });

  const getAllDropDownList = async (modelId) => {
    await axios({
      url: process.env.REACT_APP_API_KEY + "get-product-dropdown/"+modelId,
      method: "get",
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        console.log("response==>", response.data.data);
        setAllDropDown(response.data.data);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
  };
  if (allDropDown) {
    
    allDropDown.DIAL?.forEach((element) => {
      dialOption.push({ value: element.id, label: element.name });
    });
    allDropDown.STRAPBRACELET?.forEach((element) => {
      strapOption.push({ value: element.id, label: element.name });
    });
    
  }

  const formik = useFormik({
    initialValues: {
      watch_id: "",
      brand: "",
      model: "",
      dial: "",
      bracelet: "",
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (data) => {
      // alert('aaaaaaaaaaaa');
      const formData = new FormData();
      formData.append("watch_id", data.watch_id);
      formData.append("brand_id", data.brand);
      formData.append("model_id", data.model);
      formData.append("dial", data.dial);
      formData.append("bracelet", data.bracelet);
      
      setIsLoading(true);
      console.log("DATA============>", data);
      await axios
        .post(process.env.REACT_APP_API_KEY + "add-watch-id", formData, {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            type: "brand",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Created Successfully!",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/watch-id-management")
                : navigate("/watch-id-management/Modify");
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 422 || error.response.status == 500) {
            swal("Oops", error.response.data.message, "error");
          } else {
          var erroMSG = '';
          error.response.data.errors.forEach(function(number) {
            erroMSG = erroMSG+number;
          });

            swal("Oops", erroMSG, "error");
          }
          setIsLoading(false);
        });
    },
  });


  useEffect(() => {
    console.log('formik001: ', formik.errors)
  }, [formik]);


  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Add Watch Id</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="title" className="form-label">
                    Watch ID
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <input
                    name="watch_id"
                    id="watch_id"
                    type="text"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.watch_id}
                    autoComplete="off"
                    placeholder="Watch id."
                  />
                  <div className="text-danger">
                    {formik.errors.watch_id &&
                    formik.touched.watch_id
                      ? formik.errors.watch_id
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Choose Brand
                  </label>
                  <CreatableSelect
                    name="brand"
                    isClearable
                    isDisabled={isSelectLoading}
                    isLoading={isSelectLoading}
                    onChange={(selected) => {
                      console.log(selected, "==================>>>");
                      formik.setFieldValue("brand", selected?.value);
                      setNewBrand(selected);
                      setModelId(selected?.value);
                      setBrandName(selected?.label);
                      setNewCreatedModel(null);
                    }}
                    onCreateOption={(inputValue) => {
                      setIsSelectLoading(true);
                      setTimeout(() => {
                        const newOption = {
                          value: inputValue,
                          label: inputValue,
                        };
                        setIsSelectLoading(false);
                        // setOptions((prev) => [...prev, newOption]);
                        options.push(newOption);
                        console.log("optio----------ns", options);
                        setNewBrand(newOption);
                        setNewCreatedModel(null);

                        formik.setFieldValue("brand", newOption.value);
                      }, 1000);
                    }}
                    options={options}
                    value={newBrand}
                  />
                  <div className="text-danger">
                    {formik.errors.brand_id && formik.touched.brand_id
                      ? formik.errors.brand_id
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="model" className="form-label">
                    Model
                  </label>
                  <CreatableSelect
                    name="model"
                    isClearable
                    isDisabled={isSelectLoading}
                    isLoading={isSelectLoading}
                    onChange={(selected) => {
                      formik.setFieldValue("model", selected?.value);
                      setNewCreatedModel(selected);
                    }}
                    onCreateOption={(inputValue) => {
                      setIsSelectLoading(true);
                      setTimeout(() => {
                        const newOption = {
                          value: inputValue,
                          label: inputValue,
                        };
                        setIsSelectLoading(false);
                        options.push(newOption);
                        console.log("options", options);
                        setNewCreatedModel(newOption);
                        formik.setFieldValue("model", newOption.value);
                      }, 1000);
                    }}
                    options={modelOptions}
                    value={newCreatedModel}
                  />
                  <div className="text-danger">
                    {formik.errors.model && formik.touched.model
                      ? formik.errors.model
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="dial" className="form-label">
                    Dial
                    
                  </label>
                  <Select
                    name="dial"
                    onChange={(selected) => {
                      formik.setFieldValue("dial", selected.value);
                    }}
                    options={dialOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors.dial && formik.touched.dial
                      ? formik.errors.dial
                      : null}
                  </div>
                </div>


                <div className="col-md-6">
                  <label htmlFor="bracelet" className="form-label">
                    Strap/Bracelet
                    
                  </label>
                  <Select
                    name="bracelet"
                    id="bracelet"
                    onChange={(selected) => {
                      formik.setFieldValue("bracelet", selected.value);
                    }}
                    options={strapOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    noOptionsMessage={() => "No Record(s) Found"}
                  />
                  <div className="text-danger">
                    {formik.errors.bracelet && formik.touched.bracelet
                      ? formik.errors.bracelet
                      : null}
                  </div>
                </div>
                
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <i className="fa fa-refresh fa-spin"></i>Saving...
                      </>
                    ) : (
                      "Save"
                    )}
                  </button>

                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/watch-id-management/Modify"
                        : "/watch-id-management"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddBrand;
